import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import "./audiobutton.css";

class AudioButton extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
      playing: false
    };
		this.onKeyPlay = this.onKeyPlay.bind(this);
		this.onKeyPause = this.onKeyPause.bind(this);
  }

  onPlay = (event) => {
    this.setState({ playing: true });
  };
  onPause = (event) => {
    this.setState({ playing: false });
  };
  onEnded = (event) => {
    this.setState({ playing: false });
  };

  playAudio = () => {
    this.audioEl.play();
    const audio = this.audioEl;
    audio.addEventListener("play", this.onPlay);
    audio.addEventListener("pause", this.onPause);
  };

  pauseAudio = e => {
		e.stopPropagation();  
    this.audioEl.pause();
  };

  startAudio = e => {
		e.stopPropagation(); 
    this.playAudio();
  };
	
	onKeyPlay(e) {
			if (e.charCode === 13 || e.charCode === 32) {
				this.startAudio(e);
			}
	}
	
	onKeyPause(e) {
			if (e.charCode === 13 || e.charCode === 32) {
				this.pauseAudio(e);
			}
	}

  renderAudio = () => {
    const { url } = this.props;
    const { playing } = this.state;
    const notSupportedMsg =
      "Your browser does not support the <code>audio</code> element.";
    return (
      <>
			
				<i className="btn play-btn" 
						tabIndex="0" 
						onClick={!playing ? this.startAudio : this.pauseAudio} 
						onKeyPress={!playing ? this.onKeyPlay : this.onKeyPause}>
						{!playing ? <FontAwesomeIcon icon={faPlayCircle} /> : <FontAwesomeIcon icon={faPauseCircle} />}
						</i>

        <audio
          src={url}
          ref={(ref) => {
            this.audioEl = ref;
          }}
        >
          {notSupportedMsg}
        </audio>
      </>
    );
  };

  render() {
    return this.renderAudio();
  }
}

export default AudioButton;