import React, { useEffect, useContext, useState } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import Banner from '../../components/banner/banner';
import suggestionIcon from '../../assets/images/suggestion.png';
import SuggestionCard from './suggestionCard';
import { getSuggetions } from './suggestionService';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';

function Lastseen() {

  const [suggestions, setSuggetions] = useState();
  
  const { t, i18n } = useTranslation();

    const data = {
        "image": suggestionIcon,
        "menuName": t('suggestions.mysuggestion'),
        "header": t('member.menubar.lastseen'),
        "description": t('suggestions.bannerdescription'),
        "color": "#59751c"
      }

      let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        const suggetionData = {
            available: true, 
            search: '', 
            pageNo: 1, 
            pageSize: 20,
            lastSeen: true
        }
        getSuggetions(token_lang, suggetionData).then((result) => {
            if(result.status === 200){
                setSuggetions(result.data)
            }
        })
    }, [])

  return (
    <div>
			<GlkHeader/>
      <main className="main_wrapper suggestions_wrapper">
				<Banner data={data} />
        <div className='questionaire_in_menubar'>
					<SuggestionCard 
            suggestions={suggestions}
            />
				</div>
			</main>
    </div>
  )
}

export default Lastseen;