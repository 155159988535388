import React, { useEffect, useState, useContext } from 'react';
import friendshipIcon from '../../assets/images/icon_category_friendship.png';
import partnershipIcon from '../../assets/images/icon_category_partnership.png';
import Pagination from 'react-bootstrap/Pagination';
import './suggestionCard.css';
import { getAvetar } from './suggestionService';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import UserIcon from '../../assets/images/profile_pic_1.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/spinner';
import OpenProfile from './openProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { 
				faMapMarkerAlt,
				faVenus,
				faMars,
				faCommentDots,
				faUserFriends,
				faHeart,
				faEye,
				faCheck,
				faTimes,
				faQuestion
			 } from '@fortawesome/free-solid-svg-icons';

function SuggestionCard(props) {
    const {
        suggestions,
        onPagination,
        selectPageCounts,
        paginateData,
        recordPerPage,
        totalPage,
        setSuggestions
    } = props;
    const [avatars, setAvatars] = useState({});
    const [isReadMore, setIsReadMore] = useState(false);
    const [currentMemoTextId, setCurrentMemoTextId] = useState();
    const [currentSuggestion, setCurrentSuggestion] = useState();
    const [isOpenProfile, setIsOpenProfile] = useState(false);

    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    };

    const avtar = async (id) => {
        try {
            const result = await getAvetar(token_lang, id);
            if (result?.status === 200) {
                const blob = await result.blob();
                const fileURL = URL.createObjectURL(blob);
                return fileURL;
            }
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
        return '';
    };

    useEffect(() => {
        const fetchAvatars = async () => {
            if (!suggestions?.users || !Array.isArray(suggestions.users)) {
                return;
            }

            const avatarPromises = suggestions.users.map(async (suggestion) => {
                const avatarUrl = await avtar(suggestion.avatarId);
                return { id: suggestion.id, avatarUrl };
            });

            try {
                const avatarResults = await Promise.all(avatarPromises);
                const avatarMap = avatarResults.reduce((acc, { id, avatarUrl }) => {
                    acc[id] = avatarUrl;
                    return acc;
                }, {});

                setAvatars(avatarMap);
            } catch (error) {
                console.error("Error fetching avatars:", error);
            }
        };

        fetchAvatars();
    }, [suggestions]);

    const base64Image = (image) => {
        return `data:image/jpeg;base64,${image}`;
    };

    const seeTextMoreOrless = (suggestionId) => {
        if (currentMemoTextId === suggestionId) {
            setIsReadMore(!isReadMore);
        } else {
            setIsReadMore(true);
            setCurrentMemoTextId(suggestionId);
        }
    };

    const profileOpen = (suggestion) => {
        setIsOpenProfile(true)
        setCurrentSuggestion(suggestion)
    }

    const closeProfile = () => {
        setIsOpenProfile(false)
    }

    const renderPaginationItems = () => {
        const items = [];
        const { activePage } = paginateData;
    
        items.push(
            <Pagination.Prev
                onClick={() => onPagination(Math.max(activePage - 1, 1), recordPerPage)}
                key="prev"
                disabled={activePage === 1}
            />
        );
    
        if (totalPage <= 7) {
            for (let i = 1; i <= totalPage; i++) {
                items.push(
                    <Pagination.Item
                        onClick={() => onPagination(i, recordPerPage)}
                        key={i}
                        active={i === activePage}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            items.push(
                <Pagination.Item
                    onClick={() => onPagination(1, recordPerPage)}
                    key={1}
                    active={1 === activePage}
                >
                    1
                </Pagination.Item>
            );
            items.push(
                <Pagination.Item
                    onClick={() => onPagination(2, recordPerPage)}
                    key={2}
                    active={2 === activePage}
                >
                    2
                </Pagination.Item>
            );
    
            if (activePage > 4) {
                items.push(<Pagination.Ellipsis key="ellipsis-start" />);
            }
    
            const startPage = Math.max(3, activePage - 1);
            const endPage = Math.min(totalPage - 2, activePage + 1);
    
            for (let i = startPage; i <= endPage; i++) {
                items.push(
                    <Pagination.Item
                        onClick={() => onPagination(i, recordPerPage)}
                        key={i}
                        active={i === activePage}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
    
            if (activePage < totalPage - 3) {
                items.push(<Pagination.Ellipsis key="ellipsis-end" />);
            }
    
            items.push(
                <Pagination.Item
                    onClick={() => onPagination(totalPage - 1, recordPerPage)}
                    key={totalPage - 1}
                    active={totalPage - 1 === activePage}
                >
                    {totalPage - 1}
                </Pagination.Item>
            );
            items.push(
                <Pagination.Item
                    onClick={() => onPagination(totalPage, recordPerPage)}
                    key={totalPage}
                    active={totalPage === activePage}
                >
                    {totalPage}
                </Pagination.Item>
            );
        }
    
        items.push(
            <Pagination.Next
                onClick={() => onPagination(Math.min(activePage + 1, totalPage), recordPerPage)}
                key="next"
                disabled={activePage === totalPage}
            />
        );
    
        return items;
    };
    
    return (
        <div className='suggestion_list'>
            {suggestions  ? 
            suggestions?.users?.map((suggestion) => (
                <ul key={suggestion.id}>
                    <li className={`list_card ${suggestion?.available ? null : 'bg_color4unavailable'}`}>
												<div className="suggestions_categories">
														<span className="share_col">
															<strong>{t('suggestions.suggestedfor')}:</strong>		
															{suggestion.categories.map((category, idx) => (
																	category.value == 'Friendship' || category.value == 'Freundschaft' ? 
																		<span className="share_icon share_friendship" title="{category.value}" key={idx}>
																			<FontAwesomeIcon icon={faUserFriends} /> {category.value}
																		</span> : 																					
																	category.value == 'Partnership' || category.value == 'Partnerschaft' ? 
																		<span className="share_icon share_partnership" title="{category.value}" key={idx}>
																			<FontAwesomeIcon icon={faHeart} /> {category.value}
																		</span> : null
																	
															))}
														</span>
														<small className="create_date">
														{currentLang == 'en' ?
														moment(suggestion.createDate).format("MM/DD/YYYY HH:mm") : 
														moment(suggestion.createDate).format("DD.MM.YYYY HH:mm")
														}
														</small>
												</div>
                        <div className='list_image_and_details'>
                            <div className={avatars[suggestion.id] ? 'suggestion-card-profile' : ' suggestion-card-profile suggestion_userIcon'}>
                                {suggestion.viewed ? null :<div className='badge-label'>New</div>}
                                <img className="focused_image" src={avatars[suggestion.id] ? avatars[suggestion.id] : UserIcon} alt={"Avatar:"+suggestion.name} />
                                <img className='blur_backround' src={avatars[suggestion.id] ? avatars[suggestion.id] : UserIcon} alt={"Avatar blur:"+suggestion.name} />
                            </div>
                            <div className='suggestions_details'>
                                <div>
                                    <ul className='suggestion_icons'>
                                        {suggestion.footPrint && <li><img src={base64Image(suggestion.footPrint.image)} className="Footprint" alt="Footprint" /></li>}
                                    </ul>
                                </div>                                
                                <h3>
                                    {suggestion.name}
                                </h3>
																<p className="user_subinfo">
																	{(suggestion.sex.value === 'Male' || suggestion.sex.value === 'männlich') && <span><FontAwesomeIcon icon={faMars} /> {suggestion.sex.value}</span>}
                                  {(suggestion.sex.value === 'Female' || suggestion.sex.value === 'weiblich') && <span><FontAwesomeIcon icon={faVenus} /> {suggestion.sex.value}</span>}
																	{suggestion.age && `, ${suggestion.age} ${t('suggestions.yearsold')}`}
																</p>
                                <div className='suggestions_user_details'>
                                    <p className="user-age"></p>
                                    <p className="user-homeaddress"><FontAwesomeIcon icon={faMapMarkerAlt} /> {suggestion.addressList[0].city && `${suggestion.addressList[0].city}, `}{suggestion.addressList[0].region.value}, {suggestion.addressList[0].country.value}</p>
                                    <span className="viewed-profile"><FontAwesomeIcon icon={faEye} /> has viewed your profile</span><br />
                                    <span onClick={() => profileOpen(suggestion)} className="view-profile">{t('suggestions.showuserprofile')}</span>
                                </div>
                            </div>
                        </div>
												<div className="status_and_memo">
													<div className="status">
														{suggestion.affiliation.value === "Accepted" && <div className="accepted"><FontAwesomeIcon icon={faCheck} /> {t('suggestions.accepted')}</div>}
														{suggestion.affiliation.value === "Ingnored" && <div className="ignored"><FontAwesomeIcon icon={faTimes} /> {t('suggestions.ignored')}</div>}
														{suggestion.affiliation.value === "Undecided" && <div className="undecided"><FontAwesomeIcon icon={faQuestion} /> {t('suggestions.undecided')}</div>}
													</div>
													<div className="memo">
														{suggestion.memo && (
																<div className='pin_message'>
																		<FontAwesomeIcon icon={faCommentDots} /> 
																		<p>
																				{isReadMore && currentMemoTextId === suggestion.id
																						? suggestion.memo
																						: `${suggestion.memo.substring(0, 150)}${suggestion.memo.length > 150 ? '...' : ''}`
																				}
																				{suggestion.memo.length > 150 ? <Form.Group className='read_more'>
																						<a
																								onClick={() => seeTextMoreOrless(suggestion.id)}
																								className='add-filter-btn'
																								variant="primary" 
                                                                                                role="link" 
                                                                                                tabindex="0"
																						>
																								{isReadMore && currentMemoTextId == suggestion.id ? t('suggestions.readLess') : t('suggestions.readMore')}
																						</a>
																				</Form.Group> : ''}
																		</p>
																</div>
														)}
													</div>
												</div>	
                    </li>
                </ul>
            )) : <Spinner/>}
            {paginateData?.data?.length ? <Pagination className="nature_pagination">
                {renderPaginationItems()}
                <Form.Label htmlFor='formRecordperpage'>{t('suggestions.recordperpage')}</Form.Label>
                <Form.Select
                    id="formRecordperpage"
                    onChange={(e) => selectPageCounts(e)}
                    className='record_per_page' >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={100}>100</option>
                </Form.Select>
            </Pagination> : null}
            {isOpenProfile ?
                <OpenProfile
                    avatars={avatars}
                    show={isOpenProfile}
                    base64Image={base64Image}
                    handleClose={closeProfile}
                    suggestion={currentSuggestion}
                    setSuggestions={setSuggestions}
                /> : ''}
        </div>
    );
}

export default SuggestionCard;
