import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Placeholder from 'react-bootstrap/Placeholder';
import { useTranslation } from 'react-i18next';

function Footprint(props) {
  const [index, setIndex] = useState(0);
  const [footprints, setFootprints] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setFootprints(props.footprint)
  }, [props.footprint])

  function handleSelect(selectedIndex) {
    setIndex(selectedIndex);
  };


  return (
    <div className="footprint_section">
      <h3> {t('member.footprint.footprint')}</h3>
      <Carousel className='carousal' activeIndex={index} onSelect={handleSelect} interval={null}>
        {footprints != null ? 
        footprints?.map((footprint) => {
          return <Carousel.Item key={footprint.name} className='footpintImg' >
            <img src={'data:image/png;base64,' + footprint.image} alt={footprint.name}></img>
            <Carousel.Caption>
              <h4>{footprint.name}</h4>
              <p>from {footprint.alias}</p>
            </Carousel.Caption>
          </Carousel.Item>
        }) :
            <Carousel.Caption>
              <Placeholder animation="glow">
                <Placeholder xs={5} />
                <Placeholder xs={4} />
                <Placeholder xs={4} />{' '}
                <Placeholder xs={5} /> <Placeholder xs={8} />
              </Placeholder>
            </Carousel.Caption>
        }
      </Carousel>
    </div>
  )
}

export default Footprint;