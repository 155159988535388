import './login.css';
import LoginDescription from './loginDescription';
import LoginBox from './loginBox';
import GlkHeader from '../../components/Header/GlkHeader';

export default function Login() {
		
		const css = `
    .app_footer {
        display: none;
    }
		`

    return (
        <div className='login_container'>
            <GlkHeader/>
						<div className="main_wrapper">
							<LoginDescription />
							<LoginBox/>
						</div>
						<div className="login_bg" id="login1"></div>
						<div className="login_bg" id="login2"></div>
						<div className="login_bg" id="login3"></div>
						<style>{css}</style>
        </div>
    )
}
