import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye, faEyeSlash  } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from "react-i18next";
import { doLogin, getMemberDetails } from "./loginService";
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useNavigate } from "react-router-dom";
import { getCurrentURL } from '../registrationProcess/ragistrationProcessService'
import { Link, useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { autoFocusByID } from "../commonMethods";

function LoginBox() {
    const { t } = useTranslation();
    const EMAIL_REG_EX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const ALIAS_REG_EX = /^[A-Za-z][A-Za-z0-9*_.-]{3,}$/; // Validate Special Characters, Exclude: * _ . -
    
    let {
        dispatch
    } = useContext(LoginContext);
    let navigate = useNavigate();
    
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isErrorEmailOrAlias, setIsErrorEmailOrAlias] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isLoginError, setIsLoginError] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [isButtonDisable,setIsButtonDisable] = useState(false)
	const location = useLocation();
    
    useEffect(() => {
        if (location.state !== null && location.state.registerEmail !== null) {
            setUserName(location.state.registerEmail);
            window.history.replaceState({}, '');
        }
    }, [])    
    
    const loginSubmit = (e) => {
        e.preventDefault();
            handleEmailLogin({target: {value: userName}})
            handlePasswordLogin({target: {value: password}})
        if(userName && password && !isErrorEmailOrAlias && !isErrorPassword) {
        setIsButtonDisable(true)
            const data = { userName, password };
            doLogin(data).then((res) => {
                if (res.status === 200) {
                    localStorage.setItem('accessToken', res.data.accessToken);
                    dispatch({ type: 'ACCESS_TOKEN', data: res.data.accessToken })
                    memberData(res.data.accessToken, res.data.pendingPrePayment, res.data.pendingDirectDebitPayment);
                    dispatch({ type: 'LOGIN', data: res.data });
                    setIsButtonDisable(false)
                } else {
                    setIsLoginError(res.data.message)
                    setIsButtonDisable(false)
                }
            })
        }
    }

    const memberData = (accessToken, pendingPrePayment, pendingDirectDebitPayment) => {
        getMemberDetails(accessToken).then((response) => {
            if (response?.status === 200) {
                dispatch({ type: 'MEMBER_DETAILS', data: response.data });
                if (response.data.memberStatus === 'REGISTRATION') {
                    getCurrentURL(accessToken).then((res) => {
                        dispatch({ type: 'CURRENT_URL', data: res.data });
                        navigate('/registration', { replace: true });
                    })
                    return;
                }
                else if (response.data.memberStatus === 'REGISTERED' && pendingPrePayment === true) {
                    navigate('/pending/prepayment', { replace: true });
                    return;
                }
                else if (response.data.memberStatus === 'REGISTERED') {
                    if (pendingDirectDebitPayment === false && (response.data.subscriptionState === 'EXPIRING' || response.data.subscriptionState === 'ACTIVE')) {
                        navigate('/home');
                        return;
                    }
                    else if (pendingDirectDebitPayment === false && (response.data.subscriptionState === 'EXPIRED' || response.data.subscriptionState === 'CANCELED')) {
                        navigate('/inactive/subscription', { replace: true })
                        return;
                    }
                    else if (pendingDirectDebitPayment === true) {
                        navigate('/pending/dd', { replace: true });
                        return;
                    }
                }
                else if (response.data.memberStatus === 'CANCELED' || response.data.memberStatus === 'ADMIN_CANCELED') {
                    navigate('/cancellation', { replace: true })
                    return;
                }
            }
        })
    }

    const validateField = (fieldName, value) => {
        const emailValue = value ? value : "";
        switch (fieldName) {
            case `email`:
                return EMAIL_REG_EX.test(emailValue);
            case `alias`:
                return ALIAS_REG_EX.test(emailValue);
            default:
                return false;
        }
    }

    const handleEmailLogin = (e) => {
        setIsLoginError('')
        if(e.target.value) {   
            if(e.target.value.search("@") >= 0) {
                if (validateField(`email`, e.target.value)) {
                    setIsErrorEmailOrAlias(false)
                    return false;
                } else {
                    setIsErrorEmailOrAlias(true)
                    return true;
                }
            } else {
                if (e.target.value.length <= 3) {
                    setIsErrorEmailOrAlias(true)
                    return true;
                } else {
                    if(validateField(`alias`, e.target.value)) {
                        setIsErrorEmailOrAlias(false)
                        return false;
                    }
                    setIsErrorEmailOrAlias(true)
                    return true;
                }
            }
        } else{
            setIsErrorEmailOrAlias(true)
            return true;
        }
    }
    
    const handlePasswordLogin = (e) => {
        setIsLoginError('')
        if (e.target.value) {
            setIsErrorPassword(false)
            return false;
        } else {
            setIsErrorPassword(true)
            return true;
        }
    }

    const validateWithTAB = (evt) => {
        let isError = null;
        if(evt.type === 'keydown' && evt.keyCode === 9 && evt.shiftKey !== true) {
            if(evt.target.id == 'new-email') isError = handleEmailLogin(evt);
            if(evt.target.id == 'new-password') isError = handlePasswordLogin(evt);
            if(evt.target.value === '' || isError) evt.preventDefault();
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const onChangePassword=(e)=>{
        setIsLoginError('')
        setPassword(e.target.value)
        setIsErrorPassword(false)
    }
    const onChangeUserName=(e)=>{
        setIsLoginError('')
        setUserName(e.target.value)
        setIsErrorEmailOrAlias(false)
    }

    return (
        <>
            <div className="login_box">
                <div className="login_main_container">
                    <div className="login_inputs_container">
                        <h1 className="loginH1">{t("member.login.login")}</h1>
                        <p>{t('member.login.noteText')}</p>
                        <form id="login" onSubmit={loginSubmit}>
                            <div className="login_inputs">
                                <div className={isErrorEmailOrAlias ? "input_Logo_div in-valid" : "input_Logo_div"}>
                                    <span>
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                    <input
                                        focus="true"
                                        id="new-email"
                                        value={userName}
                                        type="text"
                                        name="loginName" 
                                        aria-label={t("member.login.emailOrAlias")} 
                                        placeholder={t("member.login.emailOrAlias")}
                                        onChange={(e) => onChangeUserName(e) }
                                        onBlur={(e) => handleEmailLogin(e)}
                                        onKeyDown={(e) => {
                                            validateWithTAB(e)
                                        }}

                                    />
                                </div>                                
                            </div>
                            <div className="login_inputs">
                                <div className={isErrorPassword ? "input_Logo_div in-valid" : "input_Logo_div"}>
                                    <span>
                                        <FontAwesomeIcon icon={faLock} />
                                    </span>
                                    <input
                                        id="new-password"
                                        value={password}
                                        type={showPassword ? "text" : "password"}
                                        name="password" 
                                        aria-label={t("member.login.password")} 
                                        placeholder={t("member.login.password")}
                                        onChange={(e) => onChangePassword(e) }
                                        onBlur={(e) => handlePasswordLogin(e)}
                                        onKeyDown={(e) => {
                                            validateWithTAB(e)
                                        }}
                                    />
                                    <span onClick={toggleShowPassword} className="showPasswordIcon">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </span>
                                </div>
                            </div>
                            <div className="additional_links">
                                <Link to="/forgetpassword">{t("member.login.forgotPassword")}</Link>
                            </div>
                                {isErrorEmailOrAlias || isErrorPassword || isLoginError?
                                <Alert
                                    className='member_address'
                                    variant="danger"
                                    id='main_alert'
                                >
                                <p>
                                    {isErrorEmailOrAlias && (userName ? userName.length <= 3 ? t("member.login.labels.aliasMinCharBody") : t("member.login.labels.emailOrAliasInvalid") : t("member.login.labels.emailOrAliasEmpty"))}
                                </p>
                                <p>
                                    {isErrorPassword && (password ? t("member.login.labels.passwordInvalid") : t("member.login.labels.passwordEmpty")) }
                                </p>
                                <p>
                                    {isLoginError && isLoginError}
                                </p>
                                </Alert> : null}
                            <div className="login_btn">
                                <button
                                    disabled={isButtonDisable}
                                    aria-label={t('member.login.login')}
                                    type="submit">
                                    {isButtonDisable ? (
                                        <span className="loader"></span>
                                    ) : (
                                         t("member.login.loginButton") 
                                    )}
                                </button>
                            </div>
                            <div>
                                <p>
                                    <Trans i18nKey="member.login.loginHelp"/>
                                    <a className="contact_link" target="_blank" href="https://www.gleichklang.de/kontakt/" aria-label=" contact form ">{t('member.login.contactform')} </a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="legal">
                <a className="legal_link" target="_blank" href="https://www.gleichklang.de/impressum/" aria-label="{t('member.footer.imprint')}">{t('member.footer.imprint')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/datenschutz/" aria-label="{t('member.login.dataProtection')}">{t('member.footer.dataProtection')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/agb/" aria-label="{t('member.footer.terms')}">{t('member.footer.terms')}</a>
            </div>
        </>
    )
}

export default LoginBox;