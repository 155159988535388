import React, { useEffect, useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { getPaymentDetails } from './ragistrationProcessService';
import { LoginContext } from '../../context/loginContext';
import usePreventBack from './preventBack/preventBack'

function PaymentResult(props) {
  const [prepaymentData, setPrepaymentData] = useState()
  const { t, i18n } = useTranslation();
  usePreventBack();
  const currentLang = i18n.language;
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
      window.scrollTo(0, 0)
    getPaymentDetails(token_lang).then((result) => {
      if (result?.status === 200) {
        setPrepaymentData(result?.data)
      }

    })
  }, [])
  const amountText = t('registration.prepayment.ptta')
    .replace(/\$\{amount\}/g, `<strong class="prepayment_highlight">${prepaymentData?.amount} ${prepaymentData?.currency}</strong>`)
    .replace(/\$\{externalReferenceId\}/g, `<strong class="prepayment_highlight">${prepaymentData?.externalReferenceId}</strong>`);
  return (
    <div>
      <div className='from_control questionair_container payment_result'>
        {props?.paymentMethods === 'DIRECT_DEBIT' ?        
          <div className="looking_for">
            <div className="questionnaireHeader">
              <h3 className="lineH3 text-center"><strong>{t('registration.payment.thanks')}</strong></h3>
              <h4>{t('registration.payment.thanksGo')}</h4>
              <p>{t('registration.payment.thanksDescription')}</p>
            </div>
          </div> :
          <div>
            <div className='prepayment_result'>
                <p dangerouslySetInnerHTML={{ __html: amountText }}></p>
            </div>
            <div className="prepayment_details">
              <div className="bank_name">
                <p>GLS Bank</p>
              </div>
              <div className="bank_info">
                <p>
                  <span>{t('registration.prepayment.accountN')}</span>
                  <span>{prepaymentData?.prePaymentBankAccounts[0]?.bankAccountNumber}</span>
                </p>
                <p>
                  <span>{t('registration.prepayment.bankcode')}</span>
                  <span>{prepaymentData?.prePaymentBankAccounts[0]?.bankCode}</span>
                </p>
                <p>
                  <span>IBAN: </span>
                  <span>{prepaymentData?.prePaymentBankAccounts[0]?.iban}</span>
                </p>
                <p>
                  <span>BIC:</span>
                  <span>{prepaymentData?.prePaymentBankAccounts[0]?.bic}</span>
                </p>
              </div>
            </div>
            <div className="company_address">
              <p for="Company adress is">
                <span>Gleichklang Limited</span>
                <span>Marlenstr.38</span>
                <span>30171 Hannover</span>
                <span>Germany</span>
              </p>
            </div>
          </div>
        }
        <div className="mt-3 step_one_next">
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => props.handleNextQustionaire()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PaymentResult;