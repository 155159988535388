import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import { getFootprintImages } from './suggestionService';

function Footprints(props) {
    const { show, handleClose, sendOrDeleteFootprint, currentSuggestionId } = props;
    const [footprintImages, setFootprintImages] = useState({});
    const { loginData: { accessToken } } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const token_lang = { language: currentLang, accessToken };

    const footprints = [
        { key: "SMILING_AT_YOU", desc: t('footprintList.smiling') },
        { key: "I_LIKE_YOU_PRETTY_WELL", desc: t('footprintList.ilikeyoualot') },
        { key: "WANT_TO_GET_TO_KNOW_YOU", desc: t('footprintList.gettogether') },
        { key: "YOU_ARE_ON_MY_WAVELENGTH", desc: t('footprintList.onmywavelength') },
        { key: "GREETING_IN_BETWEEN", desc: t('footprintList.greeting') },
        { key: "SENDING_SUNSHINE_TO_YOU", desc: t('footprintList.sendingsunshine') },
        { key: "SWEET_DREAMS", desc: t('footprintList.sweetdreams') },
        { key: "WISH_YOU_A_NICE_DAY", desc: t('footprintList.nicday') },
        { key: "THINKING_OF_YOU", desc: t('footprintList.thinkingofyou') },
        { key: "WANNA_DATE_AGAIN", desc: t('footprintList.wannahavedate') },
        { key: "WANNA_CALL", desc: t('footprintList.wannaspeakphone') },
        { key: "DO_YOU_WRITE_TO_ME_SOON", desc: t('footprintList.writetome') },
        { key: "THE_DATE_WAS_SUPER", desc: t('footprintList.datewasgreat') },
        { key: "I_HAVE_BEEN_DREAMING_OF_YOU", desc: t('footprintList.dreamingofyou') },
        { key: "I_MISS_YOU", desc: t('footprintList.missyou') },
        { key: "I_LIKE_YOU", desc: t('footprintList.ilikeyou') },
        { key: "I_AM_IN_LOVE_WITH_YOU", desc: t('footprintList.falleninlove') },
        { key: "YOU_ARE_IN_MY_HEART", desc: t('footprintList.inmyheart') },
        { key: "I_LOVE_YOU", desc: t('footprintList.iloveyou') },
        { key: "YOU_ARE_THE_LOVE_OF_MY_LIFE", desc: t('footprintList.loveofmylife') },
        { key: "FOREVER_TOGETHER_WITH_YOU", desc: t('footprintList.togetherforever') },
        { key: "I_ADMIRE_YOUR_CLEVERNESS", desc: t('footprintList.admireintelligence') },
        { key: "YOU_MAKE_ME_HAPPY", desc: t('footprintList.youmakemehappy') },
        { key: "WHY_DO_I_NOT_HEAR_OF_YOU", desc: t('footprintList.whynothear') },
        { key: "WHY_DO_YOU_NOT_ANSWER", desc: t('footprintList.whynotanswer') },
        { key: "LETS_GET_ALONG_AGAIN", desc: t('footprintList.letsgetalong') },
        { key: "YOU_HURT_ME", desc: t('footprintList.youhurtme') },
        { key: "DIDNT_WANT_TO_HURT_YOU", desc: t('footprintList.ididnthurt') },
        { key: "CANNOT_FORGET_YOU", desc: t('footprintList.cantforget') },
        { key: "MY_HEART_IS_BROKEN", desc: t('footprintList.heartbroken') },
        { key: "IS_THERE_ANOTHER_CHANCE_FOR_US", desc: t('footprintList.anotherchance') },
        { key: "I_HAVE_NO_INTEREST", desc: t('footprintList.notinterested') },
        { key: "PLEASE_STOP_BOTHERING_ME", desc: t('footprintList.stopbothering') }
    ];

    const footprintImage = async (id) => {
        try {
            const result = await getFootprintImages(token_lang, id);
            if (result?.status === 200) {
                const blob = await result.blob();
                const fileURL = URL.createObjectURL(blob);
                return fileURL;
            }
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
        return '';
    };
    
    useEffect(() => {
        const MAX_CONCURRENT_REQUESTS = 5;
        let activeRequests = 0;
        let queue = [...footprints];
        const fetchFootprintImage = async (footprint) => {
            try {
                const footprintUrl = await footprintImage(footprint.key);
                setFootprintImages((prevImages) => ({
                    ...prevImages,
                    [footprint.key]: footprintUrl,
                }));
            } catch (error) {
                console.error(`Error fetching image for ${footprint.key}:`, error);
            } finally {
                activeRequests--;
                if (queue.length > 0) {
                    fetchNextImage();
                }
            }
        };
    
        const fetchNextImage = () => {
            if (activeRequests < MAX_CONCURRENT_REQUESTS && queue.length > 0) {
                const nextFootprint = queue.shift();
                activeRequests++;
                fetchFootprintImage(nextFootprint);
            }
        };
    
        for (let i = 0; i < MAX_CONCURRENT_REQUESTS; i++) {
            fetchNextImage();
        }
    }, []);

    const handleFootprintClick = (footprintKey) => () => {
        sendOrDeleteFootprint(currentSuggestionId, false, footprintKey);
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('suggestions.definefootprint')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='define_footprint_body'>
                {footprints.map((footprint) => (
                    <div
                        className='footprint_img_desc_wrapper'
                        key={footprint.key}
                        onClick={handleFootprintClick(footprint.key)}
                    >
                        <img src={footprintImages[footprint.key]} alt='' />
                        <p>{footprint.desc}</p>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default Footprints;
