import React from 'react';

function NoComponent() {
  return (
    <div className='no_compnent'>
        No Component Found
    </div>
  )
}

export default NoComponent;