export const baseUrl = process.env.REACT_APP_BASE_URL;
const natureListURL = 'member/communityContacts';
const descriptionURL = 'member/communityContacts';
const getSuggetionsURL = 'suggestion';
const getAvtarURL = 'galleries/avatar';
const getSuggestionsDataURL = 'suggestion/userSuggestionData';
const updateFootprintURL = 'suggestion/footprint';
const getCategoryDataURL = 'suggestion/userCategoryData';
const updateMemoTextURL = 'suggestion/memo';
const updateRelationshipURL = 'suggestion/relationship';
const getShareGalleryDataURL = 'suggestion/galleries';
const sharedSuggestionStatusURL = 'suggestion/share';
const abuseReportURL = 'suggestion/report';

export async function getContactLists(token_lang, id, search, pageNo, pageSize) {
  if (token_lang.accessToken && id) {
    return fetch(`${baseUrl}/${natureListURL}/${id}${`?search=${search}`}&${`pageNo=${pageNo}`}&${`pageSize=${pageSize}`}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function getUserDetails(token_lang,alias) {
  if (token_lang.accessToken && alias) {
    return fetch(`${baseUrl}/${getSuggetionsURL}/userData/${alias}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getContactListsDesc(token_lang, id) {
  if (token_lang.accessToken && id) {
    return fetch(`${baseUrl}/${descriptionURL}/${id}/subscription`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getSuggetions(token_lang, suggestion) {
  const params = `${`?available=${suggestion.available}`}&${`pageNo=${suggestion.pageNo}`}&${`pageSize=${suggestion.pageSize}`}&${`lastSeen=${suggestion.lastSeen}`}&${`search=${suggestion.search}`}`;
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${getSuggetionsURL}${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAvetar(token_lang, avetarId) {
  if(token_lang.accessToken && avetarId) {
    return fetch(`${baseUrl}/${getAvtarURL}/${avetarId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getSuggestionMember(token_lang, alias) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${getSuggestionsDataURL}/${alias}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function updateFootprints(token_lang, footprint) {
    return fetch(`${baseUrl}/${updateFootprintURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      body: JSON.stringify(footprint),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
}

export async function getFootprintImages(token_lang, footprintKey) {
  if(token_lang.accessToken && footprintKey) {
    return fetch(`${baseUrl}/${updateFootprintURL}/${footprintKey}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getSuggestionsDataByCategory(token_lang, categoryId, alias) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${getCategoryDataURL}/${categoryId}/${alias}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function updateMemoText(token_lang, memoData) {
  return fetch(`${baseUrl}/${updateMemoTextURL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${token_lang.accessToken}`,
      'Accept-Language': token_lang.language,
    },
    body: JSON.stringify(memoData),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}


export async function updateRelationship(token_lang, relationshipTypes) {
  return fetch(`${baseUrl}/${updateRelationshipURL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${token_lang.accessToken}`,
      'Accept-Language': token_lang.language,
    },
    body: JSON.stringify(relationshipTypes),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function getSuggestionsGallery(token_lang, alias, galleryId, mediaId) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${getSuggetionsURL}/${alias}/${galleryId}/${mediaId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    })
    .catch((err) => {
      throw err;
    });
  }
}

export async function shareGallery(token_lang, relationshipId) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${getShareGalleryDataURL}/${relationshipId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function sharedSuggestions(token_lang, galleryId, relationshipId) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${sharedSuggestionStatusURL}/${galleryId}/${relationshipId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    })
    .catch((err) => {
      throw err;
    });
  }
}

export async function reportAbuse(token_lang, abuseData) {
  
  return fetch(`${baseUrl}/${abuseReportURL}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token_lang.accessToken}`,
      'Accept-Language': token_lang.language, 
    },
    body: abuseData
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}
