import React, { useEffect, useState, useContext } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import Banner from '../../components/banner/banner';
import './personalDetails.css';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from '../../components/spinner';
import { LoginContext } from '../../context/loginContext';
import { saveQuestionaire } from './personalDetailsService';
import { useTranslation } from 'react-i18next';
import RegionalSearchMA from './regionSearchMA';
import Alert from 'react-bootstrap/Alert';
import { AlertHeading } from 'react-bootstrap';
import { autoFocusByID } from "../commonMethods";


function PersonalDetails(props) {
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [answers, setAnswers] = useState({})
  const [questionnaire, setQuestionnaire] = useState(null)
  const [errResponse, setErrResponse] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessageSelect, setAlertMessageSelect] = useState('')
  const [error, setError] = useState(false)
  const [errorSelect, setErrorSelect] = useState(null)
  const [freeTextError, setFreeTextError] = useState("")


const [textQuestionId,setTextQuestionId]=useState()

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    setQuestionnaire(props?.questionaire)
      props?.questionaire?.questionGroups.map((item, index) =>
      (
        item?.questions?.map((data) => {
          if (data.choiceQuestion?.type === "Checkbox" || data.choiceQuestion?.type === "Radio" || data.choiceQuestion?.type === "ComboBox") {
            let allAnswers = [];
            data?.choiceQuestion?.selectedChoices.map((choice) => allAnswers.push(choice.id))
            setSelectedAnswer(prevAnswers => ({
              ...prevAnswers, 
              [data.id]: { "answerValues": allAnswers, "profileVisible": data.askVisibleToOthers && data.visibleToOthers }
            }))
            }
          else if(data?.type === 'TextQuestion') { 
            setSelectedAnswer(prevAnswers =>
            ({
              ...prevAnswers,
              [data.id]: { "answerValues": [data?.textQuestion?.answer], "profileVisible": data.askVisibleToOthers && data.visibleToOthers }
            })
            );
          }
          else if(data?.type === 'NumberQuestion') { 
            setSelectedAnswer(prevAnswers =>
            ({
              ...prevAnswers,
              [data.id]: { "answerValues": [data?.numberQuestion?.answer], "profileVisible": data.askVisibleToOthers && data.visibleToOthers }
            })
            );
          }
        }
        )
      )
      )
  }, [props])

  const saveQuestionnaire = async (answer) => {
    const answerBody = {
      questionnaireId: props?.questionaire?.id,
      answers: answer
    }
    const response = await saveQuestionaire(answerBody, token_lang)
    if (response.status !== 200) {
      setErrResponse(response.data)
    } else { setErrResponse(null)}
  }

  function stripHtml(html){
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const handleChange = (question, answer, questionGroup) => {
  if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
      if (question.choiceQuestion.multiSelect === true) {
          let shouldEnable = false
          Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
              question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                  shouldEnable = parseInt(answer) === enablerOption ||
                      (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(enablerOption) >= 0);
                  if (shouldEnable) { return true; }
                  return false;
              })
              const tempQn = { ...questionnaire }
              tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
              setQuestionnaire(tempQn)
          })

          if (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(answer) < 0) {
              selectedAnswer[question.id].answerValues = [...selectedAnswer[question.id].answerValues, answer]
              setSelectedAnswer({ ...selectedAnswer })
          } else if (!selectedAnswer[question.id]) {
              selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
              setSelectedAnswer({ ...selectedAnswer })
          }
          let toSave = {};
          toSave[question.id] = selectedAnswer[question.id]
          saveQuestionnaire(toSave)
          return;
      }
      else {
          let shouldEnable = false;
          Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
              question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                  shouldEnable = parseInt(answer) === enablerOption
                  if (shouldEnable) { return true; }
                  return false;
              })
              const tempQn = { ...questionnaire }
              tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
              if (!shouldEnable) {
                  selectedAnswer[key] = {}
                  hideChild(key, questionGroup.id, tempQn, selectedAnswer)
              }
              setQuestionnaire(tempQn)
          })
      }
  }
  if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
      if (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(answer) < 0) {
          selectedAnswer[question.id].answerValues = [...selectedAnswer[question.id].answerValues, answer]
      } else if (!selectedAnswer[question.id]) {
          selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
      }
      setSelectedAnswer({ ...selectedAnswer })
  } else {
      if (selectedAnswer[question.id]) {
          selectedAnswer[question.id].answerValues = [answer]
      } else {
          selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
      }
      setSelectedAnswer({ ...selectedAnswer })
  }
  let toSave = {};
  toSave[question.id] = selectedAnswer[question.id]
  saveQuestionnaire(toSave)
};

const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
  const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
  const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
  if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
      Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
          savedAnswers[key] = {}
          storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
      })
  }
}

const handleBlur = (data, value, item, id) => {
  if (alertMessage === '') {
      handleChange(data, value, item);
      return true; 
    }
};


const handleChangedata=(data, value, item, id)=>{
  
  if (value === undefined || value === null) {
    setAlertMessage('The input value is invalid.');
    return false;
  }

  const wordCount = value.length;

  setTextQuestionId(id);

  const message = wordCount < 200 ? 
  t('suggestions.freetextlimit') : 
    (wordCount > 800 ? t('suggestions.freetextlimit800')  : "")

  setAlertMessage(message);
  
}
const blockInvalidChar = (e) =>{
  const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
  return !e.key.match(regex) && e.preventDefault();
}
const validateWithTAB = (evt)  => {
  if(evt.target.value === ''){
    setAlertMessageSelect(evt.target.id)
    autoFocusByID(evt.target.id)
    setErrorSelect(t('member.personaldata.personaldetails.select'))
    setError(true)
  }else{
    setError(false)
  }
}
const handleTextQuestion = (e, data) => {
    const isEmpty = e.target.value === "";
    const wordCount = e.target.value.length;
    if (e.type === "keydown" && e.keyCode === 9) {
      if (isEmpty || wordCount < 200) {
          setAlertMessage(t(isEmpty ? 'registration.questionnaires.fieldRequired' : 'suggestions.freetextlimit'));
          setError(true);
          setFreeTextError(data.id);
          e.preventDefault();
          return;
      }
    }
    setFreeTextError("");
    setError(false);
}
const handleInput = (e, data, item, value) => {
  if (e.keyCode === 9 && e.target.id !== 'textquestion' && !e.target.value) {
    handleChange(data, value, item);
    e.preventDefault();
  }
};

  return (
	<>
    <GlkHeader/>
    <main className={'main_wrapper personal_details_wrapper '+props?.data.menuName}>
      <Banner data={props?.data} />
      {questionnaire ?
        <div className='questionaire_in_menubar'>

          <Alert className='alert_questionaire' show={errResponse && errResponse.message == "success"} variant="danger" id='main_alert'>
                    <AlertHeading>{t('registration.questionnaires.errorHeader')}</AlertHeading>
                    <p>{errResponse?.message}</p>
                </Alert>
          {questionnaire?.questionGroups.map((item, index) => (
            <Accordion key={index} defaultActiveKey={index === 0 ? "0" : ''}>
              <Accordion.Item eventKey="0">
                <Accordion.Header className='accordian_personal_detail'>{item?.name}</Accordion.Header>
                <Accordion.Body>
									<h3 className='description_pd' dangerouslySetInnerHTML={{ __html: item?.description }}></h3>
                  {item?.questions?.map((data, index) => {										
                    if (data.type === "ChoiceQuestion")
                      if (data.choiceQuestion?.type === "Checkbox")
                        return (
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                            <Form.Group className="mt-3 mb-3 check_box" id='Checkbox'>
														<fieldset>
															<legend>{data?.name}<span className='required_asterisks' aria-hidden='true'>{data.required ? '*' : null}</span></legend>
                              <div className='checkbox_questionair'>
                                {data?.choiceQuestion?.choices?.map((choice) => (
                                  <>
                                    <Form.Check
                                      defaultChecked={selectedAnswer[data?.id]?.answerValues?.includes(choice.id)}
                                      id={`${data?.id}-${choice?.id}`}
                                      name={data?.name}
                                      type="checkbox"
                                      label={choice?.value}
                                      className='msg_pre_checkbox'
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          handleChange(data, choice.id, item)
                                        } else if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.indexOf(choice.id) >= 0) {
                                          selectedAnswer[data.id].answerValues.splice(selectedAnswer[data.id].answerValues.indexOf(choice.id), 1)
                                          let shouldEnable = false
                                          Object.keys(data.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                                              data.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                                                  shouldEnable = selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.indexOf(enablerOption) >= 0;
                                                  if (shouldEnable) { return true; }
                                                  return false;
                                              })
                                              const tempQn = { questionnaire }
                                              tempQn.questionGroups.filter(qg => qg.id === item.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                                              if (!shouldEnable) {
                                                  selectedAnswer[key] = {};
                                              }
                                              setQuestionnaire(tempQn)
                                          })
                                          setSelectedAnswer(selectedAnswer)
                                          let toSave = {};
                                          toSave[data.id] = selectedAnswer[data.id]
                                          saveQuestionnaire(toSave)
                                        }
                                      }}
                                    >
                                    </Form.Check>
                                  </>
                                ))}
                              </div>
														</fieldset>
                            </Form.Group>
                              {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                              <Alert className='alert_questionaire' show={ selectedAnswer[data.id].answerValues.length <= 0 && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                            </div> : null}
                          </div>
                        )

                    if (data.choiceQuestion?.type === "Radio")
                      return (
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                          <Form.Group className="mt-3 mb-3 radio" id='radio'>
													<fieldset>
														<legend>{data?.name}</legend>
															{data?.description !== null ?
															<p>{data.description}</p> : null}
                            <div id={data.id} className='radio_groups'>
                              {data?.choiceQuestion?.choices?.map((choice) => (
                                <Form.Check
                                  checked={selectedAnswer[data?.id]?.answerValues?.includes(choice.id)}
                                  id={`${data?.id}-${choice?.value}`}
                                  name={data?.name}
                                  type="radio"
                                  label={choice?.value}
                                  className='msg_pre_checkbox'
                                  onChange={(e) => {
                                    handleChange(data, choice.id, item)
                                  }}
                                >
                                </Form.Check>
                              ))}
                            </div>
													</fieldset>
                          </Form.Group>
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null}
                        </div>
                      )

                    if (data.choiceQuestion?.type === "ComboBox")
                      return (<>
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                          <Form.Group className="mt-3 mb-3 combo_box" id='combobox'>
                              <label htmlFor={data.id+'_'+index}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></label>
                              <Form.Select
                                className={error && alertMessageSelect  == data.id+'_'+index && 'is-invalid'}
                                onChange={(e) => {
                                  handleChange(data, e.target.value, item);
                                  validateWithTAB(e);
                                }}
                                onKeyDown={(e) => validateWithTAB(e)}
                                id={data.id+'_'+index}
                              >
                                <option value=''>{t('registration.questionnaires.pleaseSelectOption')}</option>
                                {data.choiceQuestion.choices.map(choice => (
                                  <option
                                    selected={choice.id === data?.choiceQuestion?.selectedChoices[0]?.id}
                                    key={choice.id}
                                    value={choice.id}
                                  >
                                    {choice.value}
                                  </option>
                                ))}
                              </Form.Select>
                          </Form.Group>
                          {error && alertMessageSelect  == data.id+'_'+index && <Alert className='alert_questionaire' variant="danger" id={`err_${data.id}`}>{errorSelect}</Alert>}
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null }
                        </div>
                        </>)

                    if (data?.type === "NumberQuestion")
                      return (
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                          <Form.Group className="mt-3 number_question" id='numberquestion'>
                            <Form.Label className="" htmlFor={data.id+'_'+index}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></Form.Label>
                            <Form.Control
                              defaultValue={selectedAnswer[data?.id]?.answerValues?.[0]}
                              type="text"
                              id={data.id+'_'+index}
                              min={data.numberQuestion.minValue}
                              max={data.numberQuestion.maxValue}
                              placeholder={data.numberQuestion.placeholder}
                              onBlur={(e) =>
                                handleChange(data, e.target.value, item)
                              }
                              onKeyDown={(e) => {
                                blockInvalidChar(e);
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null}
                        </div>
                      )

                      
                    if (data?.type === "TextQuestion")
                      return (<>
                        {data.textQuestion.type === 'RichTextArea' ?
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                            <Form.Group className="mt-3 text_question" id='textquestion'>
                              <Form.Label className="" htmlFor={data.id}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></Form.Label>
                              <Form.Control
                                defaultValue={stripHtml(selectedAnswer[data?.id]?.answerValues?.[0])}
                                as="textarea"
                                id={data.id}
                                placeholder={data.textQuestion.placeholder}
                                onBlur={(e) => handleChange(data, e.target.value, item)}
                                onKeyDown={(e) => handleInput (e, data)}
                              />
                            </Form.Group>
                            <Alert className='alert_questionaire' show={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                            </div> : null}
                          </div> :
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                           
                            <Form.Group className="mt-3 text_question" id='textquestion'>
                            <fieldset>
                              <legend><label className="" htmlFor={data.id}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></label></legend>
                              <Form.Control
                                defaultValue={data.textQuestion.answer}
                                as="textarea"
                                id={data.id}
                                label={error && freeTextError === data.id ? t('suggestions.freetextlimit') : data.name }
                                className={data.required &&  error && freeTextError === data.id && "is-invalid"}
                                onChange={(e) =>{
                                  if(questionnaire?.id === 25){
                                    handleChangedata(data, e.target.value, item,data.id)
                                  }
                                }
                                }
                                onBlur={(e) =>
                                  handleBlur(data, e.target.value, item,data.id)
                                }
                                onKeyDown={(e) => handleTextQuestion(e, data)}
                              />
                            </fieldset>
                            </Form.Group>
                            {alertMessage && textQuestionId===data.id && data.id !== 204 &&
                            <Alert className='alert_questionaire' id={`err_${data.id}`}
                              variant="danger" >
                              {alertMessage}</Alert>}
                            </div> : null }
                          </div>}
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                      </>)

                    if (data.type === 'RegionQuestion')
                      return (
                        <RegionalSearchMA
                          data={data}
                          answerData={props.questionaire}
                          questionaireId={props.questionaire?.id}
                          setAnswers={setAnswers}
                          isFromRegistration={false}
                          setReloadQuestionaire={props.setReloadQuestionaire}
                        />
                      )
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
        : <Spinner />}
    </main>
	</>
  )
}

export default PersonalDetails;