import React, { useEffect, useContext, useState } from 'react';
import friendship from '../../assets/images/friendrship.png';
import { LoginContext } from '../../context/loginContext';
import PersonalDetails from './personalDetails.js';
import { getQuestionaireURL } from './personalDetailsService.js';
import { useTranslation } from 'react-i18next';

function WhatRUlooking4F() {
  const [questionaire, setQuestionaire] = useState();
  const { t, i18n } = useTranslation();

  const data = {
    "image": friendship,
    "menuName": t('member.banner.friendship'),
    "header": questionaire?.name,
    "description": questionaire?.description,
    "color": "#16738b"
}

let {
  loginData: {
    accessToken
  },
} = useContext(LoginContext);

const currentLang = i18n.language;

const token_lang = {
  language: currentLang,
  accessToken
}

useEffect(() => {
  getQuestionaireURL(token_lang, '2').then((res) => {
      if (res?.status === 200) {          
          setQuestionaire(res?.data)                
      }
  }).catch((error) => {
  })
}, [currentLang])

return (
  <div>
  <PersonalDetails
      data={data}
      questionaire={questionaire}
  />
</div>
  )
}

export default WhatRUlooking4F;