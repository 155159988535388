import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import GlkHeader from '../../components/Header/GlkHeader';
import Questionaire from './questionaire';
import { getQuestionaires, getCurrentURL, gotoNextQuestionaire } from './ragistrationProcessService';
import { LoginContext } from '../../context/loginContext';
import PartnershipPost from './partnershipPost';
import MemberPreconfigNotification from './memberPreconfigNotification';
import questionnairesImg from "../../assets/images/questionnaires.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PaymentResult from './paymentResult';
import PersonalPost from './personalPost';
import FriendshipPost from './friendshipPost';
import SubscriptionOffer from './subscriptionOffer';
import Laststep from './laststep';
import MemberAddress from './memberAddress';
import Payment from './payment';
import { getMemberDetails } from '../../pages/login/loginService.js';
import {  useNavigate } from "react-router-dom";
import NoComponent from './noComponent.js';
import Spinner from '../../components/spinner.js';
import usePreventBack from './preventBack/preventBack'


function Registration() {
  const [storeQuestionaire, setStoreQuestionaire] = useState(null);
  const [questionaireId, setQuestionaireId] = useState();
  const [spinner, setSpinner] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState()
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  let navigate = useNavigate();

  let {
    loginData: {
      accessToken,
      currentUrl
    },
    dispatch
  } = useContext(LoginContext);

  usePreventBack();
  
  useEffect(() => {
    if (!currentUrl) {
      getCurrentURL(accessToken).then((res) => {
        dispatch({ type: 'CURRENT_URL', data: res.data });
        if (res.data?.nextQuestionnaire !== null) {
          setQuestionaireId(currentUrl?.nextQuestionnaire)
        } else {
          setQuestionaireId(currentUrl?.nextStep)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (currentUrl?.nextQuestionnaire !== null) {
      setQuestionaireId(currentUrl?.nextQuestionnaire)
    }
    else {
      setQuestionaireId(currentUrl?.nextStep)
    }
  }, [currentUrl])

  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    const fetchQuestionnaires = async () => {
        setSpinner(true)
      if (typeof questionaireId === 'number') {
        const res = await getQuestionaires(token_lang, questionaireId);
        if (res?.status === 200) {
          setStoreQuestionaire(res.data);
          setSpinner(false)
        } else {
          setSpinner(false)
        }
      }
      else setSpinner(false)
    };
    fetchQuestionnaires();
  }, [currentLang, questionaireId])

  const handleNextQustionaire = () => {
    setSpinner(true)
    gotoNextQuestionaire(accessToken).then((res) => {
      if (res?.status === 200) {
        if (typeof res.data?.nextQuestionnaire === 'number') {
          setQuestionaireId(res.data?.nextQuestionnaire)
          setSpinner(false);
        }
        else if (res.data?.registrationComplete) {
          getMemberDetails(accessToken).then((response) => {
            dispatch({ type: 'MEMBER_DETAILS', data: response.data });
            if(response.data.subscriptionState && response.data.subscriptionState !== null) {
              navigate('/home');
            } else if(response.data.pendingPrepayment === true) {
              navigate('/pending/prepayment');
            } else if(response.data.pendingDirectDebitPayment === true) {
              navigate('/pending/dd');
            } else {
              navigate('/inactive/subscription')
            }
            setSpinner(false);
          })
        }
        else if (res.data?.nextStep !== null) {
          setQuestionaireId(res.data?.nextStep)
          setSpinner(false);
        }
      }else{
        setSpinner(false);
      }
    }).catch((err) => {
      setSpinner(false)
    })
  }

  let componentToRender;
  let questionnaireClass;
  switch (questionaireId) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 25:
      questionnaireClass = "step_two";
      componentToRender = <Questionaire
        questionaire={storeQuestionaire}
        setStoreQuestionaire={setStoreQuestionaire}
        setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'PARTNERSHIPPOST':
      questionnaireClass = "step_two";
      componentToRender = <PartnershipPost
        handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'CONFIGNOTIFICATION':
      questionnaireClass = "step_four";
      componentToRender = <MemberPreconfigNotification
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'PAYMENTRESULT':
      questionnaireClass = "step_three";
      componentToRender = <PaymentResult
      paymentMethods={paymentMethods}
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'PERSONALPOST':
      questionnaireClass = "step_two";
      componentToRender = <PersonalPost
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'FRIENDSHIPPOST':
      questionnaireClass = "step_two";
      componentToRender = <FriendshipPost
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'SUBSCRIPTIONOFFER':
      questionnaireClass = "step_three";
      componentToRender = <SubscriptionOffer
      setPaymentMethods={setPaymentMethods}
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'LASTSTEP':
      questionnaireClass = "step_four";
      componentToRender = <Laststep
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'MEMBERADDRESS':
      questionnaireClass = "step_two";
      componentToRender = <MemberAddress
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'PAYMENT':
      questionnaireClass = "step_three";
      componentToRender = <Payment
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    default:
      componentToRender =  <NoComponent />
  }

  
  switch (questionaireId) {
    case 3:
    case 4:
    case 14:
      questionnaireClass = "step_four";
      break;
  }

  return (
    <div>
      <GlkHeader />      
      <main className={'main_wrapper preregistration_wrapper questionnaire_wrapper '+questionnaireClass}>
        <div className="banner_wrapper">
          <div className="banner_container">
            <div className='banner_content'>
              <div className="banner_description">
                <h1 className='registration_label'>{t('member.registration.questionnaires')}</h1>
                <p><strong>{t('member.registration.h2label')}</strong></p>
                <p>{t('member.registration.plabel')}</p>
              </div>
            </div>
            <div className="banner_image">
              <img src={questionnairesImg} alt={t('member.registration.questionnaires')} />
            </div>
          </div>
        </div>
        <div className="questionaire_in_menubar">
          <div className="container-fluid registration_steps">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="step_box one">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 1"><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.registerHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.registerSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box two">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 2"><span>2</span><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.profileHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.profileSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box three">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 3"><span>3</span><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.finalizeHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.finalizeSubheading')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {spinner ? <Spinner/> : componentToRender}
        </div>
      </main>
    </div>
  )
}

export default Registration;