import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { LoginContext } from '../../context/loginContext';

function DeleteSuggestions(props) {
    const {
        changeRelationship,
        handleClose,
        show,
        affiliationType
    } = props;

    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    return (
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <ModalBody>
            {t('suggestions.deleteMsg')}
            </ModalBody>
            <Modal.Footer>
                    <Button variant="outline-danger"
                        onClick={() => {changeRelationship(affiliationType, false, true) }}
                    >
                        {t('registration.buttons.proceed')}
                    </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                    {t('common.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

 export default DeleteSuggestions;
