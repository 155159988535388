import React, { useState, useEffect, useContext, useRef } from 'react'
import './messenger.css';
import { LoginContext } from '../../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import friendshipIcon from '../../../assets/images/icon_category_friendship.png';
import partnershipIcon from '../../../assets/images/icon_category_partnership.png';
import maleSvg from '../../../assets/images/male.svg';
import audioSvg from '../../../assets/images/audio_recorder_img.svg';
import emojiSvg from '../../../assets/images/smile_icon.svg';
import femaleSvg from '../../../assets/images/female.svg';
import intersex from '../../../assets/images/intersexual.svg';
import kontactLogo from '../../../assets/images/icon_category_kontakt.png';
import Arrow from '../../../assets/images/arrow_send_icon.png';
import Attachment from '../../../assets/images/attachment_icon.svg';
import { getAvatar } from '../../personalDetails/personalDetailsService';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../assets/images/profile_pic_1.png';
import TextEditor from './textEditor';
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faDownload, faXmark, faChevronLeft, faChevronRight, faTimes, faFile, faStop,faPause, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { KeyBindingUtil } from 'draft-js';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import '../../personalDetails/audio.css'
import Alert from 'react-bootstrap/Alert';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import DOMPurify from 'dompurify';
import MicRecorder from 'mic-recorder-to-mp3';
import heic2any from 'heic2any';
let currentIndex = -1;


function ChatHistory(props) {
  const [avatar, setAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState();
  const [file, setFile] = useState([]);
  const [resetFile, setResetFile] = useState(false);
  const [base64String, setBase64String] = useState([]);
  const [subType, setSubType] = useState('TEXT_MESSAGE')
  const [currentAttachmentId, setCurrentAttachmentId] = useState();
  const [searchString, setSearchString] = useState("");
  const [matchingStringWithMessagesAndId, setMatchingStringWithMessagesAndId] = useState([]);
  const [userSearching, setUserSearching] = useState([]);
  const [discardRecording, setDiscardRecording] = useState(true);
  const [isEmoji, setIsEmoji] = useState(false);
  const [mimeType, setMimeType] = useState([]);
  const [fileUrls, setFileUrls] = useState('');
  const [recorder] = useState(new MicRecorder({ bitRate: 128 }));
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [fileSizeMsg, setFileSizeMsg] = useState('')
  const [audioFile, setAudioFile] = useState();
  const searchRef = useRef(null);
  const {
    chats,
    setUsers,
    sourceType,
    userDetails,
    isMessageError,
    isFileLoader,
    setIsFileLoader,
    setIsMessageError,
    currentUserDetail,
    currentUser,
    setCurrentUser,
    sendMessage,
    isFilterUser,
    attachmentFiles,
    chatContainerRef
  } = props;
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const inputFileSelect = useRef(null);
  const setSearchParams = (e) => {
    setSearchString(e.target.value);
    displayFocusedResult(e.target.value)
  };

  let searchResults = [];
  const displayFocusedResult = (searchValue) => {
    setMatchingStringWithMessagesAndId([]);
    if (!searchValue) {
      clearAllHighlights();

    }
    if (searchValue.length >= 1) {
      searchValue = searchValue.toLowerCase();
      searchResults = chats?.filter(text => {
        const sanitizedHTML = DOMPurify.sanitize(text.message, { ALLOWED_TAGS: [] });
        let plainTextMessage = sanitizedHTML.replace(/&#x[0-9A-Fa-f]+;/g, '');
        const textarea = document.createElement('textarea');
        textarea.innerHTML = plainTextMessage;
        plainTextMessage = textarea.value;
        const sanitizedQuery = DOMPurify.sanitize(searchValue);
        return sanitizedHTML.toLowerCase().includes(sanitizedQuery);
      });
      let searchResults1 = searchResults.reverse();
      setUserSearching(searchResults1);
    }
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/&/g, '&amp;');
  };

  const getMatchingString = () => {
    userSearching.map((item) => {
      const sanitizedHTML = DOMPurify.sanitize(item.message, { ALLOWED_TAGS: [] });
      const matches = [];
      let match;
      const escapedKeyword = escapeRegExp(searchString);
      const regex = new RegExp(escapedKeyword, 'gi');
      const textarea = document.createElement('textarea');
      textarea.innerHTML = sanitizedHTML;
      let plainTextMessage = textarea.value;

      while ((match = regex.exec(plainTextMessage)) !== null) {
        matches.push({ start: match.index, end: match.index + searchString.length });
      }
      if (matches) {
        matches.forEach(element => {
          matchingStringWithMessagesAndId.push([item.sendDate, plainTextMessage, element]);
        });
      }
      setMatchingStringWithMessagesAndId(matchingStringWithMessagesAndId);
    })
  }

  const previousResult = () => {
    if (currentIndex < matchingStringWithMessagesAndId.length) {
      currentIndex++;
      if (currentIndex === matchingStringWithMessagesAndId.length) {
        currentIndex = matchingStringWithMessagesAndId.length - 1;
      }
      highlightText(currentIndex);
      if (matchingStringWithMessagesAndId.length == currentIndex + 1) {
        currentIndex = -1
      }
    }
  }

  const nextResult = () => {
    if (currentIndex <= 0) {
      currentIndex = matchingStringWithMessagesAndId.length;
    }
    if (currentIndex > 0) {
      currentIndex--;
      highlightText(currentIndex);
    }
  }

  const highlightText = (index) => {
    if (userSearching.length === 0) {
      document.getElementById("messageCount").innerHTML = '0/0';
    }
    setTimeout(() => {
      if (userSearching.length && index !== -1) {
        document.getElementById("messageCount").innerHTML = `${index + 1}/${matchingStringWithMessagesAndId.length}`;
      }
    }, 100);
    if (userSearching.length) {
      clearPreviousHighlights(index)
    }
    if (index !== -1) {
      const message = document.getElementById(matchingStringWithMessagesAndId[index][0]);
      const updatedExp = matchingStringWithMessagesAndId[index][1].substring(0, matchingStringWithMessagesAndId[index][2].start) +
        `<mark class="highlight">${matchingStringWithMessagesAndId[index][1].substring(matchingStringWithMessagesAndId[index][2].start, matchingStringWithMessagesAndId[index][2].end)}</mark>` +
        matchingStringWithMessagesAndId[index][1].substring(matchingStringWithMessagesAndId[index][2].end);
      let newText = "<p>" + updatedExp + "</p>"
      message.innerHTML = newText;
      scrollToElement();
    }
  }

  /*when we go to next text for highlight we have to clear last one */
  const clearPreviousHighlights = () => {
    chats.map((text) => {
      const newText = document.getElementById(text.sendDate);
      if (newText) {
        newText.innerHTML = text.message;
      }
    })

  }
  /*clear highlight text when we click clear(x) btn in search box */
  const clearAllHighlights = () => {
    currentIndex = -1;
    chats.map((text) => {
      const newText = document.getElementById(text.sendDate);
      if (newText) {
        newText.innerHTML = text.message;
      }
    })
  }

  /** set highlight text in view */
  function scrollToElement() {
    const elementToScroll = document.querySelector("mark");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth", block: 'nearest' });
    }
  }

  useEffect(() => {
    clearAllHighlights();
    setMatchingStringWithMessagesAndId([]);
    if (searchString) {
      setTimeout(() => {
        previousResult();
      }, 1000);
      const searchInDelay = setTimeout(() => {
        getMatchingString();
      }, 700)
      return () => { clearTimeout(searchInDelay) }
    }
  }, [searchString])

  const pressDownKey = (event) => {
    if (searchString.length >= 1 && event.which == 40) {
      previousResult();
    }
  }

  const pressUpKey = (event) => {
    if (searchString.length >= 1 && event.which == 38) {
      nextResult();
    }
  }

  useEffect(() => {
    setAvatar(null)
    if (currentUser?.avatarId && currentUser?.avatarId !== null) {
      const data = {
        accessToken: accessToken,
        language: currentLang,
        avatarId: currentUser?.avatarId
      }
      getAvatar(data).then(async (response) => {
        if (response.status === 200) {
          const blob = await response.blob();
          const fileURL = URL.createObjectURL(blob);
          setAvatar(fileURL)
        }
      })
    }
  }, [currentUser])

  const {hasCommandModifier} = KeyBindingUtil;
    const keyBindingFn = (event) => {
        if (event.keyCode === 13 && hasCommandModifier(event)) {
                onSendMessage(message)
                const newState = EditorState.createEmpty()
                setMessage( EditorState.moveFocusToEnd(newState))
            return 'handled'
        }
        return 'not-handled'
    };

    const onSendMessage = (message) => {
      let newValue = '';
      if (message !== undefined) {
        newValue = draftToHtml(convertToRaw(message?.getCurrentContent())).trim();
      }
    
      const isEmptyMessage = newValue === '' || newValue === '<p></p>' || newValue === '<p><br></p>';
      const updatedValue = isEmptyMessage ? null : newValue;
    
      let data;
      if (subType === "TEXT_MESSAGE") {
        data = {
          type: "API",
          subType: "TEXT_MESSAGE",
          source: sourceType,
          [sourceType != 'SUGGESTIONS' ? 'targetUserId' : 'targetUserAlias']: sourceType != 'SUGGESTIONS'  
          ? currentUser?.userId 
            : currentUserDetail,         
             message: updatedValue,
        };
      } else {
        const attachments = file?.map((file, index) => ({
          attachmentName: file?.name,
          fileType: file?.type?.includes('image') ? "IMAGE" : "FILE",
          file: base64String[index],
          mimetype: mimeType[index],
        }));
    
        data = {
          type: "API",
          subType: "FILE_MESSAGE",
          source: sourceType,
          targetUserId: currentUser?.userId,
          message: updatedValue,
          attachments,
        };
        setIsFileLoader(true)
      }
    
      sendMessage(JSON.stringify(data));
      setMessage();
      setFile([]); 
      setBase64String([]);
      setSubType('TEXT_MESSAGE');
      setIsEmoji(false);
      setMimeType([]);
      setIsReadyToSend(false);
    };

  const deleteMessage = (messageId) => {
    const data = {
      type: "API",
      subType: "DELETE",
      messageId: messageId,
    };
    sendMessage(JSON.stringify(data));
  }

  useEffect(() => {
    let plainText = message && message.getCurrentContent().getPlainText();
    if (file?.length != 0 || plainText && plainText?.trim() !== "") {
      setIsReadyToSend(true);
    } else {
      setIsReadyToSend(false);
    }
  }, [file, message])

  useEffect(() => {
    let plainText = message && message.getCurrentContent().getPlainText();
    if (plainText == undefined) {
      setIsReadyToSend(false);
    }
  }, [])
  
  useEffect(() => {
    setIsUserBlocked(currentUser?.blocked)
  },[currentUser])

  const blockOrUnblockUser = (isChecked, targetUserId) => {
    setIsUserBlocked(isChecked);
    const userStatus = {
      type: "API",
      subType: "CHAT_BLOCK",
      targetUserId: targetUserId,
      chatBlock: isChecked
    }
    sendMessage(JSON.stringify(userStatus));    
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.userId === targetUserId ? { ...user, blocked: isChecked} : user
        )
      );
  }  

  //block status of user in real time 
  useEffect(() => {
    if (userDetails?.subType === 'CHAT_BLOCK') {
      const currentUserId = currentUser?.userId;
      const blockStatus = userDetails?.blockStatus == 'true' ? true : false;
      const isCurrentUserInvolved = userDetails && (currentUserId == userDetails.blockedBy || currentUserId === userDetails.blocked);

      if (isCurrentUserInvolved) {
        setCurrentUser((prevData) => {
          const isBlockedBySourceUser = currentUserId.userId == userDetails?.blockedBy && blockStatus;
          return {
            ...prevData,
            blocked: blockStatus,
            blockedBySourceUser: isBlockedBySourceUser,
            showBlockSwitch: !blockStatus
          }
        })
      } else {
        setUsers((prevUsers) =>
          prevUsers.map((user) => {
            const isBlockedBySourceUser = user.userId == userDetails?.blockedBy && blockStatus;
            const isShowBlockSwitch = !blockStatus;
            return user.userId == userDetails?.blockedBy
              ? {
                ...user,
                blocked: blockStatus,
                blockedBySourceUser: isBlockedBySourceUser,
                showBlockSwitch: isShowBlockSwitch
              }
              : user;
          })
        );
      }

    }
  }, [userDetails]);
  
  useEffect(() => {
    let base64Url = accessToken?.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    setUserId(JSON.parse(jsonPayload).user_id)
  }, [])

  useEffect(() => {
    setCurrentAttachmentId()
  }, [attachmentFiles])

  const addFile = async (e) => {
    setIsReadyToSend(true);
    setResetFile(true);
    e.preventDefault();
    if (e.target?.files.length > 0) {
      const filesArray = Array.from(e.target.files);
      const maxFileSizeMB = 20;
      const invalidNameRegex = /[\/\\\.]/g;

      const newFiles = [];
      const newBase64Strings = [];
      const newMimeTypes = [];

      for (const selectedFile of filesArray) {
        const fileSizeInMB = selectedFile.size / (1024 * 1024);
        const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
        const fileExtension = selectedFile.name.split('.').pop();

        if (!fileNameWithoutExtension || invalidNameRegex.test(fileNameWithoutExtension)) {
          const errorMessage = t('messenger.fileerror', { fileName: selectedFile.name });
          setFileSizeMsg(errorMessage);
          setIsReadyToSend(false);
          return;
        }

        if (fileSizeInMB <= maxFileSizeMB) {
          let base64String;
          if (['HEIC', 'heic', 'heif', 'HEIF'].includes(fileExtension)) {
            try {
              base64String = await handleHeicToBase64(selectedFile);
              newMimeTypes.push("image/heic");
            } catch (error) {
              console.error("Error handling HEIC file", error);
              setFileSizeMsg(t('messenger.fileerror', { fileName: selectedFile.name }));
              setIsReadyToSend(false);
              return;
            }
          } else {
            const reader = new FileReader();
            base64String = await new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
              };
              reader.readAsDataURL(selectedFile);
            });
            newMimeTypes.push(selectedFile.type);
          }

          newFiles.push(selectedFile);
          newBase64Strings.push(base64String);

          if (newFiles.length === filesArray.length) {
            setFile(newFiles);
            setBase64String(newBase64Strings);
            setMimeType(newMimeTypes);
            setSubType('FILE_MESSAGE');
          }
        } else {
          const errorMessage = t('messenger.filesize', { fileName: selectedFile.name });
          setFileSizeMsg(errorMessage);
          setIsReadyToSend(false);
        }
      }
    }
  };

  const handleHeicToBase64 = async (heicFile) => {
    try {
      const convertedBlob = await heic2any({ blob: heicFile, toType: "image/jpeg" });
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
          resolve(base64String);
        };
        reader.readAsDataURL(convertedBlob);
      });
    } catch (error) {
      console.error("Error converting HEIC to base64:", error);
      throw error;
    }
  };  

  const base64Image = (image) => {
    return `data:image/jpeg;base64,${image}`
  }

  const removePreview = (index) => {
    const updatedFiles = file.filter((_, i) => i !== index);
    const updatedBase64Strings = base64String.filter((_, i) => i !== index);
    const updatedMimeTypes = mimeType.filter((_, i) => i !== index);

    setFile(updatedFiles);
    setBase64String(updatedBase64Strings);
    setMimeType(updatedMimeTypes);

    if (updatedFiles.length == 0) {
      setFile([]);
      setBase64String([]);
      setSubType('TEXT_MESSAGE');
      setMimeType([]);
      setIsReadyToSend(false);
    }
    setResetFile(true);
  };

  useEffect(() => {
    setResetFile(false)    
  }, [resetFile])

  const downloadFile = (attachmentId) => {
    setCurrentAttachmentId(attachmentId);
    const fileData = {
      type: "API",
      subType: "FILE",
      attachmentId: attachmentId,
    };
    sendMessage(JSON.stringify(fileData));
  };

  const fileConverter = (base64Data, attachmentId, fileName) => {
    if (base64Data) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let blob;
      let mimeType;
      switch (getFileType(fileName)) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'txt':
          mimeType = 'text/plain';
          break;
        case 'rtf':
          mimeType = 'application/rtf';
          break;
        case 'mp3':
          mimeType = 'audio/mp3';
          break;
        case 'wav':
          mimeType = 'audio/wav';
          break;
        case 'm4a':
          mimeType = 'audio/m4a';
          break;
        case 'mp4':
          mimeType = 'audio/mp4';
          break;
        case 'wmv':
          mimeType = 'video/x-ms-wmv';
          break;
        case 'avi':
          mimeType = 'video/x-msvideo';
          break;
        case 'mov':
          mimeType = 'video/quicktime';
          break;
        default:
          mimeType = 'application/octet-stream';
      }
      blob = new Blob([byteArray], { type: mimeType });

      const pdfUrl = URL.createObjectURL(blob);
      setFileUrls(prevUrls => ({ ...prevUrls, [attachmentId]: pdfUrl }));
      return pdfUrl;
    }
  };

  function getFileType(filename) {
    const [name, extension] = filename.split(/\.([^.]+)$/);    
    return extension;    
  }

  const startRecording = () => {
    setFile(null);
    setDiscardRecording(true);
    recorder.start().then(() => {
      setIsRecording(true);
    }).catch((e) => console.error(e));
  };

  const stopRecording = () => {
    recorder.stop().getMp3().then(([buffer, blob]) => {
      const blobURL = URL.createObjectURL(blob);
      setAudioURL(blobURL);
      setAudioFile(blob)
      setIsRecording(false);
    }).catch((e) => console.error(e));
  };

  const abortRecording = () => {
    setIsRecording(false);
    setAudioURL('');
    setIsReadyToSend(false);
  };

  useEffect(() => {
    if (!audioURL && discardRecording) return;

    const blob = audioFile;
    let fileName = `me-at-thevoice_${Date.now()}.mp3`;
    
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      setBase64String([base64String]);
      const fileData = { name: fileName, type: blob.type };
      setMimeType(["audio/mpeg"]);      
      setFile([fileData]);
      setSubType('FILE_MESSAGE');
    };
  }, [audioURL]);

  const pickEmoji = () => {
    setIsEmoji(!isEmoji);
  }

  const addEmoji = (e) => {
    let emoji = e.native;
    if(emoji){
      setIsReadyToSend(true);
    }else{
      setIsReadyToSend(false);
    }
    if (message) {
      const newValue = draftToHtml(convertToRaw(message.getCurrentContent()));
      let newVal2 = newValue.slice(0, newValue.length - 5).slice(3);
      const value = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`${newVal2} ${emoji}`))
      );      
      setMessage(value);
    } else {
      const value = EditorState.createWithContent(
        ContentState.createFromText(emoji)
      );      
      setMessage(value);
    }
  };

  const closeAlert = () => {
    setIsMessageError(false);
    setFileSizeMsg('')
  }

  let resetErrorTime;
  useEffect(() => {
    if (resetErrorTime) {
      clearTimeout(resetErrorTime);
    }
    resetErrorTime = setTimeout(() => {
      closeAlert();
    }, 10000);
  }, [isMessageError, fileSizeMsg])
  
  return (
    <div className='chat_container'>
      {!isFilterUser ?
        <>
          <div className='chat_header'>
            <div className='header_with_searchbar'>
              <div className='chat_header_details '>
                <div className='chat_header_img'>
                  <img src={avatar || UserIcon} alt='Profile' />
                </div>
                <div className='chat_suggestion_details'>
                  <ul className='chat_suggestion_icons'>
                    {currentUser?.categories?.map((category, index) => (
                      <React.Fragment key={index}>
                        {category.value === 'Friendship' && <li><img src={friendshipIcon} alt='Friendship' /></li>}
                        {category.value === 'Partnership' && <li><img src={partnershipIcon} alt='Partnership' /></li>}
                      </React.Fragment>
                    ))}
                    {currentUser?.sex?.value == 'Male' ? <li>
                      <img src={maleSvg} />
                    </li> : null}
                    {currentUser?.sex?.value == 'Female' ? <li>
                      <img src={femaleSvg} />
                    </li> : null}
                    {currentUser?.sex?.value == 'Intersexual' ? <li>
                      <img src={intersex} />
                    </li> : null}
                    {currentUser?.categories != null && currentUser?.contactListUser ? <li>
                      <img src={kontactLogo} />
                    </li> : null}
                    {currentUser?.contactListUser == true &&
                      currentUser?.categories == null &&
                      currentUser?.showBlockSwitch == true ? <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label={isUserBlocked ? t('messenger.chatblock') : t('messenger.chatpossible')}
                        onChange={(e) => {
                          blockOrUnblockUser(e.target.checked, currentUser?.userId);
                        }}
                        checked={isUserBlocked}
                      />
                    </Form>
                      : null}
                  </ul>
                  <div className='chat_user_details'>
                    <div className='chat_user_name'>{currentUser?.alias}, {currentUser?.sex?.value}</div>
                    <div className='user_date'>{currentUser?.createDate}</div>
                    <div className='chat_address'>{currentUser?.address}</div>
                  </div>
                </div>
              </div>
              <div className="search_chat">
                <input
                  id="search_input1"
                  className="search_input1"
                  onChange={(e) => setSearchParams(e)}
                  onKeyDown={(e) => pressDownKey(e)}
                  onKeyUp={(e) => pressUpKey(e)}
                  value={searchString}
                  type="text"
                  placeholder={t('messenger.searchchat')}
                  autoComplete="off"
                />
                {searchString && (
                  <>
                    <button onClick={() => nextResult()}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <span className="message_count" id="messageCount"></span>
                    <button onClick={() => previousResult()}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button className="clear-button" onClick={() => {
                      setSearchString("");
                      clearAllHighlights();
                    }}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='chat_msges_and_texteditor position-relative'>
            <div className='chat_msges_body' ref={searchRef}>
              {chats?.map((msg, index) => (
                <div
                  ref={index == chats.length - 1 ? chatContainerRef : null}
                  key={index}
                  className={msg.senderId == userId ? 'right_side_chat' : 'left_side_chat'}>
                  {msg.senderId != userId && (
                    <div className='left_side_chat_img'>
                      <img src={avatar || UserIcon} alt='User' />
                    </div>
                  )}
                  <div className='msg_date'>
                    
                    <div id={msg.id} className="message_date_container">
                      <span className="message_date">
                        {new Date(msg.sendDate).toLocaleString()}
                      </span>
                      {msg.senderId == userId ? 
                       <Dropdown>
                        <Dropdown.Toggle as="span" className="icon-toggle" bsPrefix="custom-toggle">
                          <FontAwesomeIcon className="delete_msg" icon={faEllipsisVertical} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => deleteMessage(msg.id)}>
                          {t('common.delete')}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> : null}
                    </div>
                    <span
                      id={msg.sendDate}
                      className="messageText"
                      data-msg-index={index}
                      dangerouslySetInnerHTML={{ __html: msg.message }}
                    />
                    {msg.attachments?.length > 0 ? (
                      <div className='m_file_container'>
                        {msg.attachments.map((attachment, idx) => (
                          <>
                            {attachment?.fileType === 'IMAGE' ? (
                              <div key={attachment.id} className='m_full_image'>
                                {currentAttachmentId === attachment.id ? <Spinner /> :
                                  <>
                                    {
                                      attachmentFiles.some(attachmentFile => attachmentFile.id === attachment.id) ? null :
                                        <>
                                          <div className={msg.senderId == userId ? 'm_image rdownload' : 'm_image ldownload'} onClick={() => downloadFile(attachment.id)}>
                                            <FontAwesomeIcon icon={faDownload} />
                                          </div>
                                          <span>
                                            <FontAwesomeIcon className='m_file_icon' icon={faFile} />
                                            {attachment.attachmentName}
                                          </span>
                                        </>
                                    }
                                  </>
                                }
                                {attachmentFiles.map((attachmentFile) => (
                                  attachmentFile.id === attachment.id ? <img src={base64Image(attachmentFile.file)} alt={attachmentFile.name} className="full-image" /> : null
                                ))}
                              </div>
                            ) : (
                              <>
                                {currentAttachmentId === attachment.id ? <Spinner /> :
                                  <>
                                    {
                                      attachmentFiles.some(attachmentFile => attachmentFile.id === attachment.id) ? null :
                                        <>
                                          <div className={msg.senderId == userId ? 'm_image rdownload' : 'm_image ldownload'} onClick={() => downloadFile(attachment.id)}>
                                            <FontAwesomeIcon icon={faDownload} />
                                          </div>
                                          <span>
                                            <FontAwesomeIcon className='m_file_icon' icon={faFile} />
                                            {attachment.attachmentName}
                                          </span>
                                        </>
                                    }
                                  </>
                                }
                                {attachmentFiles.map((attachmentFile) => (
                                  attachmentFile.id === attachment.id ? (
                                    <div key={attachmentFile.id}>
                                      {getFileType(attachmentFile.attachmentName) === 'rtf' ? (
                                        <a href={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                          download={`${attachmentFile.id}.rtf`}>
                                          Download RTF File
                                        </a>
                                      ) : null}
                                      {getFileType(attachmentFile.attachmentName) === 'pdf' ||
                                        getFileType(attachmentFile.attachmentName) === 'txt' ? (
                                        <iframe src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                          width="100%"
                                          height="500px"
                                          title="Viewer" />
                                      ) : null}
                                      {getFileType(attachmentFile.attachmentName) == 'mp3' ||
                                        getFileType(attachmentFile.attachmentName) == 'm4a' ||
                                        getFileType(attachmentFile.attachmentName) == 'wav' ? (
                                        <audio controls>
                                          <source src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)} />
                                          Your browser does not support the audio element.
                                        </audio>
                                      ) : null}
                                      {getFileType(attachmentFile.attachmentName) == 'mp4' ||
                                        getFileType(attachmentFile.attachmentName) == 'mov' ||
                                        getFileType(attachmentFile.attachmentName) == 'qt' ? ( <>
                                        <video controls width="100%" height="auto">
                                          <source src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)} />
                                          Your browser does not support the video element.
                                        </video>
                                        <a href={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                          download={`${attachmentFile.attachmentName}`}>
                                          {t('messenger.downloadFile')}
                                        </a>
                                      </>
                                      ) : <a href={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                        download={`${attachmentFile.attachmentName}`}>
                                        {t('messenger.downloadFile')}
                                      </a>}
                                    </div>
                                  ) : null
                                ))}
                              </>
                            )}
                          </>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
              <div className='emoji_container' >
                {isEmoji ? <Picker
                  onEmojiSelect={addEmoji}
                  emojiTooltip={false}
                  emojiSize={18}
                /> : null}
              </div>
            </div>
            {base64String.length > 0 && (
              <div className='preview_image_container'>
                {file?.map((file, index) => (
                  <div key={index} className="file-preview-item">
                    <div
                      onClick={() => removePreview(index)}
                      className='xmark_preview'>
                      <FontAwesomeIcon className='close_icon' icon={faXmark} />
                    </div>
                    {mimeType[index]?.includes('image') ? (
                      <img
                        src={`data:${file.type};base64,${base64String[index]}`}
                        className="preview-image"
                        alt={`Preview of ${file.name}`}
                      />
                    ) : (
                      <p className="preview-test">
                        <FontAwesomeIcon className='m_file_icon' icon={faFile} />
                        {file.name}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
            {isFileLoader ?
              <div className="right_side_chat">
              <Spinner className="file_loader" />
            </div>
              : null}
            <div className='record_audio'>
              <div className='record_voice'>
                <div>
                  {isRecording ? <>
                    <div className="recordControls">
                      <img className='audiogif' src={require("../../../assets/images/audiogif.gif")} />
                      <FontAwesomeIcon
                        onClick={stopRecording}
                        icon={faPause}
                        color='white'
                        data-tip
                        data-for='for_stop'
                      />
                      <Button
                        className='stopandsaveButton'
                        onClick={stopRecording}>
                        <FontAwesomeIcon icon={faStop} /> {t('member.audio.stopandsave')}
                      </Button>
                      <Button
                        className='abortButton'
                        title={t('member.audio.abort')}
                        onClick={() => {
                          abortRecording();
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  </> : null}
                </div>
              </div>
            </div>
            {fileSizeMsg ? <Alert
              className='member_address'
              variant="danger"
              id='main_alert'
              onClose={() => closeAlert()}
              dismissible
            >
              {fileSizeMsg}
            </Alert> : null}
            {isMessageError ? <Alert
              className='member_address'
              variant="danger"
              id='main_alert'
              onClose={() => closeAlert()}
              dismissible
            >
              {userDetails?.error}
            </Alert> : null}
            {!isUserBlocked ? <div className='m_text_editor'>
              <TextEditor
                message={message}
                setMessage={setMessage}
                keyBindingFn={keyBindingFn}
                setIsReadyToSend={setIsReadyToSend}
              />
              {!isFileLoader ? <> {isReadyToSend ? <div
                className='m_sendmsg_btn m_send_btn'
                onClick={() => onSendMessage(message)}
              >
                <img src={Arrow} />
              </div> : null}
              {!file?.length && !isReadyToSend && !isRecording ? <> <div
                className='m_sendfile_btn m_send_btn'
                onClick={() => inputFileSelect.current?.click()}
              >
                <img src={Attachment} />
              </div>
              <div
                className='m_sendfile_btn m_send_btn'
                onClick={() => startRecording()}
              >
                <img src={audioSvg} />
              </div></> : null}
              <div
                className='m_sendfile_btn m_send_btn'
                onClick={() => pickEmoji()}
              >
                <img src={emojiSvg} />
              </div></> : null}
              <input
                id="file-upload"
                ref={inputFileSelect}
                name="audio-and-image-upload"
                onChange={(e) => addFile(e)}
                type={resetFile ? "reset" : "file"}
                multiple
                style={{ display: 'none' }}
              />
            </div> : null} 
            {!(currentUser?.contactListUser == true &&
              currentUser?.categories == null &&
              currentUser?.showBlockSwitch == true) && isUserBlocked ? <p>{`Chat blocked by ${currentUser?.alias}`} </p> : null}
            {isUserBlocked && (currentUser?.contactListUser == true &&
              currentUser?.categories == null &&
              currentUser?.showBlockSwitch == true) ? <p>Chat blocked by you</p> : null}
          </div>
        </> :
        null}
    </div>
  )
}

export default ChatHistory;