import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { LoginContext } from '../../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { getImage } from '../../personalDetails/personalDetailsService';
import UserIcon from '../../../assets/images/user-icon01.png';
import heic2any from 'heic2any';
import Spinner from '../../../components/spinner';
import { getAllGalleries, updateImageMetadata } from '../personalDetailsService';
import Alert from 'react-bootstrap/Alert';

function ViewAllGalleries(props) {
    const { close, show, base64Image, type, setAvatar, handleClose } = props;
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [allGalleries, setAllGalleries] = useState(null)
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedGalleryId, setSelectedGalleryId] = useState(null);
    const [selectedImageURL, setSelectedImageURL] = useState(null)
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    useEffect(() => {
        const payloadData = {
            accessToken: accessToken,
            language: i18n.language
        }
        getAllGalleries(payloadData).then((result) => {
            if (result?.status === 200) {
                setAllGalleries(result.data.galleries)
            }
            else {
                setAllGalleries(null)
                setShowErrorAlert(true)
            }
        })
    }, [])

    const onGalleryClick = (gallery) => {
        setGalleryImages([])
        setIsGalleryOpen(true);
        const images = gallery?.mediaDTOS;
        const galleryId = gallery.id;
        const galleryName = gallery.name;
        if (images.length) {
            images.forEach(async (image) => {
                try {
                    const imageUrl = await onGetImage(image.id);
                    setGalleryImages((prevImages) => [
                        ...prevImages,
                        { image, imageUrl, galleryId, galleryName },
                    ]);
                } catch (error) {
                    console.error(`Error fetching image for ID ${image.id}:`, error);
                }
            });
        } else {
            setIsGalleryOpen(false);
        }
    };

    const onGetImage = (imageId) => {
        return new Promise((resolve, reject) => {
            const data = {
                accessToken: accessToken,
                language: i18n.language,
                id: imageId
            };

            getImage(data).then(async (response) => {
                if (response.status === 200) {
                    const blob = await response.blob();
                    let fileURL;
                    if (blob.type === 'image/heif') {
                        const jpegBlob = await heic2any({
                            blob: blob,
                            toType: 'image/jpeg',
                            quality: 1
                        });
                        fileURL = URL.createObjectURL(jpegBlob);
                    } else {
                        fileURL = URL.createObjectURL(blob);
                    }
                    resolve(fileURL);
                } else {
                    reject(new Error("Failed to get image."));
                }
            }).catch(error => {
                reject(error);
            });
        });
    };

    const onBack = () => {
        setIsGalleryOpen(false);
    }

    const saveImageAsAvatar = () => {
        const imageMetadata = {
            id: selectedImageId,
            makePartnershipProfileImage: type === 'partnership' ? true : false,
            makeFriendshipProfileImage: type === 'friendship' ? true : false
        }
        const payload = {
            language: i18n.language,
            accessToken: accessToken,
            renameData: imageMetadata,
            id: selectedGalleryId
        }
        updateImageMetadata(payload).then((response) => {
            if (response.status === 200) {
                setAvatar(selectedImageURL)
                close()
                handleClose()
            } else {
                setShowErrorAlert(true)
            }
        })
    }

    const updateImageSelection = (imageId, galleryId, imageUrl) => {
        setSelectedImageId(imageId)
        setSelectedGalleryId(galleryId)
        setSelectedImageURL(imageUrl)
    }

    return (
        <Modal className='openprofile' show={show} size='lg' onHide={() => close()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('suggestions.sharedwithowngallery')}</Modal.Title>
            </Modal.Header>
            <ModalBody className='s_share_gallery'>
                <Alert className='alert_questionaire' show={showErrorAlert} dismissible
                    onClose={() => setShowErrorAlert(false)}
                    variant="danger" id={`err_1`}>{t('cropper.requestFailed')}</Alert>
                {!isGalleryOpen ? <>
                    {allGalleries?.length ?
                        <>
                            {allGalleries?.map((gallery) => (
                                gallery.mediaDTOS ?
                                    <div key={gallery?.id}>
                                        <div
                                            onClick={() => onGalleryClick(gallery)}
                                            className='s_gallery_container'>
                                            <img className='' src={base64Image(gallery?.thumbnails?.[0]) ? base64Image(gallery?.thumbnails?.[0]) : UserIcon} />
                                        </div>
                                        <div className='g_name_container'>
                                            <span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            ))}
                        </> :
                        <div className='openprofile_spinner'>
                            <Spinner />
                        </div>
                    }</>
                    :
                    <>{galleryImages?.length ?
                        <div className='image-wrapper'>
                            <Form.Group className='list_btn' >
                                <div>
                                    <Button
                                        onClick={() => onBack()}
                                        className='mb-3 mt-1'
                                        variant="outline-danger"
                                    >
                                        <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                        {t('suggestions.back')}
                                    </Button>
                                    <Button
                                        onClick={() => saveImageAsAvatar()}
                                        className='mb-3 mt-1'
                                        variant='primary'
                                    > {t('suggestions.save')}
                                    </Button>
                                </div>
                            </Form.Group>
                            <div className='images_container'>
                                {galleryImages?.map((image) => (
                                    <div key={image.image.id} id={image.image.id} className='g_container'>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedImageId === image.image.id}
                                                onChange={() => updateImageSelection(image.image.id, image.galleryId, image.imageUrl)}
                                                className='msg_pre_checkbox'
                                            />
                                        </Form.Group>

                                        <div className='gallery_images'>
                                            <img
                                                className='image'
                                                src={image.imageUrl}
                                                alt={image.image.name}
                                            />

                                        </div>
                                        <div className='g_name_container'>
                                            <span className='galleryname'>
                                                {image.image.name.length > 40 ? `${image.image.name.slice(0, 40)}...` : image.image.name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                            </div>
                            <Form.Group className='list_btn' >
                                <div>
                                    <Button
                                        onClick={() => onBack()}
                                        className='mb-3 mt-1'
                                        variant="outline-danger"
                                    >
                                        <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                        {t('suggestions.back')}
                                    </Button>
                                    <Button
                                        onClick={() => saveImageAsAvatar()}
                                        className='mb-3 mt-1'
                                        variant='primary'
                                    > {t('suggestions.save')}
                                    </Button>
                                </div>
                            </Form.Group>
                        </div> :
                        <div className='openprofile_spinner'>
                            <Spinner />
                        </div>
                    }
                    </>
                }
            </ModalBody>
        </Modal>
    )
}
export default ViewAllGalleries;

