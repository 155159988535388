import React, { useEffect, useState, useContext } from 'react'
import {Modal, Alert, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { LoginContext } from '../../../context/loginContext';
import { saveQuestionaire } from '../../personalDetails/personalDetailsService';

import { useTranslation } from 'react-i18next';

function ProfileQuestions(props) {
	
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let {
        loginData: {
            accessToken,
            memberDetails
        },
    } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
      }

    useEffect(() => {
        if (props.profileData?.data.choiceQuestion?.type === "Checkbox" || props.profileData?.data.choiceQuestion?.type === "Radio" || props.profileData?.data.choiceQuestion?.type === "ComboBox")
            setSelectedAnswer(prevAnswers =>
            ({
                ...prevAnswers,
                [props.profileData?.data.id]: [props.profileData?.data?.choiceQuestion?.selectedChoices]
            })
            );
        else if (props.profileData?.data?.type === 'TextQuestion') {
            setSelectedAnswer(prevAnswers =>
            ({
                ...prevAnswers,
                [props.profileData?.data.id]: [props.profileData?.data?.textQuestion?.answer]
            })
            );
        }
        else if (props.profileData?.data?.type === 'NumberQuestion') {
            setSelectedAnswer(prevAnswers =>
            ({
                ...prevAnswers,
                [props.profileData?.data.id]: [props.profileData?.data?.numberQuestion?.answer]
            })
            );
        }
    }, [props])
    const onCheckboxChange = (questionId, answer) => {
        const answers = selectedAnswer[questionId][0];
        const checkedValue = answers.find(item => item.id === answer.id);
        if (checkedValue) {
            const index = answers.indexOf(checkedValue);
            answers.splice(index, 1)
        } else {
            answers.push(answer)
        }
        setSelectedAnswer({ ...selectedAnswer });
    };


    const onDataChange = (questionId, answer) => {
        selectedAnswer[questionId][0] = [answer];
        setSelectedAnswer({ ...selectedAnswer });
    }

    const convertData = (data, questionId, questions) => {
        const convertedData = {
            "request": {
                [questionId]: {
                    "answerValues": data?.map(item => item.id.toString()),
                    "profileVisible": questions.askVisibleToOthers && questions.visibleToOthers
                }
            }
        };
        return convertedData;
    };

    const onSaveQuestionair = (questions, questionId, answer) => {
        let answerData;
        if (questions.type === 'ChoiceQuestion' && questions.choiceQuestion.multiSelect === true) {
            const convertedData = convertData(selectedAnswer[questionId][0], questionId, questions);
            answerData = convertedData.request;
        }
        else {
            answerData = { [questionId]: { answerValues: [answer], profileVisible: questions.askVisibleToOthers && questions.visibleToOthers} };
        }
        const answerBody = {
            questionnaireId: props?.profileData?.id,
            answers: answerData
        }
        saveQuestionaire(answerBody, token_lang).then((result) => {
            if(result?.status != 200){
                setIsError(true);
                setErrorMessage(result.data.message);
            }
        })
    }

    let dataToBeRender;
    if (props.profileData?.data.type === "ChoiceQuestion") {
        if (props.profileData?.data.choiceQuestion?.type === "Checkbox") {
            dataToBeRender = <div>
                <Form.Label id={props.profileData?.data.id}>{props.profileData?.data.name}
                    <span className='required_asterisks'>{props.profileData?.data.required ? '*' : null}</span>
                </Form.Label>
                <div className='profile_grid'>
                    {props.profileData?.data?.choiceQuestion.choices.map(choice => (
                        <Form.Group key={choice.id} className="category_f " id={choice.id}>
                            <Form.Check
                                checked={selectedAnswer[props.profileData?.data?.id]?.[0]?.find(id => id.id == choice.id)}
                                id={`${props.profileData?.data?.id}-${choice?.value}`}
                                name={props.profileData?.data?.name}
                                type="checkbox"
                                label={choice?.value}
                                className='msg_pre_checkbox'
                                onChange={(e) => {
                                    onCheckboxChange(props.profileData?.data?.id, choice);
                                    onSaveQuestionair(props.profileData?.data, props.profileData?.data?.id, choice.id);
                                }}
                            >
                            </Form.Check>
                        </Form.Group>
                    ))}
                </div>
            </div>
        }
        if (props.profileData?.data.choiceQuestion?.type === "ComboBox") {
            dataToBeRender = <Form.Group className="mt-3 mb-3 dropdown profile_combox">
                <Form.Label id={props.profileData?.data.id}>{props.profileData?.data.name}
                    <span className='required_asterisks'>{props.profileData?.data.required ? '*' : null}</span>
                </Form.Label>
                <Form.Select
                    id={props.profileData?.data.id}
                    onChange={(e) => {
                        const selectedChoice = props.profileData?.data.choiceQuestion.choices.find(choice => choice.value === e.target.value);
                        onDataChange(props.profileData?.data.id, selectedChoice);
                        onSaveQuestionair(props.profileData.data, props.profileData?.data.id, e.target.value)
                    }}
                >
                    <option>{t('registration.questionnaires.pleaseSelectOption')}</option>
                    {props.profileData?.data.choiceQuestion?.choices.map(choice => (
                        <option
                            selected={choice.id === props.profileData?.data?.choiceQuestion?.selectedChoices[0]?.id}
                            key={choice.id}
                            value={choice.id}
                        >
                            {choice.value}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        }
        if (props.profileData?.data.choiceQuestion?.type === "Radio") {
            dataToBeRender = <div className='profile_combox'>
                <Form.Label id={props.profileData?.data.id}>{props.profileData?.data.name}
                    <span className='required_asterisks'>{props.profileData?.data.required ? '*' : null}</span>
                </Form.Label>
                <div className='profile_radio'>
                    {props.profileData?.data?.choiceQuestion.choices.map(choice => (
                        <Form.Group key={choice.id} className="category_f" id={choice.id}>
                            <Form.Check
                                checked={selectedAnswer[props.profileData?.data?.id]?.[0]?.find(id => id.id == choice.id)}
                                id={`radio-${choice.id}`}
                                type="radio"
                                label={choice.value}
                                className='msg_pre_checkbox'
                                onChange={(e) => {
                                    onDataChange(props.profileData?.data.id, choice);
                                    onSaveQuestionair(props.profileData?.data, props.profileData?.data?.id, choice.id)
                                }}
                            />
                        </Form.Group>
                    ))}
                </div>
            </div>
        }
    }
    else if (props.profileData?.data.type === 'NumberQuestion') {
        dataToBeRender = <Form.Group className="mt-3 dropdown profile_combox" controlId="formGridState">
            <Form.Label className="" htmlFor={props.profileData?.data.id}>{props.profileData?.data.name}<span className='required_asterisks'>{props.profileData?.data.required ? '*' : null}</span></Form.Label>
            <Form.Control
                value={selectedAnswer[props.profileData?.data?.id]?.[0]}
                type="number"
                id={props.profileData?.data.id}
                aria-describedby={props.profileData?.data.name}
                min={props.profileData?.data.numberQuestion.minValue}
                max={props.profileData?.data.numberQuestion.maxValue}
                placeholder={props.profileData?.data.numberQuestion.placeholder}
                onBlur={(e) =>
                    onSaveQuestionair(props.profileData?.data, props.profileData?.data.id, e.target.value)
                }
                onChange={(e) => {
                    onDataChange(props.profileData?.data.id, e.target.value);
                }}
            />
        </Form.Group>
    }
    else if (props.profileData?.data.type === 'TextQuestion') {
        dataToBeRender = <Form.Group className="mt-3 dropdown profile_combox" controlId="formGridState">
            <Form.Label className="" htmlFor={props.profileData?.data.id}>{props.profileData?.data.name}<span className='required_asterisks'>{props.profileData?.data.required ? '*' : null}</span></Form.Label>
            <Form.Control
                value={selectedAnswer[props.profileData?.data?.id]?.[0]}
                as="textarea"
                id={props.profileData?.data.id}
                aria-label={props.profileData?.data.name}
                placeholder={props.profileData?.data.textQuestion?.placeholder}
                onBlur={(e) =>
                    onSaveQuestionair(props.profileData?.data, props.profileData?.data.id, e.target.value)
                }
                onChange={(e) => {
                    onDataChange(props.profileData?.data.id, e.target.value);
                }}
            />
        </Form.Group>

    }

    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} 
            show={props.show} 
            onHide={() => {
                props.handleClose();
                props.setRefreshProfileData(true);
            }} 
            >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> {memberDetails?.alias}</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                    {isError ?
                        (
                            <Alert
                                className='member_address mt-2'
                                variant="danger"
                                id='main_alert'
                                dismissible
                            >
                                <p>{errorMessage}</p>
                            </Alert>
                        ) : null}
                    {dataToBeRender}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfileQuestions;