import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import './photogallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPen,
    faArrowUpFromBracket,
    faCopy,
    faTrashCan,
    faUserPen,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import {
    OverlayTrigger,
    Tooltip,
    Form,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap';
import { uploadImage, updateImage, updateImageMetadata } from './personalDetailsService';
import ShareOrMoveImage from './shareOrMoveImage';
import ImageCarousel from './imageCarousel';
import heic2any from 'heic2any';
import ImagesGalleryPopup from './deleteImagePopup';
import Cropper from './cropper'

function ImagesGallery(props) {
    const { galleryImages, currentGallery, isGalleryLoading, onGalleryClick } = props;
    const inputImageSelect = useRef(null);
    const [images, setImages] = useState([]);
    const [imageName, setImageName] = useState('');
    const [imageId, setImageId]=useState()
    const [currentImageName, setCurrentImageName]=useState('')
    const [isRename, setIsRename] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isShareOrMoveOrDeleteImage, setIsShareOrMoveOrDeleteImage] = useState(false);
    const [selectTargetGallery, setSelectTargetGallery] = useState([]);
    const [copyOrMoveId, setCopyOrMoveId] = useState();
    const [isCarouselOpen, setIsCarouselOpen] = useState(null)
    const [isFileTypeError, setIsFileTypeError]=useState(null)
    const [show, setShow] = useState(false);
    const [type, setType] = useState(null);
    const [isErrorForDeleteOrMove, setIsErrorForDeleteOrMove] = useState(false);
    const [errorMsgForDeleteOrMove, setErrorMsgForDeleteOrMove] = useState();
    const [isErrorRename, setIsErrorRename] = useState(false);
    const [errorRenameMsg, setErrorRenameMsg] = useState();
    const [isImageCrop, setIsImageCrop] = useState(false);
    const [currentGalleryForCropper, setCurrentGalleryForCropper] = useState(currentGallery);
    const [isImageAdded, setIsImageAdded] = useState(false)
    const [unUplodedImageError,setUnUplodedImageError]=useState([]);
    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    useEffect(() => {
        setImages(galleryImages);
    }, [galleryImages]);

    const addNewImage = async (e) => {
        setIsImageAdded(true)
        e.preventDefault();
        if (e.target?.files.length > 0) {
            const fileData = e.target.files;
            let formData = new FormData();
            let newImages = [];
            let newImageUrl;
            for (const file of fileData) {
                if (file.type == 'image/heif'|| file.type == 'image/heic') {
                    const jpegBlob = await heic2any({
                      blob: file,
                      toType: 'image/jpeg',
                      quality: 1
                    });
                    newImageUrl = URL.createObjectURL(jpegBlob);
                  } else {
                    newImageUrl = URL.createObjectURL(file);
                  }
                formData.append('files', file, file.name);

                newImages.push({
                    image: {
                        id: '',
                        name: file.name
                    },
                    imageUrl: newImageUrl
                });
            }

            const uploadData = {
                form: formData,
                language: i18n.language,
                accessToken: accessToken,
                id: currentGallery?.id
            };

            const result = await uploadImage(uploadData);
            if (result?.status === 200 && result?.data?.uploadedFiles) {
              setIsFileTypeError()
              setIsImageAdded(false)
                const uploadedFiles = result.data.uploadedFiles;
                newImages = newImages.map(newImage => {
                    const uploadedFileId = uploadedFiles[newImage.image.name];
                    if (uploadedFileId) {
                        return {
                            ...newImage,
                            image: {
                                ...newImage.image,
                                id: uploadedFileId
                            }
                        };
                    }
                    return newImage;
                });
                setImages(prevImages => [...prevImages, ...newImages]);
                props.setIsImageAddedInGallery(!props.isImageAddedInGallery)
            }else if(result?.status === 406 && result?.data?.uploadedFiles){
                setIsFileTypeError()
                setIsImageAdded(false)
                const uploadedFiles = result.data.uploadedFiles;
                const successfullyUploadedImages = [];
    
                for (const [fileName, fileId] of Object.entries(uploadedFiles)) {
                    const matchingImage = newImages.find(newImage => newImage.image.name === fileName);
                    if (matchingImage) {
                        successfullyUploadedImages.push({
                            ...matchingImage,
                            image: {
                                ...matchingImage.image,
                                id: fileId,
                            },
                        });
                    }
                }
                if (successfullyUploadedImages.length > 0) {
                    setImages((prevImages) => [...prevImages, ...successfullyUploadedImages]);
                    props.setIsImageAddedInGallery(!props.isImageAddedInGallery)
                    setUnUplodedImageError(result.data.skippedFiles)
                }
            }
            else {
                setIsImageAdded(false)
                setIsFileTypeError(result.data.errorMessages?.length ? result.data.errorMessages[0] : result.data.message)
            }
        }
    };

    const imageRename = (image) => {
        setIsRename(true);
        setCurrentImage(image);
        setImageName(image.name);
    };

    const onSaveRenamedImgage = (image) => {
        onGalleryClick()
        const renameData = {
            id: image.id,
            name: imageName,
            description: '',
            makePartnershipProfileImage: false,
            makeFriendshipProfileImage: false
        };
        const data = {
            language: i18n.language,
            accessToken: accessToken,
            renameData: renameData,
            id: currentGallery?.id,
        };
        updateImageMetadata(data).then((result) => {
            if (result?.status === 200) {
                setImages(prevImages =>
                    prevImages.map(img =>
                        img.image.id === image.id ? { ...img, image: { ...img.image, name: imageName } } : img
                    )
                );
                setIsRename(false);
                props.setIsImageAddedInGallery(!props.isImageAddedInGalley)
            } else {
                setIsErrorRename(true);
                setErrorRenameMsg(result?.data?.message);
                setIsRename(false);
            }
        });
    };

    const moveOrCopyOrDeleteImage = (ids, operationName) => {

        if (!selectTargetGallery.length && operationName != 'delete') {
            setIsErrorForDeleteOrMove(true);
            setErrorMsgForDeleteOrMove(t('member.gallery.selectgallery'));
            return;
        }
        
        const updatedImages = {
            mediaIds: Array.isArray(ids) ? ids : [ids],
            targetGalleryIds: selectTargetGallery,
            move: operationName == 'move',
            delete: operationName == 'delete',
            copy: operationName == 'copy'
        };
        const data = {
            language: i18n.language,
            accessToken: accessToken,
            updatedImages: updatedImages,
            galleryId: currentGallery?.id
        };
        updateImage(data).then((response) => {
            if (response?.status === 200) {
                setShow(false)
                setSelectTargetGallery([])
                if (operationName == 'copy') {
                    setIsShareOrMoveOrDeleteImage(false);
                    setCopyOrMoveId();
                    setSelectedImages([])
                    props.setIsImageAddedInGallery(!props.isImageAddedInGalley)
                } else {
                    setShow(false)
                    setImages(prevImages => prevImages.filter(img => !updatedImages.mediaIds.includes(img.image.id)));
                    setIsShareOrMoveOrDeleteImage(false);
                    setCopyOrMoveId();
                    setSelectedImages([])
                    props.setIsImageAddedInGallery(!props.isImageAddedInGalley);
                }
            } else {
                setIsErrorForDeleteOrMove(true);
                setErrorMsgForDeleteOrMove(response?.data?.message);
            }
        });
    };

    const handleCheckboxChange = (imageId) => {
        setSelectedImages(prevSelectedImages => {
            if (prevSelectedImages.includes(imageId)) {
                return prevSelectedImages.filter(id => id !== imageId);
            } else {
                return [...prevSelectedImages, imageId];
            }
        });
    };

    const toBeMovedOrCopyImages = (id, operationName) => {
        setCopyOrMoveId(operationName);
        setIsShareOrMoveOrDeleteImage(true)
        setIsErrorForDeleteOrMove(false);
        setSelectedImages(Array.isArray(id) ? id : [id])
    }

    let cropImgErrTimeoutId;
    const cropImage = (image) => {        
        setIsImageCrop(true)
        setCurrentImage(image);
        setCurrentGalleryForCropper(currentGallery)
        if (cropImgErrTimeoutId) {
            clearTimeout(cropImgErrTimeoutId);
        }
        // cropImgErrTimeoutId = setTimeout(() => {
        //     setCurrentImage(null);
        // }, 3000);
    }     

    return (
        <div className='image-wrapper'>
            <div className='images_container'>
                {images?.map((image, index) => (
                    <div key={image.image.id} id={image.image.id} className='g_container'>
                        <div className='gallery_images'>
                            <div className='image_select'>
                                <Form.Group className="select_edit_img" >
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectedImages.includes(image.image.id)}
                                        onChange={() => handleCheckboxChange(image.image.id)}
                                        className='msg_pre_checkbox'
                                    />
                                </Form.Group>
                            </div>
                            <img
                                className='image'
                                src={image.imageUrl}
                                alt={image.image.name}
                                onClick={() => setIsCarouselOpen(index)}
                            />
                            <div className='category_icons'>
                                <OverlayTrigger
                                    key='edit'
                                    placement='top'
                                    overlay={<Tooltip id={`tooltip-edit`}>{t('member.gallery.edit')}</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        onClick={() => imageRename(image.image)}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='share'
                                    placement='top'
                                    overlay={<Tooltip id={`tooltip-move`}>{t('member.gallery.move')}</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowUpFromBracket}
                                        onClick={() => toBeMovedOrCopyImages(image.image.id, 'move')}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='Duplicate'
                                    placement='top'
                                    overlay={<Tooltip id={`tooltip-duplicate`}>{t('member.gallery.duplicate')}</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        onClick={() => toBeMovedOrCopyImages(image.image.id, 'copy')}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='delete'
                                    placement='top'
                                    overlay={<Tooltip id={`tooltip-delete`}>{t('member.gallery.delete')}</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        onClick={() => (setShow(true), setImageId(image.image.id), setType("delete"),setCurrentImageName(image.image.name))}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='editImage'
                                    placement='top'
                                    overlay={<Tooltip id={`tooltip-editImage`}>{t('member.gallery.editimage')}</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPen}
                                        onClick={() => cropImage(image)}
                                    />
                                </OverlayTrigger>
                            </div>
                        </div>                        
                        {isImageCrop && images?.length >= 10 && currentImage?.image?.id === image.image.id  ?
                                <Alert
                                    className='member_address'
                                    variant="danger"
                                    id='main_alert'
                                    onClose={() => setIsImageCrop(false)}
                                >
                                    {t('member.gallery.notcropmsg')}
                                </Alert> : null}
                        {isRename && currentImage?.id === image.image.id ? (
                            <Form.Group className="" controlId="formGridState">
                                <Form.Control
                                    value={imageName}
                                    onChange={(e) => setImageName(e.target.value)}
                                    onBlur={() => onSaveRenamedImgage(image.image)}
                                    autoFocus
                                />
                            </Form.Group>
                        ) : (
                            <div className='g_name_container'>
                                <span className='galleryname'>
                                    {image.image.name.length > 40 ? `${image.image.name.slice(0, 40)}...` : image.image.name}
                                </span>
                            </div>
                        )}
                    </div>
                ))}
                {isImageAdded ? <Spinner className='spinner_cropper sp_img' animation="border" variant="light" /> : null}
            </div>
            {isFileTypeError || images?.length >= 10 ? (
                <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    dismissible
                >
                    {isFileTypeError ? isFileTypeError : t('member.gallery.maxImages')}
                </Alert>
            ):null}
            <div>
                {isErrorRename ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    dismissible
                    onClose={() => setIsErrorRename(false)}
                >
                    <p>{errorRenameMsg}</p>
                </Alert> : null}
                {!isGalleryLoading && images?.length < 10   ? (
                    <Form.Group className='list_btn add_g_btn'>
                        <Button
                            onClick={() => inputImageSelect.current?.click()}
                            className='mt-1'
                            variant="primary"
                        >
                            {t('member.gallery.addimage')}
                        </Button>
                    </Form.Group>
                ) : null}
                <input
                    id="file-upload"
                    ref={inputImageSelect}
                    name="audio-and-image-upload"
                    onChange={(e) => addNewImage(e)}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                />
                
            </div>
            {selectedImages.length >= 2 ? <div className='category_icons'>
            <span className='icons_span'>
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        onClick={() => setSelectedImages([])}
                    />
                    {t('member.gallery.unselectAll')}
                </span>
                <span className='icons_span'>
                    <FontAwesomeIcon
                        icon={faArrowUpFromBracket}
                        onClick={() => toBeMovedOrCopyImages(selectedImages, 'move')}
                    />
                    {t('member.gallery.move')}
                </span>
                <span className='icons_span'>
                    <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => toBeMovedOrCopyImages(selectedImages, 'copy')}
                    />
                    {t('member.gallery.duplicate')}
                </span>
                <span className='icons_span'>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        onClick={() => moveOrCopyOrDeleteImage(selectedImages, 'delete')}
                    />
                    {t('member.gallery.delete')}
                </span>
            </div> : null}
            {isShareOrMoveOrDeleteImage ? <ShareOrMoveImage
                show={isShareOrMoveOrDeleteImage}
                setShow={setIsShareOrMoveOrDeleteImage}
                setSelectTargetGallery={setSelectTargetGallery}
                moveOrCopyOrDeleteImage={moveOrCopyOrDeleteImage}
                selectTargetGallery={selectTargetGallery}
                selectedImages={selectedImages}
                copyOrMoveId={copyOrMoveId}
                isError={isErrorForDeleteOrMove}
                setIsError={setIsErrorForDeleteOrMove}
                errorMsg={errorMsgForDeleteOrMove}
            /> : null}
            {isImageCrop ?
             <Cropper
                show={isImageCrop}
                setShow={setIsImageCrop} 
                setCurrentImage={setCurrentImage}
                currentImage={currentImage} 
                setImages={setImages}
                images={images}     
                currentGallery={currentGalleryForCropper}         
            /> : null}    
                    
            {unUplodedImageError.length > 0  ? unUplodedImageError.map((error)=>(
                <Alert
                    className="member_address"
                    variant="warning"
                    id="main_alert"
                    dismissible
                    onClose={() => setIsImageCrop(false)}
                    key={error}
                >
                    {error}
                </Alert>
            ))
            : null}        
            {isCarouselOpen != null ? <ImageCarousel
                images={images}
                show={isCarouselOpen}
                setShow={setIsCarouselOpen}
            /> : null}
           
            <ImagesGalleryPopup
              show={show}
              setShow={setShow}
              currentGalleryData={currentGallery}
              moveOrCopyOrDeleteImage={moveOrCopyOrDeleteImage}
              imageId={imageId}
              type={type}
              currentImageName={currentImageName}
            />
        </div>
    );
}

export default ImagesGallery;
