export const baseUrl = process.env.REACT_APP_BASE_URL

const reuseUserURL='member/reuse/data'
const resetUserURL='member/reset/data'


export async function reuseUser(accessToken, language) {
    return fetch(`${baseUrl}/${reuseUserURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'Accept-Language': language,
        }
      }).then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        })).then((response) => {
          return response;
        })    
      ).catch((err) => {
        throw err;
      });
}


export async function resetUser(accessToken, language) {
    return fetch(`${baseUrl}/${resetUserURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'Accept-Language': language,
        }
      }).then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        })).then((response) => {
          return response;
        })    
      ).catch((err) => {
        throw err;
      });
}

