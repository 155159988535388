import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { saveQuestionaire } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

function EditFreeText(props) {
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [isFreeTextLengthValid, setIsFreeTextLengthValid] = useState(false);
    const [errResponse, setErrResponse] = useState();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let {
        loginData: {
          accessToken
        },
      } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
      }

    useEffect(() => {
        props?.profileData?.freeTextQuestionnaire?.questionGroups.map((item, index) => (
            item?.questions?.map((data) => {
                if (data?.type === 'TextQuestion') {
                    setSelectedAnswer(prevAnswers =>
                    ({
                        ...prevAnswers,
                        [data.id]: [data?.textQuestion?.answer]
                    })
                    );
                }
            }
            ))
        )
    }, [])

    const onDataChange = (questionId, answer) => {
        selectedAnswer[questionId] = [answer];
        setSelectedAnswer({ ...selectedAnswer });
      }

    const onSaveQuestionair = (questionId, answer) => {        
        const answerData = { [questionId]: { answerValues: [answer], profileVisible: false } };
        const answerBody = {
          questionnaireId: props?.profileData?.freeTextQuestionnaire?.id,
          answers: answerData
        }
        saveQuestionaire(answerBody, token_lang).then((response) => {
            if (response?.status === 200) {
                setErrResponse(null)
                props.setRefreshQuestionaire(true);
            } else {
                setIsError(true);
                setErrorMessage(response.data.message);
                setErrResponse(response.data)
            }
        })
      }


    function stripHtml(html){
        var temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    const onFreetextSave = (e, items) => {
        if(e.target.value.length < 200 && items.name==="Free text"){
            setIsFreeTextLengthValid(true);
        }else{
            setIsFreeTextLengthValid(false)
            onSaveQuestionair(items.id, e.target.value)
        }
    }


    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} show={props.show} onHide={() => props.handleClose()} >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> {t('myprofile.editprofile')}</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                    {isError ?
                        (
                            <Alert
                                className='member_address mt-2'
                                variant="danger"
                                id='main_alert'
                                dismissible
                            >
                                <p>{errorMessage}</p>
                            </Alert>
                        ) : null}
                    <div className="side_border">
                        <h3 className="headings">{props.profileData?.freeTextQuestionnaire.name}</h3>
                        <div className="descriptions">
                            <p dangerouslySetInnerHTML={{ __html: props.profileData?.freeTextQuestionnaire.description }} />
                        </div>
                    </div>                    
                    {props.profileData?.freeTextQuestionnaire.questionGroups?.map((data) => (
                        <div className="modal_content side_border">
                            <h3 className="headings">{data.name}</h3>
                            <div className="descriptions">
                                <p dangerouslySetInnerHTML={{ __html: data.description }} />
                            </div>
                            <div className='freetext_details'>
                                {
                                    data.questions.map((items) => {
                                        if (items?.type === "TextQuestion"){                                           
                                            return (<>
                                                <Alert className='alert_questionaire' show={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[items.id] ? true : false}
                                                    variant="danger" id={`err_${items.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[items.id]}</Alert>
                                                <Form.Group className=" text_question" id='textquestion'>
                                                    <Form.Label className="" htmlFor={items.id}>{items.name}<span className='required_asterisks'>{items.required ? '*' : null}</span></Form.Label>
                                                    <Form.Control
                                                        value={stripHtml(selectedAnswer[items?.id]?.[0])}
                                                        as="textarea"
                                                        id={items.id}
                                                        aria-describedby={items.name}
                                                        onBlur={(e) => onFreetextSave(e, items)}
                                                        onChange={(e) => {
                                                            onDataChange(items.id, e.target.value);
                                                        }}
                                                    />
                                                    {isFreeTextLengthValid && items.name === "Free text" ?
                                                        (
                                                            <Alert
                                                                className='member_address mt-2'
                                                                variant="danger"
                                                                id='main_alert'
                                                                dismissible
                                                            >
                                                                <p>{t('suggestions.freetextlimit')}</p>
                                                            </Alert>
                                                        ) : null}
                                                </Form.Group>
                                                </>)}

                                        if (items.choiceQuestion?.type === "ComboBox")
                                            return (
                                                <Form.Group className=" mb-3 combo_box" id='combobox'>
                                                    <Form.Label id={data.id}>{data.name}
                                                        <span className='required_asterisks'>{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        id={data.id}
                                                        onChange={(e) => {
                                                            const selectedChoice = items?.choiceQuestion.choices.find(choice => choice.id === e.target.value);
                                                            onDataChange(items.id, selectedChoice);
                                                            onSaveQuestionair(items.id, e.target.value)
                                                          }}
                                                    >
                                                        <option>{t('registration.buttons.pleaseselect')}</option>
                                                        {items.choiceQuestion.choices.map(choice => (
                                                            <option
                                                                selected={choice.id === items.choiceQuestion?.selectedChoices[0]?.id}
                                                                key={choice.id}
                                                                value={choice.id}
                                                            >
                                                                {choice.value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            )
                                    }
                                    )}
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditFreeText;