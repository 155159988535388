import React, { useEffect, useContext, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import profileLogo from '../../../assets/images/icon_profile_red.png';
import pen from '../../../assets/images/edit_pencil.png';
import userIcon from '../../../assets/images/user-icon01.png';
import { getAvatar, getAudio, getProfileQustions } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import './myprofile.css';
import EditFreeText from './editFreeText';
import EditStatus from './editStatus';
import Spinner from '../../../components/spinner';
import Player from '../../../components/Audioplayer/Player';
import UploadImage from './uploadImage';
import Accordion from 'react-bootstrap/Accordion';
import ProfileQuestions from './profileQuestions';


function Myprofile(props) {
	
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false)
    const [avatar, setAvatar] = useState(null)
    const [audio, setAudio] = useState(null)
    const [isUploadImgeOpen, setIsUploadImgeOpen] = useState(false)
    const [profileQ, setProfileQ] = useState()
    const [isProfileQuestionOpen, setIsProfileQuestionOpen] = useState(false);
    const [storeProfileQ, setStoreProfileQ] = useState();
    const [refreshProfileData, setRefreshProfileData] = useState(false)

    const { profileData } = props;
    let {
        loginData: {
            accessToken,
            memberDetails
        },
    } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const token_lang = {
        language: currentLang,
        accessToken
    }

    const openFreeTextPopup = () => {
        setIsPopupOpen(true)
    }

    const closeFreeTextPopup = () => {
        setIsPopupOpen(false)
    }

    const openStatusPopup = () => {
        setIsStatusPopupOpen(true)
    }

    const closeStatusPopup = () => {
        setIsStatusPopupOpen(false)
    }

    useEffect(() => {
        if (props?.profileData?.avatar && props.profileData.avatar !== null && (!avatar || avatar === null)) {
            const data = {
                accessToken: accessToken,
                language: currentLang,
                avatarId: props.profileData.avatar
            }
            getAvatar(data).then((response) => {
                processImage(response)
            })
        }
    }, [props.profileData])

    const processImage = async (response) => {
        if (response.status === 200) {
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            setAvatar(fileURL)
        }
    }

    useEffect(() => {
        if (props?.profileData?.audio && props.profileData.audio !== null && (!audio || audio === null)) {
            const data = {
                accessToken: accessToken,
                language: currentLang,
                audioId: props.profileData.audio
            }
            getAudio(data).then((response) => {
                processAudio(response)
            })
        }
    }, [props.profileData])

    const processAudio = async (response) => {
        if (response.status === 200) {
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            setAudio(fileURL)
        }
    }

    const openUploadImagePopup = () => {
        setIsUploadImgeOpen(true)
    }

    const closeUploadImagePopup = () => {
        setIsUploadImgeOpen(false)
    }

    useEffect(() => {
        if (props) {
            getProfileQustions(token_lang, props.type).then((result) => {
                if (result.status === 200) {
                    setProfileQ(result?.data);
                    setRefreshProfileData(false);
                }
            })
        }
    }, [refreshProfileData, currentLang])

    const onQuestionsOpen = (data, id, desc) => {
        const profileToBeOpenData = {
           data,
            id: id,
            description: desc
        }
        setStoreProfileQ(profileToBeOpenData)
        setIsProfileQuestionOpen(true)
    }

    const onQuestionsClose = () => {
        setIsProfileQuestionOpen(false)
    }
		
    return (
        <>
            {profileData != null ?
                <div className="questionaire_in_menubar profile_wrapper">
									<div className="myprofile_header">
										<div className="visual_audio_wrapper">
											<div className="myprofile_image">
													<div className="user_img white_bg">
														<img src={avatar && avatar !== null ? avatar : userIcon} alt={"Avatar"+memberDetails?.alias} />
													</div>
													<span onClick={() => openUploadImagePopup()} className='edit_image'>
														<FontAwesomeIcon icon={faPen} /> {t('myprofile.changeProfileImage')}
													</span>
											</div>	
											{audio && audio !== null ?
												<div className="myprofile_audio">
												<strong className="myprofile_label">{t('myprofile.audio')}</strong>
													<Player
														audioUrl={audio}
													/>
												</div> : null}									
										</div>
										<div className="text_wrapper">
											<div className="myprofile_info">
												<strong className="myprofile_label">{t('myprofile.alias')}</strong>
												<h3 className="partnership_color">{memberDetails?.alias}</h3>
											</div>
											<div className="myprofile_status">
													<strong className="myprofile_label">{t('myprofile.status')}</strong>
													<span onClick={() => openStatusPopup()}  className='edit_status'>
														<FontAwesomeIcon icon={faPen} />
													</span>
													<p className="status_description">{profileData?.status}</p>
											</div>
											<div className="myprofile_text">
													<div className="free_text_bar">
															<span className="freetext">{t('myprofile.freetext')}</span>
															<span onClick={() => openFreeTextPopup()} className='edit_status'>
																<FontAwesomeIcon icon={faPen} />
															</span>
															{profileData?.freeTextQuestionnaire?.questionGroups.map((items,index) => (
																index > 0 ?
																items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value ?
																<p className="color ftextview">
																	<strong>{items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value}</strong><br />
																			<span dangerouslySetInnerHTML={{ __html: items?.questions[1]?.textQuestion?.answer }}></span>
																</p> : null : 
																<p className="color ftextview">
																			<span dangerouslySetInnerHTML={{ __html: items?.questions[0]?.textQuestion?.answer }}></span>
																</p>
															))}
													</div>													
											</div>
										</div>
									</div>
									<div className='myprofile_container'>
										<div className='myprofile_accordian'>
											{profileQ?.profileQuestions?.map((profile) => (
													<Accordion>
															<Accordion.Item eventKey="0">
																	<Accordion.Header className='accordian_personal_detail'>{profile?.name}</Accordion.Header>
																	<Accordion.Body className='profile_accordian_body'>
																			{profile?.questions?.map((question) => (
																					<div className='profileQ_container'>
																							<div className="profileQ_details">
																									{question?.type === 'ChoiceQuestion' ? (
																											<div className='q_details'>
																													<p className='q_heading'>{question?.name}</p>
																													<p className='q_description'>{question?.choiceQuestion?.selectedChoices?.map((ans, index) => (
																															<span key={index}>
																																	{ans.value}
																																	{index !== question.choiceQuestion.selectedChoices.length - 1 && ','}{' '}
																															</span>
																													))}</p>
																											</div>
																									) : null}
																									{question?.type === 'NumberQuestion' ? (
																											<div className='q_details'>
																													<p className='q_heading'>{question?.name}</p>
																													<p className='q_description'>{question?.numberQuestion?.answer}</p>
																											</div>
																									) : null}
																									{question?.type === "TextQuestion" ? (
																											<div className='q_details'>
																													<p className='q_heading'>{question?.name}</p>
																													<p className='q_description'>{question?.textQuestion?.answer}</p>
																											</div>
																									) : null}
																									<span onClick={() => onQuestionsOpen(question, profile?.id, profile?.description)} className='edit_status'>
																											<FontAwesomeIcon icon={faPen} />
																									</span>
																							</div>
																					</div>
																			))}
																	</Accordion.Body>
															</Accordion.Item>
													</Accordion>
											))}
										</div>
									</div>
									{isPopupOpen ?
											<EditFreeText
													profileData={profileData}
													show={isPopupOpen} 
													type={props.type}
													handleClose={closeFreeTextPopup}
													setRefreshQuestionaire={props.setRefreshQuestionaire}
											/> : null}
									{isStatusPopupOpen ?
											<EditStatus
													profileData={profileData}
													show={isStatusPopupOpen} 
													type={props.type}
													handleClose={closeStatusPopup}
													setRefreshQuestionaire={props.setRefreshQuestionaire}
											/> : null}
									{isUploadImgeOpen ?
											<UploadImage
													profileData={profileData}
													show={isUploadImgeOpen}
													type={props.type}
													handleClose={closeUploadImagePopup}
													setAvatar={setAvatar}
											/> : null}
									{isProfileQuestionOpen ?
											<ProfileQuestions
													profileData={storeProfileQ}
													show={isProfileQuestionOpen}
													type={props.type}
													handleClose={onQuestionsClose}
													setRefreshProfileData={setRefreshProfileData}
											/> : null}
                </div >
                :
                <Spinner />
            }
        </>
    )
}

export default Myprofile;