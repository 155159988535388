import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import usePreventBack from './preventBack/preventBack'

function Laststep(props) {
    const { t } = useTranslation();
    usePreventBack();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className='from_control questionair_container last_step'>
                <div className='questionair_header'>
                    <h3 className='questionair_heading'>{t('registration.laststep.header')}</h3>
                </div>
                <div className="mt-3 questions_wrapper">
                    <p><strong>{t('registration.laststep.description')}</strong></p>
                    <p>{t('registration.laststep.pleasefollow')}</p>
                    <p>{t('registration.laststep.success')}</p>
                </div>

                <div className="mt-3 step_one_next">
                    <Button
                        className='mx-5'
                        variant="primary"
                        onClick={() => props.handleNextQustionaire()}
                    >
                        {t('registration.buttons.next')}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Laststep;