import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../components/spinner';
import ImageCarousel from '../personalDetails/imageCarousel';

function SuggestionsGalleryImages(props) {
    const { show, close, suggestionGalleryImages, isGalleryLoading } = props;
    const [images, setImages] = useState([]);
    const [isCarouselOpen, setIsCarouselOpen] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        setImages(suggestionGalleryImages);
    }, [suggestionGalleryImages]);

    return (
        <Modal className='openprofile' show={show} size='lg' onHide={() => close()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('suggestions.picingellery')}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {isGalleryLoading ?
                    <div className='openprofile_spinner'>
                        <Spinner />
                    </div>
                 :
                    <div className='image-wrapper'>
                     <Form.Label className='mb-3'>{t('suggestions.photofrom')}   {images[0]?.galleryName}</Form.Label>
                        <div className='images_container'>
                            {suggestionGalleryImages?.map((image, index) => (
                                <div key={image.image.id} id={image.image.id} className='g_container'>
                                    <div id='suggestion_g_img' className='gallery_images'>
                                        <img
                                            className='image'
                                            src={image.imageUrl}
                                            alt={image.image.name}
                                            onClick={() => setIsCarouselOpen(index)}
                                        />
                                    </div>
                                    <div className='g_name_container'>
                                        <span className='galleryname'>
                                            {image.image.name.length > 40 ? `${image.image.name.slice(0, 40)}...` : image.image.name}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {!isGalleryLoading ?
                            <Form.Group className='list_btn' >
                                <div>
                                    <Button
                                        onClick={() => close()}
                                        className='mb-3 mt-1'
                                        variant="outline-danger"
                                    >
                                        <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                        {t('suggestions.back')}
                                    </Button>
                                </div>
                            </Form.Group> : null}
                    </div>}
                {isCarouselOpen != null ? <ImageCarousel
                    images={images}
                    show={isCarouselOpen}
                    setShow={setIsCarouselOpen}
            /> : null}
            </ModalBody>
        </Modal>
    )
}

export default SuggestionsGalleryImages