export const baseUrl = process.env.REACT_APP_BASE_URL;
const questionaireURL = 'questionnaire/get';
const questionaireSaveURL = 'questionnaire/save';
const saveStatusURL = 'member/profile/status';
const getAvatarURL = 'galleries/avatar';
const getAudioURL = 'audios';
const deleteRegionURL = 'questionnaire/delete/region';
const deleteProximityURL = 'questionnaire/delete/proximity';
const  profileQuestionURL= 'questionnaire/get/profile/questions';
const getAllAudiosURL = 'audios';
const updateAudioURL = 'audios';
const uploadAudioURL = 'audios';
const galleryURL = 'galleries';
const imageURL = 'galleries/image';
const addGalleryURL  = 'galleries';
const shareGalleryURL = 'galleries/share';
const uploadImageURL = 'images';
const updateImageURL = 'batch';
const updateImgURL = 'image';
const getAllGalleriesURL =  'galleries/all';
const cropImageURL = 'edit'


export async function getQuestionaireURL(token_lang, id) {
    if (token_lang.accessToken) {
      return fetch(`${baseUrl}/${questionaireURL}/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token_lang.accessToken}`,
          'Accept-Language': token_lang.language, 
        },
        'mode': 'cors'
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then(async (responseData) => {
          return responseData;
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  export async function saveQuestionaire(data, token_lang) {
    let body;
    if (data?.questionnaireId === 12 || data?.questionnaireId === 13) {
      body = {
        "questionnaireId": data.questionnaireId,
        "regionAnswerRequest" : data.answers,
      }
    } else {
      body = {    
        "questionnaireId": data.questionnaireId,
          "request": data.answers
        }
    }
    return fetch(`${baseUrl}/${questionaireSaveURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      body: JSON.stringify(body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }

export async function saveStatus(data, token_lang) {
  if (data) {
    return fetch(`${baseUrl}/${saveStatusURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      body: JSON.stringify(data),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getMyProfileData(token_lang, myProfileURL) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${myProfileURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function getAvatar(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${getAvatarURL}/${data.avatarId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAudio(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${getAudioURL}/${data.audioId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function deleteRegion(accessToken, regionRequestId, answerId) {
  return fetch(`${baseUrl}/${deleteRegionURL}/${regionRequestId}/${answerId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function deleteProximity(accessToken, proximityRequestId, answerId) {
  return fetch(`${baseUrl}/${deleteProximityURL}/${proximityRequestId}/${answerId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function getProfileQustions(token_lang, id) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${profileQuestionURL}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAllAudios(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${getAllAudiosURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function updateAudio(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${updateAudioURL}/${data.body.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function uploadAudio(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${uploadAudioURL}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: data.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getImage(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${imageURL}/${data.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    })
    .catch((err) => {
      throw err;
    });
  }
}

export async function addGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: JSON.stringify(data.newGalleryName)
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function RenameOrDeleteGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}/${data.body.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function shareGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${shareGalleryURL}/${data.body.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function uploadImage(imageData) {
  if (imageData.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}/${imageData.id}/${uploadImageURL}`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${imageData.accessToken}`,
        'Accept-Language': imageData.language,
      },
      body: imageData.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function cropImage(imageData) {  
  if (imageData.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}/${imageData.galleryId}/${imageData.mediaId}/${cropImageURL}`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${imageData.accessToken}`,
        'Accept-Language': imageData.language,
      },
      body: imageData.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function updateImage(image) {
  if (image.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}/${image.galleryId}/${updateImageURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${image.accessToken}`,
        'Accept-Language': image.language,
      },
      body: JSON.stringify(image.updatedImages),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function updateImageMetadata(image) {
  if (image.accessToken) {
    return fetch(`${baseUrl}/${addGalleryURL}/${image.id}/${updateImgURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${image.accessToken}`,
        'Accept-Language': image.language,
      },
      body: JSON.stringify(image.renameData),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getAllGalleries(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${getAllGalleriesURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

