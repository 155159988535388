import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { getSubscriptionOffers, saveSubscriptoionOffer } from './ragistrationProcessService';
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { autoFocusByID } from "../commonMethods";
import usePreventBack from './preventBack/preventBack'


function SubscriptionOffer(props) {
  const [offerDetails, setOfferDetails] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState();
  const [promoCode, setPromoCode] = useState('');
  const [isPaymentError,setIsPaymentError] =useState(null)
  const [isPaymentOfferError,setIsPaymentOfferError] =useState(null)
  const [isPaymentMethodError,setIsPaymentMethodError] =useState(null)
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const token_lang = {
    language: currentLang,
    accessToken
  }
  usePreventBack();
  useEffect(() => {
      window.scrollTo(0, 0)
    const subscriptionOffer = async () => {
      const result = await getSubscriptionOffers(token_lang)
      if (result?.status === 200) {
        setOfferDetails(result?.data)
      }
    }
    subscriptionOffer();
  }, [])

  
  const onSaveSubscriptionOffer = () => {
    const body = {
      "productId": selectedOffer,
      "actionCode": promoCode,
      "paymentMethod": paymentMethod
    }
    if (paymentMethod === undefined || paymentMethod === ""){
      setIsPaymentOfferError(null)
      setIsPaymentMethodError(t('registration.subscription.paymentMethodError'));
      window.scrollTo(0, 400)
    }
    else if(selectedOffer === null){
      setIsPaymentMethodError(null)
      setIsPaymentOfferError(t('registration.subscription.paymentOfferError'));
      window.scrollTo(0, 1000)
    }
    else{
      saveSubscriptoionOffer(body, accessToken).then((res) => {
        if (res?.status === 200) {
          props?.setQuestionaireId(res?.data?.nextStep);
          props?.setPaymentMethods(paymentMethod)
        }else{
          setIsPaymentError(res.data.message);
          window.scrollTo(0,0)
        }
      })
        .catch((err) => {
        })
    }
  }

  const handleTapSelect = (e) => {
    if (!e.target.value) {
      if (e.type === "keydown" && e.keyCode === 9 && e.target.id ==='formGridpayment') {
        autoFocusByID(e.target.id);
        setIsPaymentMethodError(true);
        setIsPaymentError(t('registration.subscription.prepaymentmethod'))
        }
      }
    }
    const handleTapOffer = (e) => {      
      if (e.type === "keydown" && e.keyCode === 9 && e.target.type !== "offer") { 
            if (!e.target.checked ) {  
               autoFocusByID(e.target.id);  
               setIsPaymentOfferError(t('registration.subscription.paymentOfferError'));
              }}
       };


  const autofillAnswers = () => {
    setPaymentMethod('DIRECT_DEBIT');
    if (offerDetails.length > 0) {
      setSelectedOffer(offerDetails[0].id);
    };
  }

const selectPaymentMethod = (paymentMethodValue) =>{
  setPaymentMethod(paymentMethodValue)
  if(paymentMethodValue !== undefined || paymentMethodValue !== ""){
    setIsPaymentError(false)
    setIsPaymentMethodError(null)
  }
}
const selectPaymentOffer = (offerId) =>{
  setSelectedOffer(offerId)
  if(offerId !== null){
    setIsPaymentOfferError(null);
  }
}
  return (
    <div>
    <div className="looking_for">
      <div className="questionnaireHeader">
        <h3 className="lineH3 text-center"><strong>{t('member.preregistration.finalizeHeading')}</strong> &ndash; {t('member.preregistration.step1of2')}</h3>
        <h4>{t('registration.subscription.choosePaymentMethod')}</h4>
        <p>{t('registration.subscription.choosePaymentDescription')}</p>
      </div>
    </div>
     {process.env.REACT_APP_ENV != 'PROD' ?
        <div className="mt-3 mb-3 auto_fill_wrapper">
          <Button
            className='mx-5'
            variant="success"
            onClick={autofillAnswers}
          >
            {t('registration.buttons.autofill')}
          </Button>
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => onSaveSubscriptionOffer()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div> : null}
        <div className='from_control questionair_container subscription_offer'>
          <div className='questionair_header'>
            <h3 className='questionair_heading'>{t('registration.subscription.heading')}</h3>
          </div>
          <div className="mt-3 partnershippost_btn questions_wrapper">
            <p className='heading_description'>{t('registration.subscription.description')}</p>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>{t('registration.subscription.promo')}</Form.Label>
              <Form.Control
                aria-label={t('member.preregistration.promoCodeDesc')}
                onChange={(e) => setPromoCode(e.target.value)}
                type="text"
                value={promoCode}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridpayment" className='mt-3'>
              <Form.Label>{t('registration.subscription.paymentmethod')}*</Form.Label>
              <Form.Select onChange={(e) => selectPaymentMethod(e.target.value)} 
                onKeyDown={(e) => handleTapSelect(e)} 
                className={isPaymentError || isPaymentMethodError ? "is-invalid" : ""}
                aria-label={isPaymentMethodError ? t('registration.subscription.prepaymentmethod') : ""}
                value={paymentMethod}
                >
                <option value=''>{t('registration.buttons.pleaseselect')}</option>
                <option value='DIRECT_DEBIT'>{t('registration.subscription.directdebit')}</option>
                <option value='PREPAYMENT'>{t('registration.subscription.prepayment')}</option>
              </Form.Select>          
            </Form.Group>   
              {isPaymentError ? (
                <Alert className='alert_questionaire' variant='danger'>{isPaymentError}</Alert>
              ) : isPaymentMethodError ? (
                <Alert className='alert_questionaire' variant='danger'>{isPaymentMethodError}</Alert>
              ) : null}
            <fieldset>  
              <legend className="subscription_legend">{t('registration.subscription.subscriptionOffer')}*</legend>
              <Form.Group className="mb-3 mt-3 offer_btn">
                {offerDetails?.map((offer, index) => (
                  <Form.Check
                    key={index}
                    id={`offer-${index}`}
                    className='msg_pre_checkbox mt-3'
                    type="radio"
                    name="offer"
                    label={<p dangerouslySetInnerHTML={{ __html: offer.value }} />} 
                    aria-label={isPaymentOfferError}
                    value={offer.id}
                    checked={selectedOffer === offer.id}
                    onChange={(e) => selectPaymentOffer(offer.id)}
                    onKeyDown={(e) => handleTapOffer(e)}
                  />
                ))}
              </Form.Group>
            </fieldset>
          </div>
          {isPaymentOfferError !== null ? (
              <Alert className='alert_questionaire' variant='danger'>{isPaymentOfferError}</Alert>
            ):null}  
          <div className="mt-3 step_one_next">
            <Button
              onClick={() => onSaveSubscriptionOffer()}
              className='mx-5'
              variant="primary"
            >
              {t('registration.buttons.booknow')}
            </Button>
          </div>
        </div>
    </div>
  )
}

export default SubscriptionOffer