import { useEffect, useState, useContext, useCallback } from 'react';
import { LoginContext } from '../../../context/loginContext';
import useWebSocket from 'react-use-websocket';

const useWebSocketComponent = () => {
    const [userDetails, setUserDetails] = useState();
    const [chatRoom, setChatRoom] = useState([]);
    const [usersChat, setUsersChat] = useState([]);
    const [messageQueue, setMessageQueue] = useState([]);
    const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_BASE_WS_URL);
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    const options = {
        onOpen: () => {
            // Send any queued messages
            console.error('WebSocket opened')
            messageQueue.forEach((message) => rawSendMessage(message));
            setMessageQueue([]);
        },
        onClose: (event) => console.error('WebSocket closed:', event),
        onError: (event) => console.error('WebSocket error:', event),
        onMessage: (message) => {
            if (message?.data != 'PONG' 
                && message?.data != "SUCCESS") {
                let parsedData;
                
                try {
                    parsedData = JSON.parse(message?.data); // Parse once
                } catch (error) {
                    console.error("Failed to parse JSON:", error);
                    return;
                }
        
                getUsers(parsedData);
        
                if (parsedData?.subType === 'CHAT_ROOMS') {
                    setChatRoom(parsedData);
                }
        
                if (parsedData?.subType === 'CHAT_HISTORY' ||
                    parsedData?.type === "TEXT_MESSAGE" ||
                    parsedData?.type === "FILE_MESSAGE" ||
                    parsedData?.fileType === 'IMAGE' ||
                    parsedData?.subType === 'DELETE' ||
                    parsedData?.fileType === 'FILE') {
                    setUsersChat(parsedData);
                }
            }
        },
                     
        retryOnError: true,
    };
    const { sendMessage: rawSendMessage, lastMessage, readyState } = useWebSocket(socketUrl, options);

    const reconnectWebSocket = () => {
        setSocketUrl(null); // Temporarily unset the URL to force a reconnect
        setTimeout(() => setSocketUrl(process.env.REACT_APP_BASE_WS_URL), 100); // Re-set the URL after a short delay
        if (readyState !== WebSocket.OPEN) {
            const authMessage = {
                type: "AUTH",
                token: accessToken,
            };
            setMessageQueue((prevQueue) => [JSON.stringify(authMessage), ...prevQueue]); // Queue the AUTH message            
        }
    };

    const sendMessage = useCallback((message) => {
        if (readyState === WebSocket.OPEN) {
            rawSendMessage(message);
        } else {
            // Queue the message if the WebSocket is not open
            setMessageQueue((prevQueue) => [...prevQueue, message]);
            if (readyState === WebSocket.CLOSED) {                
                reconnectWebSocket(); // Reconnect the WebSocket if it's closed
            }
        }
    }, [readyState, rawSendMessage]);


    const getUsers = (details) => {
        setUserDetails(details)
    };

    useEffect(() => {
        const heartbeat = setInterval(() => {
            if (readyState === 1) {
                const heartbeatMsg = {
                    type: "PING",
                }
                sendMessage(JSON.stringify(heartbeatMsg));
            }
        }, 90000);
        return () => clearInterval(heartbeat);
    }, [readyState]);

    return {
        sendMessage,
        lastMessage,
        readyState,
        userDetails,
        chatRoom,
        usersChat
    };
};

export default useWebSocketComponent;