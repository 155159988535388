import './pendingPrePayment.css';
import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import GlkHeader from '../../components/Header/GlkHeader';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhoneVolume,
    faSquarePhone,
    faEnvelope,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { getPaymentDetails, changePaymentMethod } from '../registrationProcess/ragistrationProcessService';
import Payment from '../registrationProcess/payment';

function PendingDirectDebitPayment(props) {
    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    const [ddPaymentData, setDDPaymentData] = useState(null);
    const [showPaymentPage, setShowPaymentPage] = useState(false)
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        getPaymentDetails(token_lang).then((result) => {
            if (result?.status === 200) {
                setDDPaymentData(result?.data)
            }
        })
    }, [])


    const onNext = () => {
        setShowPaymentPage(true)    
    }

    return (
        <>
            <GlkHeader showMenuBar={false} />
            {showPaymentPage === false ?
                <div className="main_wrapper pending_payment_wrapper">

                    <div className="left_area">

                        <div className="home_banner info_banner">
                            <div className="home_banner_content info_banner_content">
                                <h1><FontAwesomeIcon icon={faExclamationTriangle} /> {t('registration.pendingPayment.mainHeading')}</h1>
                                <h2>{t('registration.pendingPayment.header')}</h2>
                                <p><Trans i18nKey="registration.pendingPayment.description" /></p>
                            </div>
                        </div>

                        <div className="home_banner contact_banner">
                            <div className="home_banner_content contact_banner_content">
                                <h1>{t('registration.pendingPayment.contactTeamHeader')}</h1>
                                <h2>{t('registration.pendingPayment.contactTeam')}</h2>

                                <div className="contact_details_container">
                                    <div className="contact_number_free">
                                        <div className='contact_numberAndicon'>
                                            <div className='for_icon_circle'>
                                                <FontAwesomeIcon icon={faPhoneVolume} />
                                            </div>
                                            <div>
                                                <a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
                                                <span className="for_font_size">{t('member.footer.contactTime')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact_number_paid">
                                        <div className='contact_numberAndicon'>
                                            <div className='for_icon_circle'>
                                                <FontAwesomeIcon icon={faSquarePhone} />
                                            </div>
                                            <div>
                                                <a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
                                                <span className="for_font_size">(0.14 €/min)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact_email">
                                        <div className='contact_numberAndicon'>
                                            <div className='for_icon_circle'>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </div>
                                            <a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='from_control questionair_container'>

                        <div className='questionair_header' ref={scrollRef}>
                            <h3 className='questionair_heading'>{t('registration.pendingPayment.paymentDetails')}</h3>
                            <div className="payment_methods">
                                <div className='prepayment_result'>
                                    <p>{t('registration.pendingPayment.amount')}{ddPaymentData?.amount}</p>
                                    <p>{t('registration.pendingPayment.currency')}{ddPaymentData?.currency}</p>
                                    <p>{t('registration.pendingPayment.offerType')}{ddPaymentData?.offerType}</p>
                                    <p>{t('registration.pendingPayment.duration')}{ddPaymentData?.duration}</p>
                                    <p>{t('registration.pendingPayment.productDescription')}{ddPaymentData?.productDescription}</p>
                                    <p>{t('registration.pendingPayment.referenceId')}{ddPaymentData?.externalReferenceId}</p>
                                </div>
                            </div>
                        </div>
                        <div className='questionair_header'>
                            <h3 className='questionair_heading'>{t('registration.pendingPayment.makePayment')}</h3>
                        </div>
                        <div className="mt-3 step_one_next">
                            <Button
                                className='main_button'
                                variant="primary"
                                onClick={() => onNext()}
                            >
                                {t('registration.buttons.next')}
                            </Button>
                        </div>
                    </div>

                </div>
                : <Payment isUserRegistered={true} />}
        </>
    )
}
export default PendingDirectDebitPayment;

