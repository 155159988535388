export const baseUrl = process.env.REACT_APP_BASE_URL;
const personalDetailsURL = 'membership/personal/details'

export async function getPersonalDetails(data) {
    if (data.accessToken) {
        return fetch(`${baseUrl}/${personalDetailsURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.accessToken}`,
                'Accept-Language': data.language, 
            },
            'mode': 'cors'
        })
            .then((response) =>
                response.json().then((data) => ({
                    data: data,
                    status: response.status,
                }))
            )
            .then(async (responseData) => {
                return responseData;
            })
            .catch((err) => {
                throw err;
            });
    }
}

