import './GlkHeader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faUserPlus, faEnvelope, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/glk_member_area.svg';
import icon_logo from "../../assets/images/glk_member_area_mobile.svg";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n/i18n';
import {  useNavigate , useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {getHomeDetails} from '../../pages/home/homeServices.js';
import { useContext, useEffect, useState , useLayoutEffect } from 'react';
import { LoginContext } from '../../context/loginContext';
import Menubar from '../../pages/home/menuBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import userIcon from '../../assets/images/user-icon01.png';
import LogoutPopup from './logoutPopup.js';

function GlkHeader(props) {
  const [homeDetails, setHomeDetails] = useState(null);
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const languageCode = i18n.language;
  
  let navigate = useNavigate();
  let {
    loginData: {
      memberDetails,
			accessToken
    }, 
    dispatch
  } = useContext(LoginContext);
  
  const token_lang = {
    language: languageCode,
    accessToken
  }

  useEffect(() => {
    getHomeDetails(token_lang).then((res) => {
      if (res?.status === 200) {
        setHomeDetails(res.data);  
      }
    })    
  }, [languageCode, accessToken]);
	
	const toggleMobileMenu = () => {
		setMobileMenuVisible(!mobileMenuVisible);
  };

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  }

  const handleLogout = (e) => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGIN', data: null });
    dispatch({ type: 'MEMBER_DETAILS', data: null });
    navigate('/')
    }

  const location = useLocation();
	
	useLayoutEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }, [location.pathname]);

  return (
    <>
    <header className="app_header">      
      <div>
        <img className='full_logo' src={logo} alt="Gleichklang logo" />
        <img className='small_logo' src={icon_logo} alt="Gleichklang Icon" />
      </div>
			
	  {memberDetails?.memberStatus === 'REGISTERED' ? 
	  (
      <div className='member_header'>	
				<div className='home_btns'>		
					<Button className='suggest_btn' variant="success">
						<FontAwesomeIcon icon={faUserPlus} />
						{t('member.menubar.suggestions')}
						<Badge bg="warning">{homeDetails?.suggestions}</Badge>
						<span className="visually-hidden">unread Suggestions</span>
					</Button>
					<Button className='msg_btn' variant="primary">
						<FontAwesomeIcon icon={faEnvelope} />
						{t('member.menubar.messages')}
						<Badge bg="warning">{homeDetails?.messages}</Badge>
						<span className="visually-hidden">unread messages</span>
					</Button>
				</div>
				<div className='header_member_info'>
					<span className='greeting'>
						{memberDetails ?
						<>{t('member.header.hello')} {memberDetails?.alias}</>
						:
						null
					}
					</span>
					<div className='header_avatar'>
            <img className='avatar' src={homeDetails && homeDetails.avatar !==null ?  "data:image/jpeg;base64," + homeDetails.avatar : userIcon} alt={`Avatar ${memberDetails?.alias}`} />
					</div>
				</div>
      </div>
	  ) : ( '' )}	
		
      <div className='header_bts'>        
    {location.pathname == '/'
			? <div className='select_lag'>
        <label htmlFor="language" className="visually_hidden">Select Language</label>
          <select
            value={languageCode}
            id="language"
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            <option value='en'>English</option>
            <option value='de'>Deutsch</option>
          </select>
        </div> : location.pathname == '/pre-registration' ? 
        <button className='header_logout back_login' onClick={() => {setShow(true)}}>
						<FontAwesomeIcon icon={faChevronLeft} /> Back to login
				</button>
        :
				<>
          {location.pathname == '/registration' ?
            <span className='greeting register_user'>
              {memberDetails ?
              <>{t('member.header.hello')} {memberDetails?.alias}</>
              :
              null
            }
            </span> : null
          }
					<button className='toggle_menu' aria-label="Menu" onClick={() => toggleMobileMenu()}><FontAwesomeIcon icon={faBars} /></button>
          <OverlayTrigger
          key='bottom'
          placement='bottom' 
          overlay={
            <Tooltip id={`tooltip-logout`}>
              logout
            </Tooltip>
          }
        >
					<button className='header_logout' aria-label="Logout" onClick={() => {setShow(true)}}>
						<FontAwesomeIcon icon={faSignOutAlt} />
					</button>
        </OverlayTrigger>
				</>
        
    }
      </div>
    </header>
    {memberDetails?.memberStatus === 'REGISTERED' && (props?.showMenuBar == null || props?.showMenuBar)
    ? <Menubar mobileMenuVisible={mobileMenuVisible} handleLogout={() => {setShow(true)}} /> : '' }
      <LogoutPopup
        show={show}
        setShow={setShow}
        handleLogout={handleLogout}
        alias={memberDetails?.alias}
      />
    </>
  )
}

export default GlkHeader
