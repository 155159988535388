import React, { useState } from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import './registration.css';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import preregistrationImg from "../../assets/images/preregistration.png";
import promise from "../../assets/images/promise.png";
import Aboutyou from './aboutyou';
import { validateAlias, validateEmail, getPasswordStrength } from './preRegistrationService'
import AlertModal from '../commonComponents/alert';
import { autoFocusFirstElement } from '../commonMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons';
import GlkFooter from '../../components/Footer/GlkFooter';
import {
  faPhoneVolume,
  faSquarePhone,
  faEnvelope,
  faEarthEurope,
} from '@fortawesome/free-solid-svg-icons';

function Membership() {
  const [isgotoAboutyou, setIsgotoAboutyou] = useState(true)
  const [data, setData] = useState({})
  const [isAliasValid, setIsAliasValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [passwordCorrect, setPasswordCorrect] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState()
  const [aliasErrorMessage, setAliasErrorMessage] = useState()
  const [isConfirmEmail, setIsConfirmEmail] = useState(false)
  const [confirmEmailMsg, setConfirmEmailMsg] = useState()
  const [isPassword, setIsPassword] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState();
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState();
  const [passwordData, setPasswordData] = useState(null)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userPassword,setUserPassword]=useState('')
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const EMAIL_REG_EX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  }

  const validateData = async (value) => {
    if (value.email !== value.confirmEmail) {
      setIsConfirmEmail(true)
      setConfirmEmailMsg(t('member.preregistration.labels.emailNotMatch'))
      return;
    } else {
      setIsConfirmEmail(false)
      setIsEmailValid(false)
    }

    if (value.password !== value.confirmPassword) {
      setIsConfirmPassword(true);
      setConfirmPassErrorMsg(t(`member.preregistration.errors.confirmPwdBody`));
      return;
    } else {
      setIsPassword(false)
      setIsConfirmPassword(false)
    }

    if (value.alias == null || value.alias == '') {
      setIsAliasValid(true)
      setAliasErrorMessage(t(`member.preregistration.errors.aliasMinCharBody`))
      return;
    }else {
      setIsAliasValid(false)
      setAliasErrorMessage('')
    }

    if (validateField(`password`, value.password) === true) {
      setIsPassword(true);
      setPasswordErrorMsg(t(`member.preregistration.errors.passBody`));
      return;
    }

    const finalData = {
      body: value,
      language: currentLang
    }
    const emailResult = await validateEmail(finalData)
    const aliasResult = await validateAlias(finalData)

    if (emailResult?.status !== 200) {
      setIsEmailValid(false)
    }
    else if (emailResult.data.emailValid === false) {
      setIsEmailValid(!emailResult?.data.emailValid)
      setEmailErrorMessage(emailResult?.data?.errorMessages?.[0])
    }
    if (aliasResult?.status !== 200) {
      setIsAliasValid(false)
    }
    else if (aliasResult.data.aliasValid === false) {
      setIsAliasValid(!aliasResult?.data.aliasValid)
      setAliasErrorMessage(aliasResult?.data?.errorMessages?.[0])
    }
    return {emailValid: emailResult?.data.emailValid, aliasValid: aliasResult?.data.aliasValid, status: (emailResult?.data.emailValid && aliasResult?.data.aliasValid)} 
  }

  const validatePassword = async (value) => {
    const strength = await getPasswordStrength(value);
    let pLabel = 'none'
    let showVarient = "danger";
    switch (strength) {
      case 0:
        pLabel = t('member.preregistration.strengthVeryWeak')
        showVarient = "danger";
        break;
      case 1:
      case 2:
        pLabel = t('member.preregistration.strengthWeak')
        showVarient = "warning";
        break;
      case 3:
      case 4:
        pLabel = t('member.preregistration.strengthStrong')
        showVarient = "info";
        break;
      case 5:
        pLabel = t('member.preregistration.strengthVeryStrong')
        showVarient = "success";
        break;
      default:
        pLabel = 'none'
    }
    const pwdData = {
      strength: strength,
      label: pLabel,
      varient: showVarient
    }
    setPasswordData(pwdData)
    setPasswordCorrect(strength >= 3);
    return strength >= 3
  }

  const validateField = (fieldName, value) => {
    const emailValue = value ? value : "";
    switch (fieldName) {
      case `email`:
        return EMAIL_REG_EX.test(emailValue);
      case `alias`:
        return EMAIL_REG_EX.test(emailValue);
        case `password`:
        return EMAIL_REG_EX.test(emailValue);
      default:
        return false;
    }
  }

  const validateWithTAB = async (evt) => {
    let isError = null;
    if(evt.type === 'keydown' && evt.keyCode === 9 && evt.shiftKey !== true) {
      if(evt.target.id === 'email') isError = handleEmail(evt);
      if(evt.target.id === 'confirmEmail') isError = await handleConfirmEmail(evt);
      if(evt.target.id === 'alias') isError = await handleAlias(evt);
      if(evt.target.id === 'newpassword') isError = await handlePassword(evt);
      if(evt.target.id === 'confirmpassword') isError = handleConfirmPassword(evt);
      if(evt.target.value === '' || isError) evt.preventDefault();
    }
  }

  const handleEmail = (e) => {
    if (!e.target.value) {
      setIsEmailValid(true)
      setEmailErrorMessage(t('member.preregistration.labels.email'))
      return true;
    } else {
      data[`email`] = e.target.value;
      setData({ ...data });
      if (validateField(`email`, e.target.value)) {
        setIsEmailValid(false)
        setEmailErrorMessage('')
        return false;
      } else {
        setIsEmailValid(true)
        setEmailErrorMessage(t('member.preregistration.labels.invalidEmail'))
        return true;
      }
    }
  }

  const handleConfirmEmail = async (e) => {
    if (!e.target.value) {
      setIsConfirmEmail(true)
      setConfirmEmailMsg(t('member.preregistration.labels.emailRepeat'))
      return true;
    } else {
      data[`confirmEmail`] = e.target.value;
      setData({ ...data })
      if( data[`email`] !==  data[`confirmEmail`]) {
        setIsConfirmEmail(true)
        setConfirmEmailMsg(t('member.preregistration.labels.emailNotMatch'));
        return true;
      } else {
        const finalData = {
          body: data,
          language: currentLang
        }
        const emailResult = await validateEmail(finalData)
        if (emailResult.status !== 200 || emailResult.data?.emailValid !== true) {
          setIsEmailValid(!emailResult?.data.emailValid)
          setEmailErrorMessage(emailResult?.data?.errorMessages?.[0])
          return true;
        }
        setIsConfirmEmail(false)
        setConfirmEmailMsg('')
        return false;
      }
    }
  }
  
  const handleAlias = async(e) => {
    const aliasValue = e.target.value.trim(); 
    data[`alias`] = aliasValue ? aliasValue : '';   
  
    if (aliasValue !== '' && validateField(`alias`, aliasValue) === true) {
      setIsAliasValid(true);
      setAliasErrorMessage(t(`member.preregistration.errors.aliasBody`));
      return true;
    }
    if (aliasValue.length <= 3) {
      setIsAliasValid(true);
      setAliasErrorMessage(t(`member.preregistration.errors.aliasMinCharBody`));
      return true;
    }
    if (aliasValue.length >= 3 && validateField(`alias`, aliasValue) === false) {
      setIsAliasValid(false);
      setAliasErrorMessage('');
      data[`alias`] = aliasValue;
      setData({ ...data })
      const finalData = {
        body: data,
        language: currentLang
      }
      const rsValid = await validateAlias(finalData);
      if (rsValid.data.aliasValid === false) {
        setIsAliasValid(!rsValid?.data.aliasValid)
        setAliasErrorMessage(rsValid?.data?.errorMessages?.[0])
        return true
      }
    } else {
      data[`alias`] = null;
      setData({ ...data })
    }
    return false;
  }
  
  const handlePassword = async (e) => {
    setUserPassword(e.target.value)
    if (e.target.value == '') {
      setIsPassword(true);
      setPasswordErrorMsg(t('member.preregistration.errors.pwdBody'));
      return true;
    }
    if (data[`confirmPassword`] !== undefined && (e.target.value !== data[`confirmPassword`])) {
      setIsConfirmPassword(true);
      setConfirmPassErrorMsg(t(`member.preregistration.errors.confirmPwdBody`));
    }else{
        setIsConfirmPassword(false);
        setConfirmPassErrorMsg('');
    }
    const isPasswordValid = await validatePassword(e.target.value)
    if (isPasswordValid) {
      data[`password`] = e.target.value;
      setData({ ...data })
      if (validateField(`password`, e.target.value) === true) {
        setIsPassword(true);
        setPasswordErrorMsg(t(`member.preregistration.errors.passBody`));
        return true;
      } else {
        setIsPassword(false)
        setPasswordErrorMsg('');
        return false;
      }
    } else {
      setIsPassword(true);
      setPasswordErrorMsg(t('member.preregistration.errors.pwdBody'));
      return true;
    }
  }

  const handleConfirmPassword = (e) => {
    if (userPassword && e.target.value == '') {
      setIsConfirmPassword(true);
      setConfirmPassErrorMsg(t('member.preregistration.labels.confirmPassword'));
      return true;
    }
    else {
      setIsConfirmPassword(false)
      data[`confirmPassword`] = e.target.value;
      setData({ ...data })
      if (userPassword !== data[`confirmPassword`]) {
        setIsConfirmPassword(true);
        setConfirmPassErrorMsg(t(`member.preregistration.errors.confirmPwdBody`));
        return true;
      } else {
        setIsConfirmPassword(false);
        setConfirmPassErrorMsg('');
        return false;
      }
    }
  }

  const next = async () => {
    if (!data.email) handleEmail({ target: { value: (data[`email`] ?  data[`email`] : ''), id: 'email' } });
    if (!data.confirmEmail) handleConfirmEmail({ target: { value: (data[`confirmEmail`] ? data[`confirmEmail`] : ''), id: 'confirmEmail' } });
    if (!data.alias) handleAlias({ target: { value: (data[`alias`] ? data[`alias`] : ''), id: 'alias' } });
    if (!data.password) handlePassword({ target: { value: (data[`password`] ? data[`password`] : ''), id: 'newpassword' } });
    if (!data.confirmPassword) handleConfirmPassword({ target: { value: (data[`confirmPassword`] ? data[`confirmPassword`] : ''), id: 'confirmpassword' } });
    if (data.confirmPassword == data.alias && data.password == data.alias ) {     
      setIsPassword(true);
      setPasswordErrorMsg(t('member.preregistration.errors.aliaspassmatch'));
      return;
    }

    if(data.email && data.confirmEmail && (data.confirmEmail === data.email) && data.alias && data.password && data.confirmPassword && (data.confirmPassword === data.password)) {
      const isValidData = await validateData(data);
      if (isValidData && isValidData.status) {
        setIsgotoAboutyou(false);
      } else {
        setIsgotoAboutyou(true);
      }
      autoFocusFirstElement();
    } else {
      autoFocusFirstElement();
    }
  };

  return (
    <div>
      <GlkHeader />
      <main className='main_wrapper preregistration_wrapper'>
        <div className="banner_wrapper">
          <div className="banner_container">
            <div className='banner_content'>
              <div className="banner_description">
                <h1 className='registration_label'>{t('member.registration.registration')}</h1>
                <p><strong>{t('member.registration.h2label')}</strong></p>
                <p>{t('member.registration.plabel')}</p>
                <p>{t('member.registration.plabel2')}</p>
              </div>
            </div>
            <div className="banner_image">
              <img src={preregistrationImg} alt={t('member.registration.registration')} />
            </div>
          </div>
        </div>
        <div className="questionaire_in_menubar">
          <div className="container-fluid registration_steps">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="step_box one">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 1">1</span>
                    </div>
                    <h2>{t('member.preregistration.registerHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.registerSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box two">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 2">2</span>
                    </div>
                    <h2>{t('member.preregistration.profileHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.profileSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box three">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 3">3</span>
                    </div>
                    <h2>{t('member.preregistration.finalizeHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.finalizeSubheading')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isgotoAboutyou ?
            <div className="form_row">
              <div className="pre-register-new">
                <div className="col-12">
                  <img src={promise} alt={t('member.community.imageFor')+ " "+t('member.preregistration.promiseHeading')} />
                  <h3>{t('member.preregistration.promiseHeading')}</h3>
                </div>
                <div className="promiseInfo">
                  <div className="col-12">
                  <p><strong>{t('member.preregistration.promiseSubheading')}</strong></p>
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.registerSubText')}</span></p>
                  </div>
                  <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.profileSubText')}</span></p>
                  </div>
                  <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.finalizeSubText')}</span></p>
                  </div>
                </div>
              </div>
              <div className='membership_account'>
                <div className="reg_stepone">
                  <h3 className="lineH3 text-center"><strong>{t('member.registration.registration')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
                  <h4>{t('member.preregistration.accountHeader')}</h4>
                </div>
                <Form autoComplete="off" className='step_one_form'>
                  <AlertModal show={isEmailValid} setShow={setIsEmailValid}
                    heading={t('member.preregistration.errors.email')}
                    body={emailErrorMessage}
                  >
                  </AlertModal>
                  <Form.Label className="mt-3" htmlFor="email">{t('member.preregistration.email')}*</Form.Label>
                  <Form.Control autoComplete="new-email"
                    type="text"
                    id="email"
                    className={isEmailValid && "is-invalid"}
                    autoFocus={true}
                    aria-label={emailErrorMessage ? emailErrorMessage : t('member.preregistration.labels.email')}
                    onBlur={(e) => handleEmail(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                  <AlertModal show={isConfirmEmail} setShow={setIsConfirmEmail}
                    heading={'Error'}
                    body={confirmEmailMsg}
                  ></AlertModal>
                  <Form.Label className="mt-3" htmlFor="confirmEmail">{t('member.preregistration.repeatEmail')}*</Form.Label>
                  <Form.Control autoComplete="new-email"
                    type="text"
                    id="confirmEmail"
                    className={isConfirmEmail && "is-invalid"}
                    aria-label={confirmEmailMsg ? confirmEmailMsg : t('member.preregistration.labels.emailRepeat')}
                    onBlur={(e) => handleConfirmEmail(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                  <AlertModal show={isAliasValid} setShow={setIsAliasValid}
                    heading={t('member.preregistration.errors.alias')}
                    body={aliasErrorMessage}
                  ></AlertModal>
                  <Form.Group className="mt-3" as={Col} >
                    <Form.Label htmlFor="alias" aria-hidden="true">{t('member.preregistration.alias')}*</Form.Label>
                    <Form.Control
                      type="text"
                      id="alias"
                      className={isAliasValid && "is-invalid"}
                      aria-label={aliasErrorMessage ? aliasErrorMessage : t('member.preregistration.labels.alais')}
                      autoComplete="new-alias"
                      onBlur={(e) => handleAlias(e)}
                      onKeyDown={(e) => {
                        validateWithTAB(e);
                      }}
                    />
                  </Form.Group>
                  <AlertModal show={isPassword} setShow={setIsPassword}
                    heading={'Error'} body={passwordErrorMsg}></AlertModal>
                    <Form.Group className="position-relative" as={Col} >
                  <Form.Label className="mt-3" htmlFor="newpassword">{t('member.preregistration.pwd')}*</Form.Label>
                  <Form.Control autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    id="newpassword"
                    className={isPassword && "is-invalid"}
                    aria-label={passwordErrorMsg ? passwordErrorMsg : t('member.preregistration.labels.password')}
                    onBlur={(e) => handlePassword(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                    <span onClick={() => setShowPassword(!showPassword)} className={`showPasswordIcon_preReg ${isPassword ? "me-4" : ""}`}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                    </Form.Group>
                  <div className='progess_bar'>
                    <ProgressBar
                      className='reg_progress_bar'
                      now={passwordData ? (passwordData.strength / 5) * 100 : 0}
                      variant={passwordData ? passwordData.varient : "danger"}
                      label={passwordData?.label} 
                      tabIndex="-1" 
                      striped
                      aria-label={t('member.preregistration.labels.passwordIs') + " " + passwordData?.label}
                    >
                    </ProgressBar>
                    <p>{t('member.preregistration.pstrength')}</p>
                  </div>
                  <AlertModal show={isConfirmPassword} setShow={setIsConfirmPassword}
                    heading={'Error'} body={confirmPassErrorMsg}></AlertModal>
                    <Form.Group className="position-relative" as={Col}>
                  <Form.Label className="mt-3" htmlFor="confirmpassword">{t('member.preregistration.confirmPwd')}*</Form.Label>
                  <Form.Control autoComplete="new-password"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmpassword"
                    className={isConfirmPassword && "is-invalid"}
                    aria-label={confirmPassErrorMsg ? confirmPassErrorMsg : t('member.preregistration.labels.confirmPassword')}
                    onBlur={(e) => handleConfirmPassword(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                    <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className={`showPasswordIcon_preReg ${isConfirmPassword ? "me-4" : ""}`}>
                      <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                    </span>
                    </Form.Group>
                  <div className="mt-3 step_one_next">
                    <Button className='mx-5' variant="primary"
                      onClick={() => next()}>
                      {t('member.preregistration.nextButton')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            :
            <Aboutyou data={data} />}
          </div>
      </main>
      <footer className='app_footer'>
      <div className='footer_details'>
        <a className='text_white imprint_label' href="https://www.gleichklang.de/impressum/">{t("member.footer.imprint")}</a>
        <p className='text_white'>  Gleichklang limited   Oesterleystr. 1   D-30171 Hannover</p>
        <div className="text_white glk_contact_details_container">
          <div className="contact_number_free">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faPhoneVolume} />
              </div>
              <a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
            </div>
            <span className="for_font_size">{t('member.footer.contactTime')}</span>
          </div>
          <div className="contact_number_paid">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faSquarePhone} />
              </div>
              <a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
            </div>
            <span className="for_font_size">(0.14 €/min)</span>
          </div>
          <div className="contact_email">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
            </div>
          </div>
          <div className="contact_website">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faEarthEurope} />
              </div>
              <a className="text_white" href="https://www.gleichklang.de">www.gleichklang.de</a>
            </div>
          </div>
        </div>
      </div>
			<div className="footer_select_lng">
				<div className='select_lag'>
          <select
            value={currentLang} 
            aria-label={t('member.footer.selectLang')} 
            id="language1"
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            <option value='en'>English</option>
            <option value='de'>Deutsch</option>
          </select>
        </div>
			</div>
    </footer>
    </div>
  )
}

export default Membership;