import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import {downloadPdf} from '../home/homeServices.js';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf
  } from '@fortawesome/free-solid-svg-icons';
  import usePreventBack from './preventBack/preventBack'

function PartnershipPost(props) {

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  usePreventBack();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const openPdf = async (type) => {
    const data = {
      type: type,
      language: currentLang,
      accessToken: accessToken
    }
    const response = await downloadPdf(data)
    const blob = await response.blob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.URL.revokeObjectURL(fileURL)
  }

  return (
    <div>
      <div className="looking_for">
        <div className="questionnaireHeader">
          <h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.analysesHeader')}</strong></h3>
          <h4>{t('registration.partnershipPost.header')}</h4>
          <p>{t('registration.partnershipPost.description')}</p>
        </div>
      </div>
      <div className='from_control questionair_container'>
        <div className='questionair_header'>
          <h3 className='questionair_heading'>{t('registration.partnershipPost.analysis')}</h3>
        </div>

        <div className="mt-3 partnershippost_btn questions_wrapper">
          <p className='download_desc'>{t('registration.partnershipPost.download')}</p>
          <Button
            onClick={() => {openPdf('partnership')}}
            variant="primary"><FontAwesomeIcon icon={faFilePdf} /> {t('registration.partnershipPost.downloadpdf')}</Button>
        </div>
        <div className="fwd_row">
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => props.handleNextQustionaire()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PartnershipPost