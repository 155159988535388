import {createContext} from "react"
import { getMemberDetails } from "../pages/login/loginService";

export const LoginContext = createContext(intializeContext());

function intializeContext() {
    let accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      getMemberDetails(accessToken).then((response) => {
        console.log('response in context ', response);
    })
    }
} 