import React from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import Accordion from 'react-bootstrap/Accordion';
import './personalData.css';
import './subscription.css';
import membershipIcon from '../../assets/images/membership.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function Subscription() {
  const { t } = useTranslation();

  const data = {
    image: membershipIcon,
    menuName: t('mymembership.mymembership'),
    header: t('mymembership.subscription.subscription'),
    description: t('mymembership.subscription.description'),
    color: '#063978'
  };

  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data={data} />
      <div className='questionaire_in_menubar'>
        <div className="membership_container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.subscription')}</Accordion.Header>
              <Accordion.Body>
                <Form>
                  <div className="form_head_part">
                    <h4>{t('mymembership.subscription.serviceoffer')}</h4>
                    <span></span>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.state')}</p>
                    <p>{t('mymembership.subscription.active')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.membershipName')}</p>
                    <p>201811-NORMAL-FE-12</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.start')}</p>
                    <p>2023-10-11</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.state')}</p>
                    <p>{t('mymembership.subscription.active')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.end')}</p>
                    <p>2024-10-11</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.extended')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.paymentMethod')}</p>
                  </div>
                  <div className="active_categories">
                    <Form.Group className="category_f" id="formGridCheckbox">
                      <Form.Check name="payment" className="msg_pre_checkbox" type="radio" label={t('mymembership.subscription.directDebit')} />
                    </Form.Group>
                    <Form.Group className="category_f" id="formGridCheckbox">
                      <Form.Check name="payment" className="msg_pre_checkbox" type="radio" label={t('mymembership.subscription.prepayment')} />
                    </Form.Group>
                  </div>
                  <div className="subscription_btn">
                    <Button className="mx-5" as={Col} variant="primary" type="submit">
                      {t('mymembership.subscription.save')}
                    </Button>
                    <Button className="mx-5" as={Col} variant="primary" type="submit">
                      {t('mymembership.subscription.changeBank')}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.upgrade')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.noFurtherPurchase')}</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.serviceoffer')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.noFurtherPurchase')}</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.activeCategories')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <p className="m-0"><strong>{t('mymembership.subscription.yourActiveCategories')}</strong></p>
                <div className="active_categories">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                </div>
                <p>{t('mymembership.subscription.usingAllOptions')}</p>
                <div className="changePass_btn">
                  <Button style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.cancel')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.suspendService')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <div className="">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                </div>
                <div className="changePass_btn">
                  <Button className="mx-5 mtop" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.save')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
		</main>
    </div>
  );
}

export default Subscription;
