import React from 'react'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';


function UpdateAudioPopup(props) {

    const { t } = useTranslation();

    const getTitle = () => {
        switch (props.type) {
            case 'rename':
                return t('common.rename')
            case 'share':
                return t('common.share')
            case 'delete':
                return t('common.delete')
        }
    }

    return (
        <Modal className="audio_modal" show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    <Form.Group className="mb-3" controlId="popupForm.ControlInput1">
                        {props.type === 'rename'
                            ? <>
                                <Form.Label>{t('member.audio.rename')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.audioData?.audios?.[props.currentIndex]?.name?.split(".")[0]}
                                    autoFocus
                                    onBlurCapture={(e) => {
                                        const extension = props.audioData?.audios?.[props.currentIndex]?.name?.split(".")[1]
                                        const newName = e.target.value.split(".")[0] + "." + extension
                                        const audio = { ...props.audioData?.audios?.[props.currentIndex], name: newName }
                                        props.updateAudioData(audio)
                                    }}
                                />
                            </>
                            : props.type === 'share'
                                ? <>
                                    <Form.Label>{t('member.audio.share')} {props.audioData?.audios?.[props.currentIndex]?.name}</Form.Label>
                                    <Form.Check
                                        className='msg_pre_checkbox'
                                        type="checkbox" id='friendship'
                                        label={t('member.preregistration.categoryFriendship')}
                                        defaultChecked={props.audioData?.audios?.[props.currentIndex]?.friendshipShared === true}
                                        onClick={(e) => {
                                            const audio = { ...props.audioData?.audios?.[props.currentIndex], friendshipShared: e.target.checked }
                                            props.updateAudioData(audio)
                                        }}
                                    />
                                    <Form.Check
                                        className='msg_pre_checkbox'
                                        type="checkbox"
                                        id='partnership'
                                        label={t('member.preregistration.categoryPartnership')}
                                        defaultChecked={props.audioData?.audios?.[props.currentIndex]?.partnershipShared === true}
                                        onClick={(e) => {
                                            const audio = { ...props.audioData?.audios?.[props.currentIndex], partnershipShared: e.target.checked }
                                            props.updateAudioData(audio)
                                        }}
                                    />
                                </>
                                : <Form.Label>{props.audioData?.audios?.[props.currentIndex]?.name} {t('member.audio.deletePostFix')}</Form.Label>}
                    </Form.Group>
                </Form>
            </ModalBody>
            <Modal.Footer>
                {props.type === 'delete' ?
                    <Button variant="outline-danger" onClick={() => { props.deleteAudioFile() }}>
                        {t('common.delete')}
                    </Button>
                    : null}
                <Button variant="secondary" onClick={() => props.setShow(false)}>
                    {props.type === 'rename' ? 'OK' : t('common.close') }
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default UpdateAudioPopup

