import React, { useState, useRef, useEffect, useContext } from 'react'
import './questionair.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo
  } from '@fortawesome/free-solid-svg-icons';
import { questionaireAnswer, completeRegistration } from '../registrationProcess/ragistrationProcessService'
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from "react-i18next";
import RegionalSearch from './regionalSearch';
import Alert from 'react-bootstrap/Alert';
import { AlertHeading } from 'react-bootstrap';
import DescriptionAlert from './descriptionAlert';
import { autoFocusByID } from '../commonMethods';
import usePreventBack from './preventBack/preventBack'

function Questionaire(props) {
    const [answers, setAnswers] = useState({})
    const [errResponse, setErrResponse] = useState(null);
    const [idescription, setIdescription] = useState();
    const [isIAlertOpen, setIsIAlertOpen] = useState(false);
    const [freeTextValue, setFreeTextValue]=useState(null);
    const [freeTextError, setFreeTextError]=useState(null);
    const [freeTextId, setFreeTextId]=useState(null);
    const [error, setError] = useState(false);
    const [selectAlert, setSelectAlert] = useState('')
    const [radioAlert, setRadioAlert] = useState("")
    const [errorIds, setErrorIds] = useState([]);
    const [showAlertHeading, setShowAlertHeading] = useState(false)
    const [inputTextError, setInputTextError]=useState(null);
    const [errorMgsByID, setErrorMgsByID] = useState('');
    const [radioError, setRadioError]=useState(null);
    const [selectError, setSelectError]=useState(null);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const questionRefs = useRef(new Map());
    const handleClose = () => setIsIAlertOpen(false);
    const defaultOption = <option value={''}>{t('registration.questionnaires.pleaseSelectOption')}</option>

    const onAlertOpen = (data) => {
        setIsIAlertOpen(true);
        setIdescription(data)
    }

    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    usePreventBack();

    useEffect(() => {
        if (errorIds.length > 0) {
            const firstErrorElement = questionRefs.current.get(errorIds[0]);
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else{
            window.scrollTo(0, 0)
        }
    }, [errorIds]);
    const forQuetionaireAnswer = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 ||answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });

        setErrorIds(newErrorIds);

        const answerBody = {
            questionnaireId: props?.questionaire?.id,
            answers: answers
        };
        try {

            let cleanedAnswerBody = {...answerBody};
            let cleanedRequest = {};
            for (let key in cleanedAnswerBody?.answers) {
                if (!cleanedAnswerBody?.answers[key]?.answerValues?.includes("")) {
                    cleanedRequest[key] = cleanedAnswerBody.answers[key];
                } else {
                    delete cleanedAnswerBody.answers[key];
                }
            }

            questionaireAnswer(cleanedAnswerBody, accessToken, currentLang)
                .then((res) => {
                    if (res?.status === 200) {
                        setAnswers({});
                        setErrResponse(null);
                        if (res.data?.nextQuestionnaire !== null) {
                            props.setQuestionaireId(res.data?.nextQuestionnaire);
                        } else {
                            props.setQuestionaireId(res.data?.nextStep);
                        }
                    } else {
                        setErrResponse(res.data);
                        setShowAlertHeading(res.data?.message ? true : false)
                    }
                })
        }
        catch (error) {
        }
    };

    const handleOptionClick = (question, answer, questionGroup) => {         
    
        if(question.type === 'TextQuestion' && question.textQuestion.type==="RichTextArea"){
            setFreeTextError(null)
            setFreeTextId(question.id)
            setFreeTextValue(answer)
            if(question.required === true && answer.length >= 200 && errResponse?.errorMessageByQuestionId[question.id]) {
                let errResponseTemp = errResponse
                delete errResponseTemp.errorMessageByQuestionId[question.id]
                setErrResponse(errResponseTemp)
            }
        }
        if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            if (question.choiceQuestion.multiSelect === true) {
                let shouldEnable = false
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption ||
                            (answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0);
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    props.setStoreQuestionaire(tempQn)
                })

                if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                    answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
                    updatedAnswersValues(answers)  
                } else if (!answers[question.id]) {
                    answers[question.id] = { "answerValues": [answer], "profileVisible": false }
                    updatedAnswersValues(answers)  
                }
                return;
            }
            else {
                let shouldEnable = false;
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    if (!shouldEnable) {
                        answers[key] = {}
                        hideChild(key, questionGroup.id, tempQn, answers)
                    }
                    props.setStoreQuestionaire(tempQn)
                })
            }
        }
        if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
            if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
            } else if (!answers[question.id]) {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)  
        } else {
            if (answers[question.id]) {
                answers[question.id].answerValues = [answer]
            } else {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)            
        }
    };

    const handleOnChange = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.enabledQuestion && data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 || answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });
        if (newErrorIds.length === 0) {
            setErrResponse(null)
            setShowAlertHeading(false)
        }
    }
    
    const updatedAnswersValues =(answers) => {
        for (let key in answers) {
            if (Object.keys(answers[key]).length !== 0 && !answers[key]?.answerValues?.includes("")) {
                setAnswers({ ...answers })
            } else {
                delete answers[key];
            }
        }
    }
    
    const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
        const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
        const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
        if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                savedAnswers[key] = {}
                storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
            })
        }
    }

    const validateWithTab = (e, data) => {              
        if (e.type === "keypress" && e.keyCode === 9) {
          if (e.target.type === "select-one") {
            if (e.target.value === "" && data.required) {
              setSelectError(t('registration.questionnaires.fieldRequired'));
              setSelectAlert(data.id);
              autoFocusByID(e.target.id);
            } else {
              setSelectAlert('');
              setSelectError(null)
            }
          } else if (e.target.type === "radio" ) {
            if (!e.target.checked && data.required) {
              setRadioError(t('registration.questionnaires.fieldRequired'));
              setRadioAlert(data.id);
              autoFocusByID(e.target.id);
            } else {
              setRadioAlert('');
              setRadioError(null)
            }
          }
        }
      };

    const handleTextInput = (e, data, qId) => {     
        if (e.target.value === "") {
            setErrorMgsByID(data.required ? data.id : "");
            if (data.required) {
                autoFocusByID(e.target.id);
                setError(true);
                setInputTextError(t('registration.questionnaires.fieldRequired'));
            }
        }        
        if (e.target.value && e.target.min && e.target.max) {
            let value = e.target.value;
            let minRange = e.target.min ? Number(e.target.min) : 0;
            let maxRange = e.target.max ? Number(e.target.max) : 0;
            if (!/^\d*$/.test(value)) {
                e.target.value = value.slice(0, -1);
                setInputTextError(t("registration.questionnaires.onlyNumeric"));
                return;
            }
            let curentValue = Number(value); 
            if (curentValue < minRange || curentValue > maxRange) {                   
              setError(true);
              setInputTextError(`${t("registration.questionnaires.minMaxRangeValue")} ${e.target.min} and ${e.target.max} ${t("registration.questionnaires.minMaxRangeValue1")}`);
              setErrorMgsByID(data.id);
              autoFocusByID(e.target.id);
              return;
            } else {
              let range = [];
              let elements = document.querySelectorAll(`[data-qid='${qId}']`);
              elements.forEach((ele, k) => {
                range.push({ value: Number(ele.value), qid: qId });
              });
              if (minRange > 0 && maxRange > 0 && range.length >= 2) {            
                let minValue = range[0].value;
                let maxValue = range[1].value;
                if (minValue > 0 && maxValue > 0 && maxValue < minValue) {
                  setError(true);
                  setInputTextError(t("registration.questionnaires.maximumValue"));
                  setErrorMgsByID(data.id);
                  autoFocusByID(e.target.id);
                  return;
                }
              }
              setError(false);
              setInputTextError('');
              return;
            }
        }
    }
    const handleFreeText = (e, data) => {
        if (data.required && e.type === "keydown" && e.keyCode === 9) {
            const isEmpty = e.target.value === "";
            const freeTextLength = e.target.value.length < 200;
            if (isEmpty || freeTextLength) {
                setFreeTextError(t(isEmpty ? 'registration.questionnaires.fieldRequired' : 'suggestions.freetextlimit'));
                setError(true);
                autoFocusByID(e.target.id);
                setFreeTextValue(data.id);
                if (freeTextLength) scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        }
        setFreeTextError(null);
        setError(false);
    }
    const errorMessages = errResponse?.errorMessageByQuestionId;
    const lastErrorMessage = errorMessages ? Object.values(errorMessages).pop() : null;
    const autofillAnswers = () => {
        const updatedAnswers = {};
        let updatedQuestionaire = { ...props.questionaire };
       
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((question) => {
                if (question.type === 'ChoiceQuestion') {
                    if (question.choiceQuestion?.choices.length > 0) {
                        const firstChoiceId = question.choiceQuestion.choices[1].id;    
                        updatedAnswers[question.id] = { "answerValues": [firstChoiceId], "profileVisible": false };
                    }
                }
                if (question.type === 'TextQuestion' || question.type === 'NumberQuestion') {
                    if (question.id == 179) {
                        updatedAnswers[question.id] = { "answerValues": [123], "profileVisible": false };
                    } else updatedAnswers[question.id] = { "answerValues": [], "profileVisible": false };
                    if (question?.textQuestion?.type == 'RichTextArea' && (question.id == 172 || question.id == 165)) {
                        updatedAnswers[question.id] = { "answerValues": ["With Gleichklang you can now take the decisive step on the way to a happy partnership or a real friendship After registration you can view and fill in all questionnaires and view your results. This is not binding and you are not obliged to decide to use the paid placement service.This is not binding and you are not obliged to decide to use the paid placement service."], "profileVisible": false };
                    }
                }
            });
        });
        setAnswers(updatedAnswers);
        props.setStoreQuestionaire(updatedQuestionaire);
        setErrResponse(null)
        setShowAlertHeading(false)
    };

    const questionReference = (question, evt) => {
        if (evt) {
            questionRefs.current.set(question.id.toString(), evt);
        } else {
            questionRefs.current.delete(question.id.toString());
        }
    }

    const autoRegister = () => {
        completeRegistration(accessToken).then((result) => {
            if (result?.status === 200) {
                props.setQuestionaireId(result.data?.nextStep);
            } else {
                setErrResponse(result.data);
                setShowAlertHeading(result.data?.message ? true : false)
            }
        })
    }
    
    
    let questionnaireHeader;
    if (props.questionaire?.id == 1) { //Partnership 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
        <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == 8) { //Partnership 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 12) { //Partnership 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
        <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == 2) { //Friendship 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
        <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == 11) { //Friendship 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 13) { //Friendship 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
        <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == 25) { //Personal Details 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step1of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 6) { //Personal Details 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step2of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 5) { //Personal Details 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step3of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 10) { //Personal Details 4
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step4of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 7) { //Personal Details 5
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step5of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 9) { //Social Engagement 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.socialEng')}</strong> &ndash; {t('member.preregistration.step1of1')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 3) { //Self description partnership
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourPartnershipProfile')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 4) { //Self description friendship
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourFriendshipProfile')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == 14) { //Member Survey
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.helpUs')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else {
        questionnaireHeader = <h4>{props.questionaire?.name}</h4>;
    }

    return (
        <div className={"questionnaire_form questionnaire"+props.questionaire?.id}>
            <div className="looking_for">
                {questionnaireHeader}
                <p dangerouslySetInnerHTML={{ __html: props.questionaire?.description }}></p>
            </div>
           {process.env.REACT_APP_ENV != 'PROD' ? <div className="mt-3 mb-3 auto_fill_wrapper">
            {(props.questionaire?.id == 14 || props.questionaire?.id == 4) ? null :
                <Button
                    className='mx-5'
                    variant="secondary"
                    onClick={() => autoRegister()}
                >
                    {t('registration.buttons.autoregister')}
                </Button>
            }
                {props.questionaire?.id == 12 ||
                    props.questionaire?.id == 13 ? null : <Button
                        className='mx-5'
                        variant="success"
                        onClick={autofillAnswers}
                    >
                    {t('registration.buttons.autofill')}
                </Button>}
                <Button
                    className='mx-5'
                    variant="primary"
                    onClick={() => forQuetionaireAnswer()}
                >
                    {t('registration.buttons.next')}
                </Button>
            </div> : null}
            <div className='from_control questionair_container' id='questionnaire_container' ref={scrollRef}>
                <Alert className='alert_questionaire' show={showAlertHeading} variant="danger" id='main_alert' dismissible onClose={() => setShowAlertHeading(false) }>
                    <AlertHeading>{t('registration.questionnaires.errorHeader')}</AlertHeading>
                    <p>{lastErrorMessage ? lastErrorMessage :""}</p>
                </Alert>
                <Alert className='alert_questionaire' show={freeTextError ? true : false} variant="danger" id='main_alert'>
                    <AlertHeading>{t('registration.questionnaires.errorHeader')}</AlertHeading>
                    <p>{freeTextError ? freeTextError :""}</p>
                </Alert>
                {props.questionaire?.questionGroups?.map((qgroup) => (
                    <div key={qgroup.name}>
                        <div className='questionair_header'>
                            <h3 className='questionair_heading'>{qgroup.name}</h3>
                        </div>
                        <div className="questions_wrapper">
                            <p className="group_description" dangerouslySetInnerHTML={{ __html: qgroup.description }}></p>
                        {
                            qgroup.questions.map((data) => {
                                
                                if (data.type === 'ChoiceQuestion')
                                    if (data.choiceQuestion?.type === 'ComboBox')
                                        return (
                                        <div key={`combo-${data.id}`} className='questionair_alignment' 
                                        ref={(e) => questionReference(data,e)}             
                                        >
                                            {data.enabledQuestion === true ?
                                                <div>
                                                    <Form.Group className="mt-3 dropdown">
                                                        <span className="disriptionI_label">
                                                            {data?.description ?
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    onClick={() => onAlertOpen(data?.description)}
                                                                /> : null}
                                                            <Form.Label  htmlFor={data.id} id={`label-${data.id}`}>{data.name}
                                                                <span className='required_asterisks'>{data.required ? '*' : null}</span>
                                                            </Form.Label>
                                                        </span>
                                                        <Form.Select
                                                            id={data.id}
                                                            className={data.required && selectAlert == data.id && 'is-invalid'}
                                                            aria-label={data.required && selectAlert === data.id ? selectError : data.name}
                                                            value={ answers[data.id]?.answerValues?.[0] || defaultOption}
                                                            onChange={(e) => {
                                                                handleOptionClick(data, e.target.value, qgroup)
                                                                handleOnChange()
                                                                if(e.target.value !== '') setSelectAlert('')}}
                                                            onKeyPress={(e) => validateWithTab(e, data)}
                                                        >
                                                            <option value= "">{t('registration.questionnaires.pleaseSelectOption')}</option>
                                                            {data.choiceQuestion.choices.map(choice => (
                                                                <option
                                                                    key={choice.id}
                                                                    value={choice.id}
                                                                >
                                                                    {choice.value}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        {data.askVisibleToOthers === true ?
                                                            <Form.Check id={`profileVisible${data.id}`}
                                                                name={`profileVisible${data.id}`}
                                                                type="checkbox"
                                                                label={t('common.visibleToOthers')}
                                                                className='msg_pre_checkbox profile_visible'
                                                                onClick={(e) => {
                                                                    if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                        answers[data.id].profileVisible = e.target.checked
                                                                        setAnswers(answers)
                                                                    }
                                                                }}
                                                            >
                                                            </Form.Check> : null}
                                                    </Form.Group>
                                                    {data.required && selectAlert === data.id ? (<Alert className='alert_questionaire'  variant="danger">{selectError}</Alert>): (
                                                        <Alert className='alert_questionaire' show={!answers[data.id] && data.required  &&  errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                        variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                                    )}       
                                                    
                                                </div> : null}
                                        </div>
                                        )
                                if (data.choiceQuestion?.type === 'Radio')
                                    return (
                                        <div key={`radio-${data.id}`} className='radio_section'
                                        ref={(e) => questionReference(data, e)}  
                                        >
                                            {data.enabledQuestion === true ? <div>
                                                <fieldset>
                                                    <legend className="disriptionI_label inlineLegend"  id={`legend-${data.id}`}>
                                                        {data?.description ? (
                                                            <FontAwesomeIcon
                                                                icon={faCircleInfo}
                                                                onClick={() => onAlertOpen(data?.description)}
                                                            />
                                                        ) : null}
                                                        {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                    </legend>                                              
                                                <div id={data.id} className='radio_groups' role='radiogroup' aria-labelledby={`legend-${data.id}`}
                                                >
                                                    {data.choiceQuestion.choices.map(choice => (
                                                        <Form.Group key={choice.id} className="category_f" id={`label-${choice.id}-${data.id}`}>
                                                            <Form.Check
                                                                checked={answers[data.id]?.answerValues?.includes(choice.id)}
                                                                id={`radio-${choice.id}-${data.id}`}                                              
                                                                type="radio"
                                                                name={data.id}
                                                                label={choice.value}
                                                                className='msg_pre_checkbox'
                                                                onClick={(e) => {
                                                                    handleOptionClick(data, choice.id, qgroup)
                                                                    if(e.target.checked !== "") setRadioAlert("")
                                                                    handleOnChange()
                                                                }}
                                                                onKeyPress={(e) => validateWithTab(e, data)}
                                                            />
                                                        </Form.Group>
                                                    ))
                                                    }
                                                </div>
                                                {data.askVisibleToOthers === true ?
                                                    <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                                    {data.required && radioAlert == data.id ? (
                                                        <Alert className='alert_questionaire'  variant="danger">{radioError}</Alert> 
                                                    ):(
                                                         <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                         variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                                    )}
                                                 </fieldset>
                                            </div> : null}
                                        </div>
                                    )
                                if (data.choiceQuestion?.type === 'Checkbox')
                                    return (
                                        <div key={`check-${data.id}`} className='questionair_alignment'
                                        ref={(e) => questionReference(data, e)}  
                                        >
                                            {data.enabledQuestion === true ? <div>
                                            <fieldset>  
                                                <legend className="disriptionI_label inlineLegend">
                                                    {data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                    <strong>{data.name} <span className='required_asterisks'>{data.required ? '*' : null}</span></strong>
                                                </legend>
                                                <div className='checkbox_questionair'>
                                                    {data.choiceQuestion.choices.map(choice => (
                                                        <Form.Group key={choice.id} className="category_f">
                                                            <Form.Check
                                                                defaultChecked={answers[data.id]?.answerValues.includes(choice.id)}
                                                                id= {`checkbox-${choice.id}-${data.id}`}
                                                                name={data.id}
                                                                type="checkbox"
                                                                label={choice.value}
                                                                className='msg_pre_checkbox'
                                                                onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        handleOptionClick(data, choice.id, qgroup)
                                                                        handleOnChange()
                                                                    } else if (answers[data.id] && answers[data.id].answerValues.indexOf(choice.id) >= 0) {
                                                                        answers[data.id].answerValues.splice(answers[data.id].answerValues.indexOf(choice.id), 1)
                                                                        if(answers[data.id].answerValues.length == 0) delete answers[data.id];
                                                                        let shouldEnable = false
                                                                        Object.keys(data.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                                                                            data.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                                                                                shouldEnable = answers[data.id] && answers[data.id].answerValues.indexOf(enablerOption) >= 0;
                                                                                if (shouldEnable) { return true; }
                                                                                return false;
                                                                            })
                                                                            const tempQn = { ...props.questionaire }
                                                                            tempQn.questionGroups.filter(qg => qg.id === qgroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                                                                            if (!shouldEnable) {
                                                                                answers[key] = {};
                                                                            }
                                                                            props.setStoreQuestionaire(tempQn)
                                                                        })
                                                                        setAnswers(answers)
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    {data.askVisibleToOthers === true ?
                                                        <Form.Check id={`profileVisible${data.id}`}
                                                            name={`profileVisible${data.id}`}
                                                            type="checkbox"
                                                            label={t('common.visibleToOthers')}
                                                            className='msg_pre_checkbox'
                                                            onClick={(e) => {
                                                                if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                    answers[data.id].profileVisible = e.target.checked
                                                                    setAnswers(answers)
                                                                }
                                                            }}
                                                        >
                                                        </Form.Check> : null}
                                                </div>
                                                <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                    variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                            </fieldset>
                                            </div> : null}
                                        </div>
                                    );
                                if (data.type === 'NumberQuestion')
                                    return (
                                    <div key={`number-${data.id}`} className='questionair_alignment'
                                    ref={(e) => questionReference(data, e)}  
                                    >
                                        {data.enabledQuestion === true ? <div>
                                            <Form.Group className="mt-3 dropdown"  role="group" aria-labelledby={`description-${data.id}`}>
                                                <div className="disriptionI_label" id={`description-${data.id}`}>
                                                    {data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                    <Form.Label id={`label-${data.id}`}  className="radio_label" htmlFor={data.id}>
                                                        {data.name} <span className='required_asterisks'>{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    defaultValue={answers[data.id]?.answerValues[0] || ''}
                                                    type="text"
                                                    id={data.id}
                                                    data-qid = {qgroup.id}
                                                    aria-label={error && errorMgsByID === data.id && inputTextError }
                                                    className={ error && errorMgsByID === data.id && 'is-invalid'}
                                                    aria-describedby={`description-${data.id}`}
                                                    min={data.numberQuestion.minValue}
                                                    max={data.numberQuestion.maxValue}
                                                    onChange={(e) => {
                                                        handleOptionClick(data, e.target.value, qgroup)
                                                        handleTextInput(e, data, qgroup.id)
                                                        handleOnChange()
                                                    }}
                                                    onKeyDown={(e)  => handleTextInput(e, data, qgroup.id)}
                                                />
        
                                                {data.askVisibleToOthers === true ?
                                                    <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                            </Form.Group>
                                            { error && errorMgsByID === data.id ? <Alert className='alert_questionaire' variant="danger" id={`err_${data.id}`}>{inputTextError}</Alert>: (
                                                 <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                 variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                            )}

                                        </div> : null}
                                    </div>
                                    )

                                if (data.type === 'TextQuestion')
                                    return (
                                    <div key={`text-${data.id}`} className='questionair_alignment'
                                    ref={(e) => questionReference(data, e)}  
                                    >
                                        {data.enabledQuestion === true ? <div>
                                            <Form.Group className="mt-3 text_question">
                                                <span className="disriptionI_label">
                                                    {data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                    <Form.Label className="radio_label" htmlFor={data.id}>
                                                        {data.name} <span className='required_asterisks'>{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                </span>
                                                <Form.Control
                                                    value={answers[data.id] != null && answers[data.id].answerValues != null && answers[data.id].answerValues.length > 0 
                                                        ? answers[data.id]?.answerValues[0] : ''}
                                                    as="textarea"
                                                    className={data.required && error && freeTextValue === data.id && 'is-invalid'}
                                                    id={data.id}
                                                    aria-label={data.required && error && freeTextValue === data.id ? freeTextError : data.name}
                                                    onChange={(e) => {
                                                        handleOptionClick(data, e.target.value, qgroup)
                                                        if(e.target.value !== "") setError(false)
                                                        handleOnChange()
                                                    }}
                                                    onKeyDown={(e)  => handleFreeText(e,data)}
                                                />
                                                {data.askVisibleToOthers === true ?
                                                      <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                            </Form.Group>
                                            {error && freeTextValue === data.id ? (<Alert className='alert_questionaire' variant="danger" id={`err_${data.id}`}>{freeTextError}</Alert> ):(
                                                <Alert className='alert_questionaire' show={data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                    variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}
                                                </Alert>
                                            )}
                                            
                                    {freeTextError && freeTextId && freeTextId===data.id &&
                                    <Alert className='alert_questionaire' id={data.id}
                                        variant="danger" >
                                        {freeTextError ? freeTextError :""}
                                    </Alert>}
                                        </div> : null}
                                    </div>
                                    )

                                if (data.type === 'RegionQuestion')
                                    return (
                                        <RegionalSearch
                                            key={`region-${data.id}`}
                                            data={data}
                                            questionaireId={props.questionaire?.id}
                                            setAnswers={setAnswers}
                                            isFromRegistration={true}
                                        />
                                    )
                            })
                        }
                        </div>
                    </div>

                ))}
                <div className="mt-3 step_one_next">
                    <Button
                        className='mx-5'
                        variant="primary"
                        onClick={() => forQuetionaireAnswer()}
                    >
                        {t('registration.buttons.next')}
                    </Button>
                </div>
            </div>
            {isIAlertOpen ?
                <DescriptionAlert
                    description={idescription}
                    show={isIAlertOpen}  
                    handleClose={handleClose}                  
                /> : null}
        </div>
    )
}

export default Questionaire;