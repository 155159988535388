import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Placeholder from 'react-bootstrap/Placeholder';
import { useTranslation } from 'react-i18next';
import { newsViewMarked } from './homeServices';
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';

function News(props) {
  const [index, setIndex] = useState(0);
  const [news, setNews] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  let {
    loginData: {
      accessToken
    }, 
  } = useContext(LoginContext);
  
  const token_lang = {
    language: currentLang,
    accessToken
  }

  const handleClose = () => setShow(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setNews(props.news)
  }, [props.news])

  const openNews = (newsObject) => {
    setPopupData(newsObject);
    setShow(true);
  }

  const onNewsMarked = (id) => {    
    newsViewMarked(token_lang, id).then((response) => {
      if (response.status === 200) {
        handleClose();
        props.setNewsRefresh(true)
      }
    })
  }

  return (
    <div className="news_section">
      <h3>{t('member.news.newsheading')}</h3>
      <Carousel className='carousal' activeIndex={index} onSelect={handleSelect} interval={null}>
        { news != null ?
          news?.map((newsObject) => {
            return <Carousel.Item key={newsObject.title}>
              <Carousel.Caption className='news_carousal'>
                <div onClick={() =>  openNews(newsObject) }>
                  <h4>{newsObject.title}</h4>
                  <p>{newsObject.teaserText}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          }) : 
          <Carousel.Item>
            <Carousel.Caption className='news_carousal'>
              <Placeholder animation="glow">
                <Placeholder xs={5} />
                <Placeholder xs={4} />
                <Placeholder xs={4} />{' '}
                <Placeholder xs={5} /> <Placeholder xs={8} />
              </Placeholder>
            </Carousel.Caption>
          </Carousel.Item>
        }
      </Carousel>

      {show ? <div className="modal show">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{popupData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: popupData.newsText }}></p>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleClose}>
            {t('member.incompletequestionnaire.close')}
            </Button>
            <Button variant="success" onClick={() => onNewsMarked(popupData.newsId)}>
            {t('member.news.markviewed')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div> : null}
    </div>
  )
}

export default News