import React from 'react'
import Modal from 'react-bootstrap/Modal';

function DescriptionAlert(props) {

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body
                    className='idescription'
                    dangerouslySetInnerHTML={{ __html: props?.description }}>
                </Modal.Body>               
            </Modal>
        </div>
    )
}

export default DescriptionAlert;