import React, { useEffect, useContext, useState } from 'react';
import { getQuestionaireURL } from './personalDetailsService.js';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import PersonalDetails from './personalDetails.js';
import personalDetailsIcon from '../../assets/images/personal_details.png';

function SocialEnvironement() {
    const [questionaire, setQuestionaire] = useState();
    const { t, i18n } = useTranslation();

    const data = {
        "image": personalDetailsIcon,
        "menuName": t('member.personaldata.personaldetails.title'),
        "header": questionaire?.name,
        "description": questionaire?.description,
        "color": "#464565"
    }


    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        getQuestionaireURL(token_lang, '6').then((res) => {
            if (res?.status === 200) {
                setQuestionaire(res?.data)
            }
        }).catch((error) => {
        })
    }, [currentLang])

    return (
        <div>
            <PersonalDetails
                data={data}
                questionaire={questionaire}
            />
        </div>
    )
}

export default SocialEnvironement;

