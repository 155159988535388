import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import { getCountry, getRegion, getZipCode } from "./ragistrationProcessService";
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faTrashAlt,
    faPen
  } from '@fortawesome/free-solid-svg-icons';
import { saveQuestionaire } from '../personalDetails/personalDetailsService';
import usePreventBack from './preventBack/preventBack'

function RegionalSearch(props) {
    const [searchRelocatable, setSearchRelocatable] = useState(false);
    const [relocatable, setRelocatable] = useState(false);
    const [continentId, setContinentId] = useState();
    const [countries, setCountries] = useState();
    const [countryId, setCountryId] = useState();
    const [regions, setRegions] = useState();
    const [regionId, setRegionId] = useState([]);
    const [regionValue, setRegionValue] = useState([]);
    const [zipcode, setZipCode] = useState();
    const [zipcodeValue, setZipCodeValue] = useState('');
    const [proximityValue, setProximityValue] = useState('');
    const [countryProximityId, setCountryProximityId] = useState('');
    const [continentProximityId, setContinentProximityId] = useState('');
    const [countryProximityValue, setCountryProximityValue] = useState('');
    const [regionProximityId, setRegionProximityId] = useState('');
    const [zipProximityId, setZipProximityId] = useState('');
    const [isRestrictCountry, setIsRestrictCountry] = useState();
    const [isRestrictRegion, setIsRestrictRegion] = useState(false);
    const [regionContinent, setRegionContinent] = useState('');
    const [regionCountry, setRegionCountry] = useState('');
    const [restrictedRegions, setRestrictedRegions] = useState([]);
    const [proximityRegions, setProximityRegions] = useState([]);
    const [isEditTrueForRegions, setIsEditTrueForRegions] = useState(false);
    const [editIndexForRegions, setEditIndexForRegions] = useState()
    const [isEditTrueForProximity, setIsEditTrueForProximity] = useState(false);
    const [editIndexForProximity, setEditIndexForProximity] = useState();
    const [regionSearchRequestsData, setRegionSearchRequestsData] = useState([]);
    const [proximitySearchRequestsData, setProximitySearchRequestsData] = useState([]);
    const [isAlertOpenForRegion, setIsAlertOpenForRegion] = useState(false);
    const [isErrorForRegion, setIsErrorForRegion] = useState(null);
    const [isAlertOpenForProximity, setIsAlertOpenForProximity] = useState(false);
    const [isErrorForProximity, setIsErrorForProximity] = useState(null);
    const [errorForRegion, setErrorForRegion] = useState();
    const [errorForProximity, setErrorForProximity] = useState();
    const [errorsField, setFieldErrors] = useState({
        continent: false,
        continentone: false,
        country: false,
        countries: false,
        zipcode: false,
        proximity: false
      });
    const { t,i18n } = useTranslation();
    let {
        loginData: {
            accessToken,
        }        
    } = useContext(LoginContext);
    const currentLang = i18n.language;
    const token_lang = {
        language: currentLang,
        accessToken
      }
    const answerBody =  {
            "question": props?.data?.id,
            "searchRelocatable": searchRelocatable,
            "relocatable": relocatable,
            "regionSearchRequests": regionSearchRequestsData,
            "proximitySearchRequests": proximitySearchRequestsData        
    }
    usePreventBack();
    useEffect(() => {
        props.setAnswers(answerBody)
    }, [
        searchRelocatable,
        relocatable,
        regionSearchRequestsData,
        proximitySearchRequestsData
    ])
    useEffect(() => {
        props.setAnswers({
            "question": props?.data?.id,
            "searchRelocatable": searchRelocatable,
            "relocatable": relocatable,
            "regionSearchRequests": [],
            "proximitySearchRequests": []
        })
    }, [])

    const selectRegions = (id, value) => {
        if (regionId?.includes(id)) {
            setRegionId(prevSelectedRegions => prevSelectedRegions.filter(regionId => regionId !== id));
            setRegionValue(prevValue => prevValue.filter(regionValue => regionValue !== value));
        } else {
            setRegionId(prevSelectedRegions => [...prevSelectedRegions, id]);
            setRegionValue(prevValue => [...prevValue, value]);
        }
    }

    const getCountryData = async (continentId) => {
        const data = await getCountry(accessToken, continentId)
        if (data?.status === 200) {
            setCountries(data.data?.locations)
        }
    }

    const getRegionData = async (countryId) => {
        const res = await getRegion(accessToken, countryId);
        
        if (res?.status === 200 ) {
            setRegions(res.data?.locations)
        }
    }

    const handleAddRestrictions = () => {        
            if (regionContinent !== '' && regionCountry !== '' && restrictedRegions.length < 10) {
                setRestrictedRegions([
                    ...restrictedRegions,
                    {
                        continent: regionContinent,
                        continentId: continentId,
                        country: regionCountry,
                        countryId: countryId,
                        region: regionValue,
                        isRestrictRegion: isRestrictRegion
                    }
                ]);
            
            setRegionSearchRequestsData([
                ...regionSearchRequestsData,
                {
                    continent: continentId,
                    country: countryId,
                    regions: regionId
                }

            ])
            setRegionValue([]);
            setRegionContinent('');
            setContinentId('');
            setCountryId('');
            setRegionCountry('');
            setIsRestrictRegion(false);
            setRegionId([]);
        }
};

    const removeRegion = (indexToDelete) => {
        const updatedregionSearchRequestsData = regionSearchRequestsData.filter((_, i) => i !== indexToDelete);
        setRegionSearchRequestsData(updatedregionSearchRequestsData)
        const updatedRestrictedRegions = restrictedRegions.filter((_, i) => i !== indexToDelete);
        setRestrictedRegions(updatedRestrictedRegions);
    };

    
    const editRestrictedRegion = (id) => {
        setRegionValue(restrictedRegions[id].region);
        setRegionContinent(restrictedRegions[id].continent);
        setContinentId(restrictedRegions[id].continentId)
        setCountryId(restrictedRegions[id].countryId)
        setRegionCountry(restrictedRegions[id].country)
        setIsRestrictRegion(restrictedRegions[id].isRestrictRegion)
        setIsEditTrueForRegions(!isEditTrueForRegions)
        setEditIndexForRegions(id)
    }
    
    const saveEditedRestrictedRegion = (index) => {
        restrictedRegions[index].continent = regionContinent;
        restrictedRegions[index].continentId = continentId;
        restrictedRegions[index].country = regionCountry;
        restrictedRegions[index].countryId = countryId;
        restrictedRegions[index].region = regionValue;
        restrictedRegions[index].isRestrictRegion = isRestrictRegion;

        regionSearchRequestsData[index].continent = continentId;
        regionSearchRequestsData[index].country = countryId;
        regionSearchRequestsData[index].regions = regionId;

        setRegionValue([]);
        setRegionContinent('');
        setContinentId('');
        setCountryId('');
        setRegionCountry('')
        setIsRestrictRegion(false);
        setIsEditTrueForRegions(!isEditTrueForRegions);
        setEditIndexForRegions()
    }
    
    const addProximityRegion = () => {
        if (zipcodeValue !== '' && countryProximityValue !== '' && proximityRegions.length < 10) {
            setProximityRegions([
                ...proximityRegions,
                { 
                     zipcode: zipcodeValue,
                     proximity: proximityValue, 
                     country: countryProximityValue,
                     zipcodeId: zipProximityId,
                     proximityId: regionProximityId, 
                     countryId: countryProximityId,
                     continentId:continentProximityId,
                     restrictCountry: isRestrictCountry
                     }
            ])
            setProximitySearchRequestsData([
                ...proximitySearchRequestsData,
                {
                    country: countryProximityId,
                    zip: zipProximityId,
                    distance: regionProximityId,
                    restrictCountry: isRestrictCountry
                  }
            ])            
            setZipCodeValue('');
            setProximityValue('');
            setCountryProximityValue('');
            setCountryProximityId('');
            setContinentProximityId('');
            setZipProximityId('')
            setRegionProximityId('')
            setIsRestrictCountry('')
            setZipCode('')
        }
    }

    const editPrximityRegion = (index) => {
        setZipCodeValue(proximityRegions[index].zipcode);
        setProximityValue(proximityRegions[index].proximity);
        setCountryProximityValue(proximityRegions[index].country);
        setCountryProximityId(proximityRegions[index].countryId);
        setContinentProximityId(proximityRegions[index].continentId);
        setZipProximityId(proximityRegions[index].zipcodeId)
        setRegionProximityId(proximityRegions[index].proximityId)
        setIsRestrictCountry(proximityRegions[index].restrictCountry);
        setEditIndexForProximity(index);
        setIsEditTrueForProximity(!isEditTrueForProximity);
        setZipCode({value: proximityRegions[index].zipcodeId, label: proximityRegions[index].zipcode})
    }

    const removeProximity = (indexToDelete) => {
        const updatedProximity = proximityRegions.filter((_, i) => i !== indexToDelete);
        setProximityRegions(updatedProximity);
        const updatedproximitySearchRequestsData = proximitySearchRequestsData.filter((_, i) => i !== indexToDelete);
        setProximitySearchRequestsData(updatedproximitySearchRequestsData)
    };

    const saveEditedProximityRegions = (index) => {
        proximityRegions[index].zipcode = zipcodeValue;
        proximityRegions[index].proximity = proximityValue;
        proximityRegions[index].country = countryProximityValue;
        proximityRegions[index].countryId = countryProximityId;
        proximityRegions[index].continentId = continentProximityId;
        proximityRegions[index].zipcodeId = zipProximityId;
        proximityRegions[index].proximityId = regionProximityId; 

        proximitySearchRequestsData[index].country = countryProximityId;
        proximitySearchRequestsData[index].zip = zipProximityId;
        proximitySearchRequestsData[index].distance = regionProximityId;
        proximitySearchRequestsData[index].restrictCountry = isRestrictCountry;
        setZipCode()

        setEditIndexForProximity()
        setIsEditTrueForProximity(false);    
        setZipCodeValue('');
        setProximityValue('');
        setCountryProximityValue('');
        setCountryProximityId('');
        setContinentProximityId('');
        setZipProximityId('');
        setRegionProximityId('');   
        setIsRestrictCountry('');
    }

    async function loadZipOptions(search, loadedOptions, { page }) {
        const res = await getZipCode(accessToken, countryProximityId, page, search);
        if (res?.status === 200) {
            const { data = [] } = res || {};
            const adaptedJson = {
                results: data?.locations.map(item => ({
                    value: item.id,
                    label: item.value,
                })),
                has_more: data?.locations.length > 0,
            };

            return {
                options: adaptedJson?.results,
                hasMore: adaptedJson?.has_more,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    const addRegion = () => {
        setRestrictedRegions([
            ...restrictedRegions,
            {
                continent: regionContinent,
                continentId: continentId,
                country: regionCountry,
                countryId: countryId,
                region: regionValue,
                isRestrictRegion: isRestrictRegion
            }
        ]);
        setRegionSearchRequestsData([
            ...regionSearchRequestsData,
            {
                continent: continentId,
                country: countryId,
                regions: regionId
            }

        ])
        setRegionValue([]);
        setRegionContinent('');
        setContinentId('');
        setCountryId('');
        setRegionCountry('');
        setIsRestrictRegion(false);
        setRegionId([]);
    }
    const addProximity = () => {
        setProximityRegions([
            ...proximityRegions,
            {
                zipCode: zipcodeValue,
                proximity: proximityValue,
                country: countryProximityValue,
                zipcodeId: zipProximityId,
                proximityId: regionProximityId,
                countryId: countryProximityId,
                continentId: continentProximityId
            }
        ])
        setProximitySearchRequestsData([
            ...proximitySearchRequestsData,
            {
                country: countryProximityId,
                zip: zipProximityId,
                distance: regionProximityId,
                restrictCountry: isRestrictCountry
            }
        ])
        setZipCodeValue('');
        setProximityValue('');
        setCountryProximityValue('');
        setCountryProximityId('');
        setContinentProximityId('');
        setZipProximityId('')
        setRegionProximityId('')
        setIsRestrictCountry();
        setZipCode({
            "value": '',
            "label": t('registration.buttons.pleaseselect')
        })
    }

    const onChangeSave = (r, sr, type) => {
        if (type === 'region') {
            if (regionContinent == '') {
                setIsErrorForRegion(t('registration.proximity.continenterror'))
                setIsAlertOpenForRegion(true)
                return;
            }
            if (regionCountry == '') {
                setIsErrorForRegion(t('registration.proximity.countryerror'))
                setIsAlertOpenForRegion(true)
                return;
            }
            if (restrictedRegions.length == 10) {
                setIsAlertOpenForRegion(true);
                setIsErrorForRegion(t('registration.proximity.regionlimit'));
                return;
            }
        }

        if (type === 'proximity') {
            if (continentProximityId == '') {
                setIsAlertOpenForProximity(true);
                setIsErrorForProximity(t('registration.proximity.continenterror'));
                return;
            }

            if (countryProximityId == '') {
                setIsAlertOpenForProximity(true);
                setIsErrorForProximity(t('registration.proximity.countryerror'));
                return;
            }
            if (zipProximityId == '') {
                setIsAlertOpenForProximity(true);
                setIsErrorForProximity(t('registration.proximity.ziperror'));
                return;
            }
            if (regionProximityId == '') {
                setIsAlertOpenForProximity(true);
                setIsErrorForProximity(t('registration.proximity.proximityerror'));
                return;
            }
            if (proximityRegions.length == 10) {
                setIsAlertOpenForProximity(true);
                setIsErrorForProximity(t('registration.proximity.proximitylimit'));
                return;
            }


        }
        if (!props?.isFromRegistration) {
            saveQuestionaire(token_lang).then((res) => {
                if (res?.status === 200) {
                    props.setReloadQuestionaire(true);
                    if (type === 'region') {
                        addRegion();
                    }

                    if (type === 'proximity') {
                        addProximity();
                    }
                }
                else {
                    if (type === 'region') {
                        setErrorForRegion(Object.values(res?.data?.errorMessageByQuestionId))
                        setIsErrorForRegion(res?.data?.message)
                        setIsAlertOpenForRegion(true)
                    }
                    if (type === 'proximity') {
                        setErrorForProximity(Object.values(res?.data?.errorMessageByQuestionId))
                        setIsErrorForProximity(res?.data?.message)
                        setIsAlertOpenForProximity(true)
                    }
                }
            })
        }
    }
    useEffect(() => {
        if (regionContinent != '') {
            setIsErrorForRegion(null)
            setIsAlertOpenForRegion(false)
        }
        if (regionCountry != '') {
            setIsErrorForRegion(null)
            setIsAlertOpenForRegion(false)
        }
        if (continentProximityId != '') {
            setIsAlertOpenForProximity(false);
            setIsErrorForProximity(null);
        }
        if (countryProximityId != '') {
            setIsAlertOpenForProximity(false);
            setIsErrorForProximity(null);
        }
        if (zipProximityId != '') {
            setIsAlertOpenForProximity(false);
            setIsErrorForProximity(null);
        }
        if (regionProximityId != '') {
            setIsAlertOpenForProximity(false);
            setIsErrorForProximity(null);
        }

    },
        [
            regionContinent,
            regionCountry,
            regionProximityId,
            zipProximityId,
            countryProximityId,
            continentProximityId
        ])

        const onFocusTabSelect = (e) => {
            const fields = {
                formGridContinent: "continent",
                formGridCountry1: "country",
                formGridContinentOne: "continentone",
                formGridCountry2: "countries",
                formGridZip: "zipcode",
                formGridProximity: "proximity"
            };
        
            if (e.type === "keydown" && e.keyCode === 9 && fields[e.target.id]) {
                const invalidValue = !e.target.value;
                if (invalidValue) {
                    e.preventDefault()
                }
                setFieldErrors((prevState) => ({
                    ...prevState,
                    [fields[e.target.id]]: invalidValue
                }));
            }
          };
        
    return (
        <div className="region_search">           
            {props.questionaireId === 12 ? <div>
                <fieldset aria-describedby="regionalSearchDescription">
                    <legend><h4>{t('registration.willingness.title')}</h4></legend>
                    <p id="regionalSearchDescription">{t('registration.willingness.subtitle')} </p>
                        
                    <Form.Group className="mt-3 region_search_grp legend" controlId={'relocate_'+props.data.id}>
                        <Form.Check 
                            checked={relocatable}
                            name={props.data.id}
                            type="checkbox"
                            id={'relocate_'+props.data.id}
                            className='msg_pre_checkbox'
                            onChange={(e) => {setRelocatable(e.target.checked)
                                onChangeSave(e.target.checked, searchRelocatable, '');
                                
                            }}
                        />
                        <Form.Label>{t('registration.willingness.relocate')}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mt-3 region_search_grp legend" controlId={'partnerRelocate_'+props.data.id}>
                        <Form.Check 
                            checked={searchRelocatable} 
                            name={props.data.id}
                            type="checkbox"
                            id={'partnerRelocate_'+props.data.id}
                            className='msg_pre_checkbox'
                            onChange={(e) => {setSearchRelocatable(e.target.checked)
                                onChangeSave(relocatable, e.target.checked, '');
                                
                            }}
                        />
                        <Form.Label>{t('registration.willingness.partnerRelocate')}</Form.Label>
                    </Form.Group>
                </fieldset>
            </div> : null}
            <div>
                <h4>{t('registration.search.title')}</h4>
                <p>{t('registration.search.description')}</p>
                <p>{t('registration.search.worldwide')}</p>
                <Row className="mb-3 region_search_row">
                    <Form.Group className="mt-3" controlId="formGridContinent">
                        <Form.Label>{t('member.personaldata.personaldetails.continent')}<span className='required_asterisks'>*</span></Form.Label>
                        <Form.Select
                            value={continentId}
                            aria-label={t('registration.proximity.continenterror')}
                            className={errorsField && errorsField.continent ? "is-invalid" : "" }
                            onChange={(e) => {
                                getCountryData(e.target.value);
                                setContinentId(e.target.value);
                                setRegionContinent(e.target.selectedOptions[0].text)
                                if(e.target.value !== "" ) setFieldErrors(false)
                               
                            }}
                            onKeyDown={(e) => {
                                onFocusTabSelect(e, continentId) 
                            }}
                        >
                            <option value=''>{t('registration.buttons.pleaseselect')}</option>
                            {props.data?.regionQuestion.regionSearch.continents.map(continent => (
                                <option
                                    key={continent.id}
                                    value={continent.id}
                                >
                                    {continent.value}
                                </option>
                            ))}
                            
                        </Form.Select>
                        <div className='d-flex justify-content-end'>
                        {errorsField && errorsField.continent ? (
                        <span className='text-danger'>{t('registration.proximity.continenterror')}</span>
                        ) : ("")}</div>
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="formGridCountry1">
                        <Form.Label>{t('member.personaldata.personaldetails.country')}<span className='required_asterisks'>*</span></Form.Label>
                        <Form.Select
                            value={countryId}
                            aria-label={t('registration.proximity.countryerror')}
                            className={errorsField && errorsField.country && "is-invalid"}
                            onChange={(e) => {
                                getRegionData(e.target.value);
                                setCountryId(e.target.value)
                                setRegionCountry(e.target.selectedOptions[0].text)
                                if(e.target.value !== '')  setFieldErrors(false)
                            }}
                            onKeyDown={(e) => {
                                onFocusTabSelect(e, countryId) 
                            }}
                        >
                            <option value=''>{t('registration.buttons.pleaseselect')}</option>
                            {countries?.map(country => (
                                <option
                                    key={country.id}
                                    value={country.id}
                                >
                                    {country.value}
                                </option>
                            ))}
                        </Form.Select>
                        <div className='d-flex justify-content-end'>
                        {errorsField && errorsField.country ? (
                        <span className='text-danger'>{t('registration.proximity.countryerror')}</span>
                        ) : ("")}</div>
                    </Form.Group>
                    <Form.Group className="mt-3 mb-3 region_search_grp" controlId={`checkbox-${props.data.id}`}>
                        <Form.Check
                            checked={isRestrictRegion} 
                            name={props.data.id}
                            type="checkbox"
                            id={`checkbox-${props.data.id}`}
                            className='msg_pre_checkbox'
                            onChange={(e) => setIsRestrictRegion(e.target.checked)}
                        />
                        <Form.Label>{t('registration.search.restrictStates')}</Form.Label>
                    </Form.Group>
                    {isRestrictRegion ? <div className='checkbox_questionair'>
                        {regions?.map(region => (
                            <Form.Group key={`region-${region.id}`} htmlFor={`regioncheckbox-${region.id}`} className="my-2 category_f" id={`region-${region.id}`}>
                                <Form.Check
                                    checked={regionValue?.includes(region.value)}
                                    name={region.id} 
                                    id={`regioncheckbox-${region.id}`}
                                    type="checkbox"
                                    label={region.value}
                                    className='msg_pre_checkbox'
                                    onChange={(e) => {
                                        selectRegions(region.id, region.value);
                                    }}
                                />
                            </Form.Group>
                        ))
                        }
                    </div> : null}
                </Row>
                {isAlertOpenForRegion ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    dismissible
                    onClose={() => setIsAlertOpenForRegion(false)}
                >
                    <p>{isErrorForRegion}</p>
                    <p>{errorForRegion?.[0]}</p>
                </Alert> : ''}
                <div className="mt-3 step_one_next">
                    <Button
                        disabled={isEditTrueForRegions}
                        className='mx-5'
                        variant="primary"
                        onClick={() => {handleAddRestrictions()
                            onChangeSave(relocatable, searchRelocatable, 'region')

                        }}
                    >
                        {t('registration.buttons.addRegionRestriction')}
                    </Button>
                </div>
                <div className="proximity_restriction_serach">
                    {restrictedRegions.length ? <h4>{t('registration.proximity.regionSearches')} : <span>{restrictedRegions.length} / 10</span></h4> : null}
                    {restrictedRegions?.map((data, index) => (
                        <div key={index} className="result-box proximity-new-section">
                            <div className="proximity-one">
                                <div className="proximity_search">
                                    <div className="proximity_area region_arrow">
                                        <span >{data.continent} </span>
                                    </div>
                                    <div className="proximity_area region_arrow">
                                        <span>{data.country}</span>
                                    </div>
                                    <div className="regions">
                                        {data?.region?.map((region, index, {length}) => {
                                            if(index + 1 === length){ 
                                                return (
                                                    <span> {region}</span>
                                                );
                                            } else {
                                                return (
                                                    <span> {region}, </span>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>  
                            <div className="proximity-two region_btn">
                                <div className="action-btn">
                                    {isEditTrueForRegions && editIndexForRegions === index ?
                                        <span
                                            onClick={() => {
                                                saveEditedRestrictedRegion(index);
                                            }}
                                            role="button"
                                        >
                                            <FontAwesomeIcon icon={faSave} />
                                            {t('registration.buttons.save')}
                                        </span>
                                        :
                                        <span
                                            onClick={() => {
                                                editRestrictedRegion(index);
                                            }}
                                            role="button"
                                        >
                                            <FontAwesomeIcon icon={faPen} />
                                            {t('registration.proximity.edit')}
                                        </span>
                                    }
                                    <span onClick={() => removeRegion(index,)} role="button">
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        {t('registration.proximity.removeProximity')}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
            <div>
                <h4>{t('registration.proximity.title')}</h4>
                <p>{t('registration.proximity.description')}</p>
                <p>{t('registration.search.worldwide')}</p>
                <Row className="mb-3 region_search_row">
                    <Form.Group className="mt-3" controlId="formGridContinentOne">
                        <Form.Label>{t('member.personaldata.personaldetails.continent')}<span className='required_asterisks'>*</span></Form.Label>
                        <Form.Select
                            aria-label={t('registration.proximity.continenterror')}
                            className={errorsField && errorsField.continentone && "is-invalid"}
                            value={continentProximityId}
                            onChange={(e) => {
                                getCountryData(e.target.value);
                                setContinentProximityId(e.target.value)
                                if(e.target.value !== '')  setFieldErrors(false)
                            }}
                            onKeyDown={(e) => {
                                onFocusTabSelect(e, continentProximityId) 
                            }}
                        >
                            <option value=''>{t('registration.buttons.pleaseselect')}</option>
                            {props.data?.regionQuestion.proximitySearch.continents.map(continent => (
                                <option
                                    key={continent.id}
                                    value={continent.id}
                                >
                                    {continent.value}
                                </option>
                            ))}
                        </Form.Select>
                        <div className='d-flex justify-content-end'>
                        {errorsField && errorsField.continentone ? (
                        <span className='text-danger'>{t('registration.proximity.continenterror')}</span>
                        ) : ("")}</div>
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="formGridCountry2">
                        <Form.Label>{t('member.personaldata.personaldetails.country')}<span className='required_asterisks'>*</span></Form.Label>
                        <Form.Select
                            aria-label={t('registration.proximity.countryerror')}
                            className={errorsField && errorsField.countries && "is-invalid"}
                            value={countryProximityId}
                            onChange={(e) => {
                                setCountryProximityId(e.target.value);
                                setCountryProximityValue(e.target.selectedOptions[0].text)
                                if(e.target.value !== '')  setFieldErrors(false)
                            }}
                            onKeyDown={(e) => {
                                onFocusTabSelect(e, countryProximityId) 
                            }}
                        >
                            <option value=''>{t('registration.buttons.pleaseselect')}</option>
                            {countries?.map(country => (
                                <option
                                    key={country.id}
                                    value={country.id}
                                >
                                    {country.value}
                                </option>
                            ))}
                        </Form.Select>
                        <div className='d-flex justify-content-end'>
                        {errorsField && errorsField.countries ? (
                        <span className='text-danger'>{t('registration.proximity.countryerror')}</span>
                        ) : ("")}</div>
                    </Form.Group>
                            <Form.Group className="mt-3" controlId="formGridZip">
                                <Form.Label htmlFor='zipcodeInput'>{t('member.personaldata.personaldetails.zip')}<span className='required_asterisks'>*</span></Form.Label>                       
                                <AsyncPaginate 
                                    value={zipcode}
                                    inputId="zipcodeInput"
                                    className={`${errorsField && errorsField.proximity ? "is-invalid" : ""} async_paginate`}
                                    loadOptions={loadZipOptions}
                                    cacheUniqs={[countryProximityId]}
                                    isDisabled={!countryProximityId}
                                    onChange={(e) => {
                                        setZipProximityId(e.value);
                                        setZipCodeValue(e.label);
                                        setZipCode({value:e.value, label: e.label})
                                    }}
                                    onKeyDown={(e) => {
                                        onFocusTabSelect(e, zipcode) 
                                    }}
                                    additional={{
                                        page: 1,
                                    }}
                                    placeholder={t('registration.buttons.pleaseselect')}
                                />
                            </Form.Group>                  
                    <Form.Group className="mt-3" controlId="formGridProximity">
                        <Form.Label>{t('registration.proximity.proximity')}<span className='required_asterisks'>*</span></Form.Label>
                        <Form.Select
                            aria-label={t('registration.proximity.proximityerror')}
                            className={errorsField && errorsField.proximity && "is-invalid"}
                            value={regionProximityId}
                            onChange={(e) => {
                                setRegionProximityId(e.target.value);
                                setProximityValue(e.target.selectedOptions[0].text)
                                if(e.target.value !== '')  setFieldErrors(false)
                            }}
                            onKeyDown={(e) => {
                                onFocusTabSelect(e, regionProximityId) 
                            }}
                        >
                            <option value=''>{t('registration.buttons.pleaseselect')}</option>
                            <option value='TINY'>{props.data?.regionQuestion.proximitySearch.distance.TINY}</option>
                            <option value='SMALL'>{props.data?.regionQuestion.proximitySearch.distance.SMALL}</option>
                            <option value='MEDIUM'>{props.data?.regionQuestion.proximitySearch.distance.MEDIUM}</option>
                            <option value='LARGE'>{props.data?.regionQuestion.proximitySearch.distance.LARGE}</option>
                        </Form.Select>
                        <div className='d-flex justify-content-end'>
                        {errorsField && errorsField.proximity ? (
                        <span className='text-danger'>{t('registration.proximity.proximityerror')}</span>
                        ) : ("")}</div>
                    </Form.Group>
                </Row>
                <div className='active_categories'>
                    <fieldset>
                        <legend><strong>{t('registration.search.selectedCountryOnly')}</strong></legend>
                            <Form.Group className="my-3 category_f" id="formGridCheckbox1" htmlFor="restrict_country_Yes">
                                <Form.Check
                                    checked={isRestrictCountry === true}
                                    name='restrict_country'
                                    id='restrict_country_Yes'
                                    className='msg_pre_checkbox'
                                    type="radio"
                                    label={t('registration.buttons.yes')}
                                    onChange={(e) => setIsRestrictCountry(true)}
                                />
                            </Form.Group>
                            <Form.Group className="my-3 category_f" id="formGridCheckbox2" htmlFor="restrict_country_No">
                                <Form.Check
                                    checked={isRestrictCountry === false}
                                    name='restrict_country'
                                    id='restrict_country_No'
                                    className='msg_pre_checkbox'
                                    type="radio"
                                    label={t('registration.buttons.no')}
                                    onChange={(e) => setIsRestrictCountry(false)}
                                />
                            </Form.Group>
                    </fieldset>
                </div>
                {isAlertOpenForProximity ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    dismissible
                    onClose={() => setIsAlertOpenForProximity(false)}
                >
                    <p>{isErrorForProximity}</p>
                    <p>{errorForProximity?.[0]}</p>
                </Alert> : ''}
                <div className="mt-3 step_one_next">
                    <Button
                        disabled={isEditTrueForProximity}
                        className='mx-5'
                        variant="primary"
                        onClick={() => (addProximityRegion(),
                         onChangeSave(relocatable, searchRelocatable, 'proximity'))}
                    >
                        {t('registration.buttons.addProximityRestriction')}
                    </Button>
                </div>
                <div className="proximity_restriction_serach">
                    {proximityRegions.length ? <h4>{t('registration.proximity.proximitySearches')} : <span>{proximityRegions.length} / 10</span></h4> : null}
                    {proximityRegions?.map((data, index) => (
                        <div key={index} className="result-box proximity-new-section">
                            <div className="proximity-one">
                                <div className="proximity_search">
                                    <div className="proximity_value">
                                        <span >PLZ: {data.zipcode}</span>
                                        <span >{t('registration.proximity.proximity')}:<br/>{data.proximity}</span>
                                    </div>
                                    <div className="proximity_mark">
                                        <span className="circle">
                                            <span className="inner_circle">
                                            </span>
                                        </span>
                                    </div>

                                    <div className="proximity_area">
                                        <span >({data.country})</span>
                                    </div>
                                </div>
                            </div>
                            <div className="proximity-two">
                                <div className="action-btn">
                                    {isEditTrueForProximity && editIndexForProximity === index ?
                                        <span
                                            onClick={() => {
                                                saveEditedProximityRegions(index);
                                            }}
                                            role="button"
                                        > 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1z" />
                                                <path fillRule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708z" />
                                            </svg>
                                            {t('registration.buttons.save')}
                                        </span>
                                        :
                                        <span
                                            onClick={() => {
                                                editPrximityRegion(index);
                                            }}
                                            role="button"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                            </svg>
                                            {t('registration.proximity.edit')}
                                        </span>
                                    }
                                    <span onClick={() => removeProximity(index)} role="button" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg>
                                        {t('registration.proximity.removeProximity')}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </div>
    )
}

export default RegionalSearch;