import React, { useState, createRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import { ModalBody, Alert, Modal, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCropSimple,
    faRotate,
    faRotateLeft,
    faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { updateImageMetadata, cropImage } from './personalDetailsService';
import heic2any from 'heic2any';

function ImageCropper(props) {    
    const { currentImage, setImages, images, currentGallery, setCurrentImage } = props;
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState();
    const [isImageCropped, setIsImageCropped] = useState(false);
    const [partnership, setPartnership] = useState(false);
    const [friendship, setFriendship] = useState(false);
    const [description, setDescription] = useState();
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [errorMessage, setErrorMessage] = useState([]);
    const [isStatusPending, setIsStatusPending] = useState(false);

    const { loginData: { accessToken } } = useContext(LoginContext);
    const cropperRef = createRef();
    const { t, i18n } = useTranslation();
    const closeCropper = () => {
        props.setShow(false);
        setCurrentImage(null);
    }

    useEffect(() => {
        setImage(currentImage?.imageUrl)
    }, [])
    const getCropData = (e) => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setIsImageCropped(true);
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
    };
    const rotateLeft = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.rotate(-90);
        }
    };
    const rotateRight = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.rotate(90);
        }
    };
    const reset = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.reset();
            setIsImageCropped(false);
        }
    };
    const saveCroppedImage = async () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setIsStatusPending(true);
            const newCropData = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
            const imageName = currentImage?.image?.name;            
            const base = imageName?.substring(0, imageName?.lastIndexOf('.'));
            const ext = imageName?.substring(imageName?.lastIndexOf('.'));
            const timestamp = Date.now().toString();
            const lastThreeDigits = timestamp.slice(-3);
            const newImageName = `cropped${lastThreeDigits}${ext}`;
            const fileData = dataURLtoFile(newCropData, newImageName);
            let newImageUrl;
            if (fileData.type == 'image/heif' || fileData.type == 'image/heic') {
                const jpegBlob = await heic2any({
                    blob: fileData,
                    toType: 'image/jpeg',
                    quality: 1
                });
                newImageUrl = URL.createObjectURL(jpegBlob);
            } else {
                newImageUrl = URL.createObjectURL(fileData);
            }

            let formData = new FormData();
            formData.append('file', fileData, fileData.name);

            const uploadData = {
                form: formData,
                language: i18n.language,
                accessToken: accessToken,
                galleryId: currentGallery?.id,
                mediaId: currentImage?.image?.id 
            };

            try {
                const response = await cropImage(uploadData);
                if (response.data) {
                    if (response?.status === 200) {                        
                        const uploadedFiles = response.data.uploadedFiles;
                        const croppedImageName = Object.keys(uploadedFiles)[0];  
                        const croppedImageId = uploadedFiles[croppedImageName];

                        setImages(prevImages =>
                            prevImages.map(image =>
                                image.image.name == currentImage?.image?.name
                                    ? {
                                        image: {
                                            id: croppedImageId,
                                            name: croppedImageName
                                        },
                                        imageUrl: newImageUrl
                                    }
                                    : image
                            )
                        );
                        if (description) {
                            updateImageWithDescAndCategory(croppedImageName, croppedImageId);
                        }
                        closeCropper();
                        setCurrentImage(null);
                    }
                    else {
                        setIsError(true);
                        setIsStatusPending(false);
                        setErrorMsg(response.data.message);
                        setErrorMessage(response.data.errorMessages);
                    }
                }
            } catch (error) {
            }
        }
    };
    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const updateImageWithDescAndCategory = async (newImageName, imageId) => {
        const cropedImageDetail = {
            id: imageId,
            name: newImageName,
            description: description,
            makePartnershipProfileImage: partnership,
            makeFriendshipProfileImage: friendship
        };
        const imageData = {
            language: i18n.language,
            accessToken: accessToken,
            renameData: cropedImageDetail,
            id: currentGallery?.id
        };

        const response = await updateImageMetadata(imageData);
        if (response.data) {
            if (response?.status === 200) {
                closeCropper();
            }
            else {
                setIsError(true);
                setIsStatusPending(false);
                setErrorMsg(response.data.message);
                setErrorMessage(response.data.errorMessages);
            }
        }
    };

    return (
        <Modal className='cropper_modal' size='lg' show={props.show} onHide={() => closeCropper()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("cropper.cropimage")}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <div className='cropper_container'>
                    <Cropper
                        ref={cropperRef}
                        className='main_cropper'
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={isImageCropped ? cropData : image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                    />
                </div>
                <div className='cropper_btns'>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='right_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-right_rotate`}>{t("cropper.rotateright")}</Tooltip>}
                        >
                            <Button
                                onClick={rotateRight}
                                className='mt-1'
                                variant="primary"
                            >
                                <FontAwesomeIcon icon={faRotateRight} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='left_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-left_rotate`}>{t("cropper.rotateleft")}</Tooltip>}
                        >
                            <Button
                                onClick={rotateLeft}
                                className='mt-1'
                                variant="primary"
                            >
                                <FontAwesomeIcon icon={faRotateLeft} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='left_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-editImage`}>{t("cropper.crop")}</Tooltip>}
                        >
                            <Button
                                onClick={getCropData}
                                className='mt-1'
                                variant="primary"
                            >
                                <FontAwesomeIcon
                                    icon={faCropSimple}
                                />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='reset'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-reset`}>{t("cropper.reset")}</Tooltip>}
                        >
                            <Button
                                onClick={reset}
                                className='mt-1'
                                variant="primary"
                            >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                </div>
                <div id='cropper_btn_wrapper' className='cropper_description_wrapper'>
                    <Form.Group className=''>
                        <Form.Label> {t("cropper.description")}</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={t("cropper.adddiscription")}
                        />
                    </Form.Group>
                    <Form.Label className='mt-3'> {t("cropper.setpicture")}</Form.Label>
                    <Form.Group className="select_edit_img" >
                        <Form.Check
                            type="checkbox"
                            label={t("cropper.partnership")}
                            checked={partnership}
                            onChange={() => {
                                setPartnership(!partnership);
                            }}
                            className='msg_pre_checkbox'
                        />
                    </Form.Group>
                    <Form.Group className="select_edit_img" >
                        <Form.Check
                            type="checkbox"
                            label={t("cropper.friendship")}
                            checked={friendship}
                            onChange={() => {
                                setFriendship(!friendship);
                            }}
                            className='msg_pre_checkbox'
                        />
                    </Form.Group>
                </div>
                {isError ? <Alert
                    className='member_address mt-3'
                    variant="danger"
                    id='main_alert'
                    dismissible
                >
                    <p>{errorMsg}</p>
                </Alert> : null}
                {errorMessage?.length ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    dismissible
                >
                    {
                        errorMessage.map((errorMsg, index) => <p key={index}>{errorMsg}</p>) || <p>{errorMsg}</p>}
                </Alert> : null}
                <div className='cropper_save_btn'>
                    <Form.Group className=''>
                        <Button
                            onClick={isStatusPending ? null : saveCroppedImage}
                            className='mt-1'
                            variant="primary"
                        >
                            {isStatusPending ?
                                <Spinner className='spinner_cropper' animation="border" variant="light" />
                                :
                                t("cropper.savecroppedImage")}
                        </Button>
                    </Form.Group>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ImageCropper;
