import { useState } from "react";

export const numberOnlyWithDigit = (evt, digit) => {
    var val = evt.target.value;
    if(digit === 1) var reg = /^([0-9]|[0-9]+)/g;
    if(digit === 2) var reg = /^([0-9]?[0-9]|[0-9]+)/g;
    if(digit === 3) var reg = /^([0-9]?[0-9]?[0-9]|[0-9]+)/g;
    if(digit === 4) var reg = /^([0-9]?[0-9]?[0-9]?[0-9]|[0-9]+)/g;
    if(digit === 5) var reg = /^([0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]|[0-9]+)/g;
    val = reg.exec(val);
    if (val) {
        evt.target.value = val[0]
    } else{
        evt.target.value = "";
    }
}
export const autoFocusByID = (inputId, sTime) => {
    var setTime = 0;
    if(sTime) setTime = sTime;
    if (inputId) {
      window.setTimeout(function () {
        if (document.getElementById(inputId)) {
          document.getElementById(inputId).focus();
        }
      }, setTime);
    }
};

// This function the cursor will focus in first element on error input with class name 'is-invalid', When user submit the data
export const autoFocusFirstElement = (sClassName) => {
  var className = 'is-invalid';
  if(sClassName) className = sClassName;
  window.setTimeout(function () {
    const ele = document.getElementsByClassName(className);
    if(ele && ele.length > 0) {
      autoFocusByID(ele[0].id)
    }
  }, 10);
}