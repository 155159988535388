import React, { useEffect, useContext, useState } from 'react';
import partnership from '../../assets/images/partnership.png';
import { LoginContext } from '../../context/loginContext';
import PersonalDetails from './personalDetails.js';
import { getQuestionaireURL } from './personalDetailsService.js';
import { useTranslation } from 'react-i18next';

function WhatRUlooking4P() {
  const [questionaire, setQuestionaire] = useState();
  const { t, i18n } = useTranslation();

  const data = {
    "image": partnership,
    "menuName": t('member.banner.partnership'),
    "header": questionaire?.name,
    "description": questionaire?.description,
    "color": "#a01313"
  }

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const currentLang = i18n.language;

  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    getQuestionaireURL(token_lang, '1').then((res) => {
      if (res?.status === 200) {
        setQuestionaire(res?.data)
      }
    }).catch((error) => {
    })
  }, [currentLang])

  return (
    <div>
      <PersonalDetails
        data={data}
        questionaire={questionaire}
      />
    </div>
  )
}

export default WhatRUlooking4P;