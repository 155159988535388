import React, { useContext, useEffect, useState } from 'react';
import {Button, Form, Modal, ModalBody, Alert} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Friendship from '../../assets/images/icon_category_friendship-pd.png';
import Partnership from '../../assets/images/icon_category_partnership-pd.png';
import sharedToSuggestionIcon from '../../assets/images/sharedToSuggestionIcon.png';
import { shareGallery } from './personalDetailsService';
import { LoginContext } from '../../context/loginContext';

function GalleryPopup(props) {
    const [allPartnership, setAllPartnership] = useState(false);
    const [allFriendship, setAllFriendship] = useState(false);
    const [partnershipMarkedInteresting, setPartnershipMarkedInteresting] = useState(false);
    const [friendshipMarkedInteresting, setFriendshipMarkedInteresting] = useState(false);
    const [customSelection, setCustomSelection] = useState(false);
    const [isShareGallery, setIsShareGallery] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isError , setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    const getTitle = () => {
        switch (props.type) {
            case 'rename':
                return t('common.rename');
            case 'share':
                return t('common.share');
            case 'delete':
                return t('common.delete');
            default:
                return '';
        }
    }

    useEffect(() => {
        setAllPartnership(props?.currentGalleryData?.allPartnership || false);
        setAllFriendship(props?.currentGalleryData?.allFriendship || false);
        setPartnershipMarkedInteresting(props?.currentGalleryData?.partnershipMarkedInteresting || false);
        setFriendshipMarkedInteresting(props?.currentGalleryData?.friendshipMarkedInteresting || false);
        setCustomSelection(props?.currentGalleryData?.customSelection || false);
    }, [props]);

    const handleCheckboxChange = (checkboxName) => {
        const newState = !eval(checkboxName);
        eval(`set${checkboxName.charAt(0).toUpperCase() + checkboxName.slice(1)}(${newState})`);

        const data = {
            id: props.currentGalleryData.id,
            allPartnership: checkboxName === 'allPartnership' ? newState : allPartnership,
            partnershipMarkedInteresting: checkboxName === 'partnershipMarkedInteresting' ? newState : partnershipMarkedInteresting,
            allFriendship: checkboxName === 'allFriendship' ? newState : allFriendship,
            friendshipMarkedInteresting: checkboxName === 'friendshipMarkedInteresting' ? newState : friendshipMarkedInteresting,
            customSelection: checkboxName === 'customSelection' ? newState : customSelection,
            excludeRelationshipIds: [],
            includeRelationshipIds: []
        };
        const body = {
            accessToken: accessToken,
            language: i18n.language,
            body: data
        };
        shareGallery(body).then((result) => {
            if (result?.status === 200) {
                setIsShareGallery(true);
            }
            else{
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
        });
    };

    const onClose = () => {
        props.setShow(false);
        if (isShareGallery) props.setIsGalleryRefreshed(!props.isGalleryRefreshed)
    }

    return (
        <Modal show={props.show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    {isError ? <Alert
                        className='member_address'
                        variant="danger"
                        id='main_alert'
                        onClose={() => setIsError(false)}
                        dismissible
                    >
                        <p>{errorMessage}</p>
                    </Alert> : null}
                    <Form.Group className="mb-3" controlId="popupForm.ControlInput1">
                        {props.type === 'share' ? (
                            <>
                                <Form.Label>
                                    {t('member.gallery.share')} {props.currentGalleryData?.name} {t('member.gallery.with')}
                                </Form.Label>
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="friendship"
                                    checked={allPartnership}
                                    label={
                                        <span className="sharetosug">
                                            <img src={Partnership} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.proposal')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('allPartnership')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="partnership"
                                    checked={allFriendship}
                                    label={
                                        <span className="sharetosug">
                                            <img src={Friendship} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.suggestions')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('allFriendship')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    checked={partnershipMarkedInteresting}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.allpartnership')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('partnershipMarkedInteresting')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    checked={friendshipMarkedInteresting}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.allfriends')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('friendshipMarkedInteresting')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    checked={customSelection}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.alloptions')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('customSelection')}
                                />
                            </>
                        ) : (
                            <Form.Label>
                                {props.currentGalleryData?.name} {t('member.audio.deletePostFix')}
                            </Form.Label>
                        )}
                    </Form.Group>
                </Form>
            </ModalBody>
            <Modal.Footer>
                {props.type === 'delete' && (
                    <Button variant="outline-danger" onClick={() => { props.onGalleryDelete(props?.currentGalleryData, true) }}>
                        {t('common.delete')}
                    </Button>
                )}
                <Button variant="secondary" onClick={() => onClose()}>
                    {t('common.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default GalleryPopup;
