import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import Banner from '../../../components/banner/banner';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faTrash,
    faArrowLeft,
    faCircleInfo,
    faMars,
    faVenus,
    faTransgender,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import { getContactLists, getContactListsDesc, getUserDetails } from '../suggestionService';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../../context/loginContext';
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-bootstrap/Pagination";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { getContinentForMemberAddress, getCountry, getRegion } from '../../registrationProcess/ragistrationProcessService';
import DescriptionAlert from '../../registrationProcess/descriptionAlert';
import { saveQuestionaire } from '../../personalDetails/personalDetailsService';
import Alert from 'react-bootstrap/Alert';
import useWebSocketComponent from '../messenger/webSocket';
import ChatHistory from '../messenger/chatHistory';
import { Modal } from 'react-bootstrap';


function CommunityLists(props) {
    const { sendMessage, userDetails } = useWebSocketComponent();
    const { listName, data } = props;
    const [natureList, setNatureList] = useState();
    const [recordPerPage, setRecordPerPage] = useState(10)
    const [paginateData, setPaginateData] = useState({
        data: [],
        limit: recordPerPage,
        activePage: 1
    });
    const [totalPage, setTotalPage] = useState();
    const [continent, setContinent] = useState([]);
    const [selectFilter, setSelectFilter] = useState();
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState();
    const [searchValue, setSearchValue] = useState({
        name: '',
        region: '',
        freeText: ''
    });
    const [alias, setAlias] = useState('');
    const [continentId, setContinentId] = useState();
    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [regionName, setRegionName] = useState('');
    const [freeText, setFreeText] = useState('');
    const [filtersData, setFiltersData] = useState([]);
    const [forAvailableFilter, setForAvailableFilter] = useState([]);
    const [description, setDescription] = useState();
    const [idescription, setIdescription] = useState();
    const [isIAlertOpen, setIsIAlertOpen] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [freeTextValue, setFreeTextValue] = useState('');
    const [isFreeTextLengthValid, setIsFreeTextLengthValid] = useState(false);
    const [storeSearchValue, setStoreSearchValue] = useState('');
    const [currentUserDetail, setCurrentUserDetail] = useState({});
    const [currentAvatar, setCurrentAvatar] = useState('');
    const [recordsRemaining, setRecordsRemaining] = useState(true);
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [users, setUsers] = useState([]);
    const [chats, setChats] = useState([]);
    const [userDetail,setUserDetail]=useState()
    const [chatHistoryModel,setChatHistoryModel]=useState(false)
    const [isMessageError, setIsMessageError] =  useState(false);
  const [pageNo, setPageNo] = useState(1);

    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    const handleClose = () => setIsIAlertOpen(false);

    const onAlertOpen = (data) => {
        setIsIAlertOpen(true);
        setIdescription(data)
    }

    const onBack = () => {
        props.setListName('contact-list');
    }

    const gotoNature = (e) => {
        props.setListName('nature');
      }
      
      const gotoProject = (e) => {
        props.setListName('project');
      }
    
      const gotoBeruf = (e) => {
        props.setListName('beruf');
      }
    
      const gotoTreffen = (e) => {
        props.setListName('treffen');
      }

    useEffect(() => {
        onDeleteAllFilter()
        getContactLists(token_lang, listName, '', paginateData?.activePage, paginateData?.limit).then((result) => {
            if (result.status === 200) {
                const total_count = result.data?.totalCount;
                const total_page = Math.floor(total_count / recordPerPage)
                const remainder = total_count % recordPerPage;
                if (remainder != 0) {
                    setTotalPage(total_page + 1);
                } else {
                    setTotalPage(total_page)
                }
                setNatureList(result.data)
                setPaginateData((prev) => ({
                    ...prev,
                    limit: recordPerPage,
                    data: result.data?.contactListUsers
                }));
            }
        })
    }, [listName]);

    useEffect(() => {
        getContactListsDesc(token_lang, listName).then((response) => {
            if (response?.status === 200) {
                setDescription(response.data)    
                setSelectedAnswer(response.data?.contactListQuestion?.choiceQuestion?.selectedChoices[0])
                setFreeTextValue(response.data?.freeTextQuestion?.textQuestion?.answer);
            }
        })
    }, [listName])


    const getContinents = async () => {
        const result = await getContinentForMemberAddress(token_lang)
        if (result?.status === 200) {
            setContinent(result.data?.locations)
        }
    }

    useEffect(() => {
        if (selectFilter == 10) {
            getContinents()
        }
    }, [selectFilter])

    const selectContinent = (e) => {
        getCountries(e.target.value);
        setContinentId(e.target.value);
        const selectedContinentId = e.target.value;
        const selectedContinent = continent.find(cont => cont.id == selectedContinentId);
        const continentName = selectedContinent ? selectedContinent.value : '';
        setRegionName(continentName);
    }

    const selectCountry = (e) => {
        getRegions(e.target.value);
        setCountryId(e.target.value);
        const selectedCountryId = e.target.value;
        const selectedCountry = countries.find(country => country.id == selectedCountryId);
        const countryName = selectedCountry ? selectedCountry.value : '';
        setRegionName(countryName);
    }

    const selectRegion = (e) => {
        setRegionId(e.target.value);
        const selectedRegionsId = e.target.value;
        const selectedRegion = regions?.find(region => region.id == selectedRegionsId);
        const regionName = selectedRegion ? selectedRegion.value : '';
        setRegionName(regionName);
    }

    const getCountries = async (id) => {
        const data = await getCountry(accessToken, id)
        if (data?.status === 200) {
            setCountries(data?.data.locations)
        }
    }

    const getRegions = async (countryId) => {
        const res = await getRegion(accessToken, countryId);
        if (res?.status === 200) {
            setRegions(res?.data?.locations)
        }
    }

    const onPaginationAndFilterChange = (pageNumber, pageRecord, search) => {
        setPaginateData((prev) => ({ ...prev, activePage: pageNumber }));
        getContactLists(token_lang, listName, search, pageNumber, pageRecord)
            .then((res) => {
                if (res.status === 200) {
                    const total_count = res.data?.totalCount;
                    const total_page = Math.floor(total_count / pageRecord)
                    const remainder = total_count % pageRecord;
                    if (remainder != 0) {
                        setTotalPage(total_page + 1);
                    } else {
                        setTotalPage(total_page)
                    }
                    setNatureList(res.data)
                    setPaginateData((prev) => ({
                        ...prev,
                        limit: pageRecord,
                        data: res.data?.contactListUsers
                    }));
                }
            })
    };

    const onGetUserDetails = (item) => {
            const getUsers = {
              type: "AUTH",      
              token: accessToken,
            };
            sendMessage(JSON.stringify(getUsers));
            setCurrentUserDetail(item);
        getUserDetails(token_lang, item?.alias)
            .then((res) => {
                if (res?.status === 200) {
                    setUserDetail({
                        ...res.data,        
                        alias: item?.alias,
                        userId:item?.id,
                        sex:{
                            value:item?.gender
                        },
                        address:  item.addressList &&
                        item.addressList.map(
                            (item2) => {
                                let addr = '';
                                if (item2.city !== undefined) {
                                    addr += item2.city + ", ";
                                }
                                if (item2.country !== undefined) {
                                    addr += item2.country.value + ", ";
                                }
                                if (item2.zip !== undefined) {
                                    addr += item2.zip.value;
                                }
                                return addr;
                            }
                        )
                    });
                    setChatHistoryModel(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
    };

    useEffect(() => {
        setChats([]);
      }, [currentUserDetail]);
    const filtersName = [
        { id: 1, value: 'Alias' },
        { id: 10, value: 'Region' },
        { id: 24, value: 'Free Text' },
    ]

    const availableOptions = filtersName.filter(item => !forAvailableFilter.includes(String(item.id)));

    const onSelectFilter = (e) => {
        setSelectFilter(e.target.value)
        setFiltersData([...filtersData, e.target.value])
    }

    let searchData;
    const onAddFilter = () => {
        searchValue.name = alias;
        searchValue.region = regionName;
        searchValue.freeText = freeText;

        if (filtersData.includes('1') && filtersData.includes('10') && filtersData.includes('24')) {
            searchData = `${1}-${alias},${10}-${continentId}:${countryId}:${regionId},${24}-${freeText}`;
        }
        else if (
            (filtersData.includes('1') && filtersData.includes('10')) ||
            (filtersData.includes('1') && filtersData.includes('24')) ||
            (filtersData.includes('10') && filtersData.includes('24'))
        ) {
            if (filtersData.includes('1') && filtersData.includes('10')) {
                searchData = `${1}-${alias},${10}-${continentId}:${countryId}:${regionId}`;
            }
            if (filtersData.includes('1') && filtersData.includes('24')) {
                searchData = `${1}-${alias},${24}-${freeText}`;
            }
            if (filtersData.includes('10') && filtersData.includes('24')) {
                searchData = `${10}-${continentId}:${countryId}:${regionId},${24}-${freeText}`;
            }
        }
        else if (selectFilter == '1') {
            searchData = `${selectFilter}-${alias}`;
        }

        else if (selectFilter == '10') {
            searchData = `${selectFilter}-${continentId}:${countryId}:${regionId}`;
        }

        else if (selectFilter == '24') {
            searchData = `${selectFilter}-${freeText}`;
        }
        if ((alias || freeText || regionName) ) {
            setStoreSearchValue(searchData)
            onPaginationAndFilterChange(1, recordPerPage, searchData);
            setForAvailableFilter([...forAvailableFilter, selectFilter])
        } else {
            return;
        }
        setSelectFilter('');
    };

    const onDeleteFilter = (deleteId) => {
        if (deleteId == '1') {
            setSearchValue({ ...searchValue, name: '' });
            setAlias('');
        }
        if (deleteId == '10') {
            setSearchValue({ ...searchValue, region: '' });
            setRegionName('')
            setContinentId()
            setCountryId('')
            setRegionId('')
            setContinent()
            setCountries()
            setRegions()
        }
        if (deleteId == '24') {
            setSearchValue({ ...searchValue, freeText: '' });
            setFreeText('')
        }
        const updatedFiltersData = filtersData.filter(id => id !== deleteId);
        setFiltersData(updatedFiltersData);
        const updatedfilters = forAvailableFilter.filter(id => id !== deleteId);
        setForAvailableFilter(updatedfilters);
        let data;
        if (updatedFiltersData.includes('1') && updatedFiltersData.includes('10') && updatedFiltersData.includes('24')) {
            data = `${1}-${alias},${10}-${continentId}:${countryId}:${regionId},${24}-${freeText}`;
        }
        else if (
            (updatedFiltersData.includes('1') && updatedFiltersData.includes('10')) ||
            (updatedFiltersData.includes('1') && updatedFiltersData.includes('24')) ||
            (updatedFiltersData.includes('10') && updatedFiltersData.includes('24'))
        ) {
            if (updatedFiltersData.includes('1') && updatedFiltersData.includes('10')) {
                data = `${1}-${alias},${10}-${continentId}:${countryId}:${regionId}`;
            }
            if (updatedFiltersData.includes('1') && updatedFiltersData.includes('24')) {
                data = `${1}-${alias},${24}-${freeText}`;
            }
            if (updatedFiltersData.includes('10') && updatedFiltersData.includes('24')) {
                data = `${10}-${continentId}:${countryId}:${regionId},${24}-${freeText}`;
            }
        }
        else if (updatedFiltersData.includes('1')) {
            data = `${1}-${alias}`;
        }

        else if (updatedFiltersData.includes('10')) {
            data = `${10}-${continentId}:${countryId}:${regionId}`;
        }

        else if (updatedFiltersData.includes('24')) {
            data = `${24}-${freeText}`;
        }
        else{
            data = ''
        }
        
        setSelectFilter('');
        setStoreSearchValue(data)
        onPaginationAndFilterChange(1, recordPerPage, data);
    };

    const onDeleteAllFilter = () => {
        if (alias != '' || freeText != '' || regionName !='') {
            onPaginationAndFilterChange(1, recordPerPage, '');            
        }
        setSearchValue({ name: '', freeText: '', region: '' });
        setFiltersData([])
        setForAvailableFilter([])
        setStoreSearchValue('')
        setSelectFilter('');
        setAlias('')
        setFreeText('')
        setRegionName('')
        setContinentId()
        setCountryId('')
        setRegionId('')
        setContinent()
        setCountries()
        setRegions()
    }

    useEffect(() => {
        if (freeTextValue?.length < 200) {
            setIsFreeTextLengthValid(true)
        } else setIsFreeTextLengthValid(false)
    }, [freeTextValue])

    const onSave = async (questionId, answer, isprofileVisible) => {
        let answers = {}
        if (answer.id) {
            setSelectedAnswer(answer)
            answers[questionId] = { "answerValues": [answer.id], "profileVisible": isprofileVisible }            
        }else{
            setSelectedAnswer({id: 1, value: 'Yes'})
            if (freeTextValue?.length < 200)  return;
            answers[questionId] = { "answerValues": [freeTextValue], "profileVisible": isprofileVisible }            
        }
        const answerBody = {
          questionnaireId: 25,
          answers: answers
        }
        await saveQuestionaire(answerBody, token_lang)        
      } 


    const selectPageCounts = (e) => {
        setRecordPerPage(e.target.value);
        onPaginationAndFilterChange(1, e.target.value, storeSearchValue);
    }
    
    useEffect(() => {
        if (userDetails?.fileType === 'IMAGE' || userDetails?.fileType === 'FILE') {
          setAttachmentFiles((prevAttachments) => [userDetails, ...prevAttachments]);
        }
    
        if (userDetails?.subType === 'CHAT_HISTORY' || userDetails?.type === "TEXT_MESSAGE" || userDetails?.type === "FILE_MESSAGE") {
          if (userDetails?.subType === "CHAT_HISTORY") {
            setRecordsRemaining(userDetails?.recordsRemaining);
          }
          if (userDetails?.error) {
            setIsMessageError(true)
          }
          const newMessages =
            userDetails?.type === "TEXT_MESSAGE" || userDetails?.type === "FILE_MESSAGE"
              ? [userDetails]
              : userDetails?.messages;
          setChats((prevMessages) => {
            const isChatHistory = userDetails?.subType === "CHAT_HISTORY";
            const currentUserId = currentUserDetail?.id;
            const newMessage = newMessages[0];
            const isCurrentUserInvolved = newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);
    
            if (isChatHistory) {
              return [...new Set([...prevMessages, ...newMessages])];
            } else if (isCurrentUserInvolved) {
              return [...new Set([...newMessages, ...prevMessages])];
            } else {
              const additionalMessages = userDetails?.messages || [];
              return [...new Set([...prevMessages, ...additionalMessages])];
            }
          });
        }
        if (userDetails?.subType === "DELETE") {
            setChats((prevMessages) =>
                prevMessages.filter((msg) => msg.id != userDetails.messageId)
            );
        }
      }, [userDetails]);
      

      const fetchChatHistory = (pageNo) => {
      const message = {
      type: "API",
      subType: "CHAT_HISTORY",
      pageNo: pageNo,
      targetUserId: currentUserDetail?.id,
      pageSize: 20,
    };
    if (currentUserDetail?.id) {
      sendMessage(JSON.stringify(message));
    }
  };

  useEffect(() => {
    fetchChatHistory(pageNo);
  }, [pageNo, currentUserDetail?.id]);

  const chatContainerRef = useRef(null);
  const lastContactElementRef = useCallback(
    (node) => {
      if (chatContainerRef.current) chatContainerRef.current.disconnect();
      chatContainerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
          setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatContainerRef.current.observe(node);
    },
    [recordsRemaining]
  );
    return (
        <main className="main_wrapper suggestions_wrapper nature">
            <Banner data={data} />
            <div className='questionaire_in_menubar'>
                
                <div className='nature_container'>
                    <div className='contact_list_btn'>

                        <Form.Group className='list_btn' >
                            <div>
                                <Button
                                    onClick={() => onBack()}
                                    className='mb-3 mt-1'
                                    variant="outline-danger"
                                >
                                    <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                    {t('suggestions.back')}
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group className='list_btn' >
                            <div>
                                <Button
                                    onClick={() => gotoNature()}
                                    className='mb-3 mt-1'
                                    variant="success"
                                    disabled={listName == 'nature'}
                                >
                                    {t('contactlist.nature.button')}
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group className='list_btn' >
                            <div>
                                <Button
                                    onClick={() => gotoProject()}
                                    className='mb-3 mt-1'
                                    variant="primary"
                                    disabled={listName == 'project'}
                                >
                                    {t('contactlist.project.button')}
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group className='list_btn' >
                            <div>
                                <Button
                                    onClick={() => gotoBeruf()}
                                    className='mb-3 mt-1'
                                    variant="warning"
                                    disabled={listName == 'beruf'}
                                >
                                    {t('contactlist.beruf.button')}
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group className='list_btn' >
                            <div>
                                <Button
                                    onClick={() => gotoTreffen()}
                                    className='mb-3 mt-1'
                                    variant="danger"
                                    disabled={listName == 'treffen'}
                                >
                                    {t('contactlist.treffen.button')}
                                </Button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="all-filter">
                        <div className="main_filter_freetext">                            
                            {description?.subscribed ?
                                <Form.Group className=" text_question" id='textquestion'>
                                    <Form.Label className="" htmlFor=''>
                                        {t('suggestions.descriptionLabel')}:<br/>
                                        <small>{t('suggestions.changeautosave')}</small>
                                    </Form.Label>
                                    <Form.Control
                                        value={description?.freeText}
                                        as="textarea"
                                        id='freetext'
                                        aria-describedby='free-text'
                                        readOnly
                                    />
                                </Form.Group>
                                :
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='accordian_personal_detail'>{t('suggestions.participate')}</Accordion.Header>
                                        <Accordion.Body>
                                            {description?.contactListQuestion?.choiceQuestion?.type === "Radio" ?
                                                <Form.Group className="mt-3 mb-3 radio" id='radio'>
                                                    <div className='disriptionI_label'>
                                                        {data?.description ?
                                                            <FontAwesomeIcon
                                                                icon={faCircleInfo}
                                                                onClick={() => onAlertOpen(description?.contactListQuestion?.description)}
                                                            /> : null}
                                                        <Form.Label>{description?.contactListQuestion?.name}</Form.Label>
                                                    </div>
                                                    <div id={data.id} className='radio_groups contactlist'>
                                                        {description?.contactListQuestion?.choiceQuestion?.choices?.map((choice) => (
                                                            <Form.Check
                                                                key={`${description?.contactListQuestion?.id}-${choice?.value}`}
                                                                checked={selectedAnswer?.id == choice?.id}
                                                                id={`${description?.contactListQuestion?.id}-${choice?.value}`}
                                                                name={description?.contactListQuestion?.name}
                                                                type="radio"
                                                                label={choice?.value}
                                                                className='msg_pre_checkbox'
                                                                onClick={(e) => onSave(
                                                                    description?.contactListQuestion?.id, 
                                                                    choice, 
                                                                    description?.contactListQuestion?.askVisibleToOthers &&
                                                                    description?.contactListQuestion?.visibleToOthers)}
                                                            >
                                                            </Form.Check>
                                                        ))}
                                                    </div>
                                                </Form.Group> : null
                                            }
                                            
                                            {(selectedAnswer?.value == 'Ja' || selectedAnswer?.value == 'Yes') && 
                                            description?.freeTextQuestion ?
                                                <>{isFreeTextLengthValid ? <Alert
                                                    className='member_address'
                                                    variant="danger"
                                                    id='main_alert'
                                                    dismissible
                                                >
                                                    <p>{t('suggestions.freetextlimit')}</p>
                                                </Alert> : null}
                                                    <Form.Group className="freetextarea " id='textquestion'>
                                                        <div className='freetextques disriptionI_label'>
                                                            {data?.description ?
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    onClick={() => onAlertOpen(description?.freeTextQuestion?.description)}
                                                                /> : null}
                                                            <Form.Label className="" htmlFor=''>
                                                                {description?.freeTextQuestion?.name}<br/>
                                                                <small>{t('suggestions.changeautosave')}</small>
                                                            </Form.Label>
                                                        </div>
                                                        <Form.Control
                                                            value={freeTextValue}
                                                            as="textarea"
                                                            id='freetext'
                                                            aria-describedby='free-text'
                                                            onChange={(e) => setFreeTextValue(e.target.value)}
                                                            onBlur={(e) => onSave(
                                                                description?.freeTextQuestion?.id,
                                                                '',
                                                                description?.freeTextQuestion?.askVisibleToOthers &&
                                                                description?.freeTextQuestion?.visibleToOthers)}
                                                        />
                                                    </Form.Group>
                                                </> : null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            }
                        </div> 
                        <div className="main-filter">
                            <Form.Label className="m-0">{t('suggestions.filter')}</Form.Label>
                            <Row className="mx-3">
                                <Form.Group as={Col}>
                                    <Form.Select
                                        value={selectFilter}
                                        className='filter_width'
                                        onChange={(e) => onSelectFilter(e)}
                                    >
                                        <option value=''>{t('registration.buttons.pleaseselect')}</option>
                                        {availableOptions?.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.value}
                                                    </option>
                                                ))}                                        
                                    </Form.Select>
                                </Form.Group>

                                {selectFilter == 10 ?
                                    <>
                                        <Form.Group as={Col}>
                                            <Form.Select
                                                className='filter_width'
                                                onChange={(e) => selectContinent(e)}
                                            >
                                                <option>{t('registration.buttons.pleaseselect')}</option>
                                                {continent?.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                        {countries?.length ? <Form.Group as={Col}>
                                            <Form.Select
                                                className='filter_width'
                                                onChange={(e) => selectCountry(e)}>
                                                <option>{t('registration.buttons.pleaseselect')}</option>
                                                {countries?.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group> : null}

                                        {regions?.length ?
                                            <Form.Group as={Col}>
                                                <Form.Select className='filter_width'
                                                    onChange={(e) => selectRegion(e)}
                                                >
                                                    <option>{t('registration.buttons.pleaseselect')}</option>
                                                    {regions?.map(item => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.value}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group> : null}

                                    </>
                                    : null}

                                {selectFilter == 1 ?
                                    <Form.Group as={Col}>
                                        <Form.Control
                                            className='filter_width'
                                            value={alias}
                                            type="text"
                                            onChange={(e) => setAlias(e.target.value)}
                                        />
                                    </Form.Group> : null}
                                {selectFilter == 24 ?
                                    <Form.Group as={Col}>
                                        <Form.Control
                                            className='filter_width'
                                            type="text"
                                            onChange={(e) => setFreeText(e.target.value)}
                                        />
                                    </Form.Group> : null}

                                <Form.Group as={Col}>
                                    <Button
                                        onClick={() => onAddFilter()}
                                        className='add-filter-btn'
                                        variant="success"
                                    >
                                        {t('suggestions.addfilter')}
                                    </Button>
                                </Form.Group>
                            </Row>
                        </div>
                        <div className="flex">
                            <div className="text_1 remove_filter">
                                <p className="text-left">
                                    {searchValue?.name ?
                                        <>
                                            <b>Alias : {searchValue?.name}</b>
                                            &nbsp;
                                            <span tabindex="0" aria-label="Delete alias filter">
                                                <FontAwesomeIcon
                                                    className='trash'
                                                    icon={faTrash}
                                                    onClick={() => onDeleteFilter('1')}
                                                />
                                            </span>
                                        </>
                                        : null}
                                </p>
                                <p>
                                    {searchValue?.freeText ?
                                        <>
                                            <b>Free Text : {searchValue?.freeText}</b>
                                            &nbsp;
                                            <span tabindex="0" aria-label="Delete free text filter">
                                                <FontAwesomeIcon
                                                    className='trash'
                                                    icon={faTrash}
                                                    onClick={() => onDeleteFilter('24')}
                                                />
                                            </span>
                                        </>
                                        : null}

                                </p>
                                <p>
                                    {searchValue?.region ?
                                        <>
                                            <b>Region : {searchValue?.region}</b>
                                            &nbsp;
                                            <span tabindex="0" aria-label="Delete Region filter">
                                                <FontAwesomeIcon
                                                    className='trash'
                                                    icon={faTrash}
                                                    onClick={() => onDeleteFilter('10')}
                                                />
                                            </span>
                                        </>
                                        : null}

                                </p>
                            </div>
                        </div>
                        {forAvailableFilter.length ? <div className="flex">
                            <Button
                                onClick={() => onDeleteAllFilter()}
                                className='mb-3 mt-1'
                                variant="outline-danger"
                            >
                                {t('suggestions.removefilter')}
                            </Button>
                        </div> : null}
                    </div>
                    {natureList?.contactListUsers?.map((item) => (
                        <div key={item.alias} className="content-filter">
                            <div className="full-row">
                                <div className="half">
                                    <h3>{item.alias}</h3>
                                    <p className="user_subinfo">
                                    <span>
                                    {(item.gender === 'Male' || item.gender === 'männlich') && <FontAwesomeIcon icon={faMars} />}
                                    {(item.gender === 'Female' || item.gender === 'weiblich') && <FontAwesomeIcon icon={faVenus} />}
                                    {(item.gender.includes("intersexual") || item.gender.includes("Intersexual") || item.gender.includes("intersexuell")) && <FontAwesomeIcon icon={faTransgender} />}
                                    {item.gender}
                                    </span>
                                    {item.age && `, ${item.age} ${t('suggestions.yearsold')}`}
                                    </p>
                                    <div className='suggestions_user_details'>
                                        <p className="user-age"></p>
                                        <p className="user-homeaddress"><FontAwesomeIcon icon={faMapMarkerAlt} /> 
                                            {item.addressList &&
                                                item.addressList.map(
                                                    (item2) => {
                                                        let addr = '';
                                                        if (item2.city !== undefined) {
                                                            addr += item2.city + ", ";
                                                        }
                                                        if (item2.country !== undefined) {
                                                            addr += item2.country.value + ", ";
                                                        }
                                                        if (item2.zip !== undefined) {
                                                            addr += item2.zip.value;
                                                        }
                                                        return addr;
                                                    }
                                                )}
                                        </p>
                                    </div>
                                    <button onClick={()=>onGetUserDetails(item)}  className="btn-green" aria-label="profile Santhe">
                                        <FontAwesomeIcon icon={faUser} /> {t('suggestions.showuserprofile')}
                                    </button>
                                </div>
                                <div className="half">
                                    <p>{item?.freeText}</p>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    {paginateData?.data?.length ? <Pagination className="nature_pagination">
                        {[...Array(totalPage)].map((_, index) => {
                            return (
                                <Pagination.Item
                                    onClick={() => onPaginationAndFilterChange(index + 1, recordPerPage, storeSearchValue)}
                                    key={index + 1}
                                    active={index + 1 === paginateData.activePage}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            );
                        })}
                        <Form.Label>{t('suggestions.recordperpage')}</Form.Label>
                        <Form.Select
                            onChange={(e) => selectPageCounts(e)}
                            className='record_per_page' >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Pagination> : null}
                </div>
            </div>
            <Modal 
            className='chatHistory_model' 
            fullscreen
            show={chatHistoryModel}
            onHide={() => setChatHistoryModel(false)}
        >
             <Modal.Header closeButton />
             <div className='communityContacts_chatHistory'>
            <ChatHistory
              chats={chats}
              setUsers={setUsers}
              currentUser={userDetail}
              setCurrentUser={setUserDetail}
              userDetails={userDetails}
              sendMessage={sendMessage}
              sourceType={'CONTACT_LIST'}
              currentAvatar={currentAvatar}
              isMessageError={isMessageError}
              attachmentFiles={attachmentFiles}
              setIsMessageError={setIsMessageError}
              chatContainerRef={lastContactElementRef}
            />
             </div>
            </Modal>
            {isIAlertOpen ?
                <DescriptionAlert
                    description={idescription}
                    show={isIAlertOpen}  
                    handleClose={handleClose}                  
                /> : null}
        </main>
    )
}


export default CommunityLists;