import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function LoginDescription() {
	const location = useLocation();

    const [registrationClass, setRegistrationClass] = useState('');
    useEffect(() => {
        if (location.state !== null) {            
            setRegistrationClass("registered");
        } else {
            setRegistrationClass("");
        }
    }, [])    
    
    const { t } = useTranslation();

    return (
        <div className={"login_banner "+registrationClass}>
            <div className="before_loginNote">
                <div className="note_text">
					<h1>{t('member.login.welcomegk')}</h1>
                    <h2>{t('member.login.note')}</h2>
                    <div className="register_paragraph">
                        <b>{t("member.login.notYetThere")}</b>
                        <p>{t('member.login.registernoteText')}</p>
                        <Link className="btn btn-primary" to="/pre-registration">{t("member.login.registerNow")}</Link>
                    </div>
                </div>
            </div>
            <div className="after_registration_loginNote">
                <div className="note_text">
					<h1>{t('member.login.welcomegk')}</h1>
                    <h2>{t('member.login.noteAfterReg')}</h2>
                    <div className="register_paragraph">
                        <p>{t('member.login.afterRegText')}</p>
                        <p>
                            <b>{t('member.login.afterRegHelp')}</b><br/>
                            {t('member.login.afterRegCall')}<br/>
                            <b className="green">{t('member.login.afterRegHotline')}</b>
                            {t('member.login.afterRegOpentime')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
