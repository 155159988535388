import React from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/membership.png';
import Banner from '../../components/banner/banner'
import './statistics.css'
import { useTranslation } from 'react-i18next';


function Statistics() {
  const { t } = useTranslation();

  const data = {
    "image": membershipIcon,
    "menuName": t('mymembership.mymembership'),
    "header": t('mymembership.statistics.statistics'),
    "description": t('mymembership.statistics.description'),
    "color": "#063978"
  }

  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data= {data}/>
      <div className='msg2gk_container'> 
      {/* <div className='container'>
        <div className="form_head_part text">
          <p>{t('mymembership.statistics.activity')}</p>
          <span className='underline'>
          </span>
        </div>
      </div> */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.activity')}</span>
          <span className="underline"></span>
        </div>
        <div className="form-group mmship-statics-activity">
          <p>%-view any proposal received 46.15 %(recommended percentage: 90%)</p>
          <p>%-Percentage of replies to messages sent first 39.39 %(average percentage: 60%)</p>
          <p>%-First proposal received 84.62 %(recommended percentage: 30%)</p>
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.cipher')}</p>
          <span>
          </span>
        </div> */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.cipher')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          <p>{t('mymembership.statistics.partnership')} 248 </p>
          <p>{t('mymembership.statistics.friendship')} 1618 </p>
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.suggestion')}</p>
          <span>
          </span>
        </div> */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.suggestion')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          <p>{t('mymembership.statistics.partnership')} 248 </p>
          <p>{t('mymembership.statistics.friendship')} 1618 </p>
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.sentcipher')}</p>
          <span>
          </span>
        </div>     */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.sentcipher')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          <p>{t('mymembership.statistics.partnership')} 248 </p>
          <p>{t('mymembership.statistics.friendship')} 1618 </p>
        </div>      
      </div>
		</main>
    </div>
  )
}

export default Statistics