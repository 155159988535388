function LoginReducer(states, action) {
    switch (action.type) {
      case 'LOGIN': {
        return {
          ...states,
          memberLogin: action.data 
        };
      }
      case 'MEMBER_DETAILS': {
        return {
          ...states,
          memberDetails: action.data
        };
      }
      case 'ACCESS_TOKEN': {
        return {
          ...states,
          accessToken: action.data
        };
      }
      case 'CURRENT_URL': {
        return {
          ...states,
          currentUrl: action.data
        };
      }
      default:
        return states;
    }
  }
  
  export default LoginReducer;
  