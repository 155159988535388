import { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { saveAnswers } from './homeServices.js'
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { AlertHeading } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

const IncompleteQuestionnaire = (props) => {
    const [questionnaire, setQuestionnaire] = useState(null);
    const [answers, setAnswers] = useState({});
    const [errResponse, setErrResponse] = useState(null);
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    useEffect(() => {
        setQuestionnaire(props.questionnaire)
    }, [props.questionnaire])

    const handleChange = (question, answer, questionGroup) => {
        if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            if (question.choiceQuestion.multiSelect === true) {
                let shouldEnable = false
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption ||
                            (answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0);
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...props.questionaire }
                    try {
                        tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    } catch (err) {
                    }
                    setQuestionnaire(tempQn)
                })

                if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                    answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
                    setAnswers({ ...answers })
                } else if (!answers[question.id]) {
                    answers[question.id] = { "answerValues": [answer], "profileVisible": false }
                    setAnswers({ ...answers })
                }
                return;
            }
            else {
                let shouldEnable = false;
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...questionnaire }
                    try {
                        tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    } catch (err) {
                    }
                    if (!shouldEnable) {
                        answers[key] = {}
                        hideChild(key, questionGroup.id, tempQn, answers)
                    }
                    setQuestionnaire(tempQn)
                })
            }
        }
        if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
            if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
            } else if (!answers[question.id]) {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            setAnswers({ ...answers })
        } else {
            if (answers[question.id]) {
                answers[question.id].answerValues = [answer]
            } else {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            setAnswers({ ...answers })
        }
        saveData()
    };

    const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
        const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
        const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
        if (parentQuestion && parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                savedAnswers[key] = {}
                try {
                    storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
                } catch (err) {
                }
            })
        }
    }

    const saveData = async () => {
        const body = {
            "questionnaireId": questionnaire?.id,
            "request": answers
        }
        const data = {
            body: body,
            language: currentLang,
            accessToken: accessToken
        }
        const res = await saveAnswers(data)
        if (res?.status === 200) {
            setAnswers({});
            setErrResponse(null);
            // props.handleClose()
        } else if (res?.status === 400) {
            setErrResponse(res.data)
        }
    }

    return (
        <> {
            questionnaire ?
                <div className="modal show">
                    <Modal
                        className='incomplete_q_modal'
                        show={props.show}
                        onHide={() => {
                            props.handleClose();
                            props.setRefreshIncompleteQ(true)
                        }}
                    >
                        <Modal.Header closeButton>
                            <h3 className='inquestionair_heading'>{questionnaire.name}</h3>
                        </Modal.Header>
                        <Modal.Body className='incomplete_questionaire'>
                            <div className='questionair_header incomplete_q_header'>
                                <p dangerouslySetInnerHTML={{ __html: questionnaire.description }}></p>
                            </div>
                            <div className='from_control incomplete_q_body'>
                                <Alert className='alert_questionaire' show={errResponse && errResponse.message ? true : false} variant="danger" id='main_alert'>
                                    <AlertHeading>{t('registration.questionnaires.errorHeader')}</AlertHeading>
                                    <p>{errResponse?.message}</p>
                                </Alert>
                                {questionnaire && questionnaire.questionGroups.length ? questionnaire.questionGroups
                                    .map((qGroup, index) => {
                                        return (
                                            <Accordion defaultActiveKey={index === 0 ? "0" : ''}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className='accordian_personal_detail'>{qGroup.name}</Accordion.Header>                                                    <Accordion.Body>
                                                        <div className='questionair_header'>
                                                            <p dangerouslySetInnerHTML={{ __html: qGroup.description }}></p>
                                                        </div>
                                                        <div>
                                                            {qGroup.questions && qGroup.questions.length ? qGroup.questions
                                                                .map((question) => {
                                                                    if (question.type === 'ChoiceQuestion') {
                                                                        if (question.choiceQuestion?.type === 'Radio')
                                                                            return (
                                                                                <div>
                                                                                    {question.enabledQuestion === true ? <div>
                                                                                        <Form.Label id={question.id} className="mt-3" htmlFor={question.id}>{question.name}
                                                                                            <span className='required_asterisks'>{question.required ? '*' : null}</span></Form.Label>
                                                                                        <div id={question.id} className='radio_groups'>
                                                                                            {question.choiceQuestion.choices.map(choice => (
                                                                                                <Form.Group key={choice.id} className="category_f" id={choice.id}>
                                                                                                    <Form.Check
                                                                                                        id={`radio-${choice.id}`}
                                                                                                        type="radio"
                                                                                                        name={question.id}
                                                                                                        label={choice.value}
                                                                                                        className='msg_pre_checkbox'
                                                                                                        onClick={() => handleChange(question, choice.id, qGroup)}
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                        {question.askVisibleToOthers === true ?
                                                                                            <Form.Check id={`profileVisible${question.id}`}
                                                                                                name={`profileVisible${question.id}`}
                                                                                                type="checkbox"
                                                                                                label='Make your answer to this question visible to other users?'
                                                                                                className='msg_pre_checkbox'
                                                                                                onClick={(e) => {
                                                                                                    if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                                                                                        answers[question.id].profileVisible = e.target.checked
                                                                                                        setAnswers(answers)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                            </Form.Check> : null}
                                                                                        <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id] ? true : false}
                                                                                            variant="danger" id={`err_${question.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id]}</Alert>
                                                                                    </div> : null}
                                                                                </div>
                                                                            )
                                                                        else if (question.choiceQuestion?.type === 'ComboBox')
                                                                            return (
                                                                                <div>
                                                                                    {question.enabledQuestion === true ? <div>
                                                                                        <Form.Group className="mt-3 mb-3 dropdown">
                                                                                            <Form.Label id={question.id}>{question.name}
                                                                                                <span className='required_asterisks'>{question.required ? '*' : null}</span>
                                                                                            </Form.Label>
                                                                                            <Form.Select
                                                                                                id={question.id}
                                                                                                onChange={(e) => handleChange(question, e.target.value, qGroup)}
                                                                                                defaultValue={t('registration.questionnaires.pleaseSelectOption')}
                                                                                            >
                                                                                                <option>{t('registration.questionnaires.pleaseSelectOption')}</option>
                                                                                                {question.choiceQuestion.choices.map(choice => (
                                                                                                    <option
                                                                                                        key={choice.id}
                                                                                                        value={choice.id}
                                                                                                    >
                                                                                                        {choice.value}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                        {question.askVisibleToOthers === true ?
                                                                                            <Form.Check id={`profileVisible${question.id}`}
                                                                                                name={`profileVisible${question.id}`}
                                                                                                type="checkbox"
                                                                                                label='Make your answer to this question visible to other users?'
                                                                                                className='msg_pre_checkbox'
                                                                                                onClick={(e) => {
                                                                                                    if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                                                                                        answers[question.id].profileVisible = e.target.checked
                                                                                                        setAnswers(answers)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                            </Form.Check> : null}
                                                                                        <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id] ? true : false}
                                                                                            variant="danger" id={`err_${question.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id]}</Alert>
                                                                                    </div> : null}
                                                                                </div>
                                                                            )

                                                                        else if (question.choiceQuestion?.type === 'Checkbox')
                                                                            return (
                                                                                <div>
                                                                                    {question.enabledQuestion === true ? <div>
                                                                                        <Form.Label className="mt-1" htmlFor={question.id}>{question.name}<span className='required_asterisks'>{question.required ? '*' : null}</span></Form.Label>
                                                                                        <div className='checkbox_questionair'>
                                                                                            {question.choicesList?.map(choice => (
                                                                                                <Form.Group key={choice.id} className="category_f">
                                                                                                    <Form.Check
                                                                                                        id={`checkbox-${choice.id}`}
                                                                                                        name={question.id}
                                                                                                        type="checkbox"
                                                                                                        label={choice.value}
                                                                                                        className='msg_pre_checkbox'
                                                                                                        onClick={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                handleChange(question, choice.id, qGroup)
                                                                                                            } else if (answers[question.id] && answers[question.id].answerValues.indexOf(choice.id) >= 0) {
                                                                                                                answers[question.id].answerValues.splice(answers[question.id].answerValues.indexOf(choice.id), 1)
                                                                                                                let shouldEnable = false
                                                                                                                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                                                                                                                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                                                                                                                        shouldEnable = answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0;
                                                                                                                        if (shouldEnable) { return true; }
                                                                                                                        return false;
                                                                                                                    })
                                                                                                                    const tempQn = { ...questionnaire }
                                                                                                                    tempQn.questionGroups.filter(qg => qg.id === qGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                                                                                                                    if (!shouldEnable) {
                                                                                                                        answers[key] = {};
                                                                                                                    }
                                                                                                                    setQuestionnaire(tempQn)
                                                                                                                })
                                                                                                                setAnswers(answers)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            ))}
                                                                                        </div>
                                                                                        {question.askVisibleToOthers === true ?
                                                                                            <Form.Check id={`profileVisible${question.id}`}
                                                                                                name={`profileVisible${question.id}`}
                                                                                                type="checkbox"
                                                                                                label='Make your answer to this question visible to other users?'
                                                                                                className='msg_pre_checkbox'
                                                                                                onClick={(e) => {
                                                                                                    if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                                                                                        answers[question.id].profileVisible = e.target.checked
                                                                                                        setAnswers(answers)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                            </Form.Check> : null}
                                                                                        <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id] ? true : false}
                                                                                            variant="danger" id={`err_${question.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id]}</Alert>
                                                                                    </div> : null
                                                                                    }
                                                                                </div>
                                                                            );
                                                                    }
                                                                    else if (question.type === 'NumberQuestion') {
                                                                        return (<div>
                                                                            {question.enabledQuestion === true ? <div>
                                                                                <Form.Group className="mt-3 dropdown" controlId="formGridState">
                                                                                    <Form.Label className="" htmlFor={question.id}>{question.name}<span className='required_asterisks'>{question.required ? '*' : null}</span></Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={question.id}
                                                                                        aria-describedby={question.name}
                                                                                        min={question.numberQuestion.minValue}
                                                                                        max={question.numberQuestion.maxValue}
                                                                                        placeholder={question.numberQuestion.placeholder}
                                                                                        onChange={(e) => handleChange(question, e.target.value, qGroup)}
                                                                                    />
                                                                                    {question.askVisibleToOthers === true ?
                                                                                        <Form.Check id={`profileVisible${question.id}`}
                                                                                            name={`profileVisible${question.id}`}
                                                                                            type="checkbox"
                                                                                            label='Make your answer to this question visible to other users?'
                                                                                            className='msg_pre_checkbox'
                                                                                            onClick={(e) => {
                                                                                                if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                                                                                    answers[question.id].profileVisible = e.target.checked
                                                                                                    setAnswers(answers)
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                        </Form.Check> : null}
                                                                                </Form.Group>
                                                                                <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id] ? true : false}
                                                                                    variant="danger" id={`err_${question.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id]}</Alert>
                                                                            </div> : null}
                                                                        </div>
                                                                        )
                                                                    }
                                                                    else if (question.type === 'TextQuestion') {
                                                                        return (<div>
                                                                            {question.enabledQuestion === true ? <div>
                                                                                <Form.Group className="mt-3 dropdown" controlId="formGridState">
                                                                                    <Form.Label className="" htmlFor={question.id}>{question.name}<span className='required_asterisks'>{question.required ? '*' : null}</span></Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        id={question.id}
                                                                                        aria-label={question.name}
                                                                                        placeholder={question.textQuestion?.placeholder}
                                                                                        onChange={(e) => handleChange(question, e.target.value, qGroup)}
                                                                                    />
                                                                                    {question.askVisibleToOthers === true ?
                                                                                        <Form.Check id={`profileVisible${question.id}`}
                                                                                            name={`profileVisible${question.id}`}
                                                                                            type="checkbox"
                                                                                            label='Make your answer to this question visible to other users?'
                                                                                            className='msg_pre_checkbox'
                                                                                            onClick={(e) => {
                                                                                                if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                                                                                    answers[question.id].profileVisible = e.target.checked
                                                                                                    setAnswers(answers)
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                        </Form.Check> : null}
                                                                                </Form.Group>
                                                                                <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id] ? true : false}
                                                                                    variant="danger" id={`err_${question.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[question.id]}</Alert>
                                                                            </div> : null}
                                                                        </div>
                                                                        )
                                                                    }
                                                                }) : null}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>                                            
                                        )
                                    }) : null}
                            </div>
                        </Modal.Body>                        
                    </Modal>
                </div>
                : null}
        </>
    )
}
export default IncompleteQuestionnaire;