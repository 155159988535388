import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import step3 from "../../assets/images/step_3.png";
import promise from "../../assets/images/promise.png";
import './AGBdataprotection.css'
import { savePreRegistrationData } from './preRegistrationService'
import { useTranslation, Trans } from 'react-i18next';
import AlertModal from '../commonComponents/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {  useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { autoFocusByID } from '../commonMethods';

function AGBdataprotection(props) {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const [data, setData] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [alertBody, setAlertBody] = useState("")
    const [showMessageAlertUI, setshowMessageAlertUI] = useState({
        generalTermsAccepted: false,
        communityRulesAccepted: false,
        cancellationPolicyAccepted: false,
        privacyPolicyAccepted: false
    })

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        setData(props && props.data)
    }, [])
    
    const validateWithTAB = (evt) => {
        if(evt.type === 'keydown' && evt.keyCode === 9) {
            if(evt.target.checked === false) { 
                if(evt.target.id === 'privacyPolicyAccepted') setshowMessageAlertUI({...showMessageAlertUI, privacyPolicyAccepted: true})
                if(evt.target.id === 'generalTermsAccepted') setshowMessageAlertUI({...showMessageAlertUI, generalTermsAccepted: true})
                if(evt.target.id === 'cancellationPolicyAccepted') setshowMessageAlertUI({...showMessageAlertUI, cancellationPolicyAccepted: true})
                if(evt.target.id === 'communityRulesAccepted') setshowMessageAlertUI({...showMessageAlertUI, communityRulesAccepted: true})
                autoFocusByID(evt.target.id) 
            }
        }
    }
    
    const navigateToLogin = () => {
        navigate('/', { state: { registerEmail: props.data.email } })
    }

    const save = (e) => {
        if (data[`generalTermsAccepted`] === true && data[`communityRulesAccepted`] === true 
            && data[`cancellationPolicyAccepted`] === true && data[`privacyPolicyAccepted`] === true) {
            savePreRegistrationData(data, currentLang).then((result) => {
                if (result.status === 200) {
                    setShowSuccessPopup(true)
                }else{
                    setIsError(true)
                    setErrorMessage(result?.data.message)
                    setShowAlert(false)
                }
            })
        } else {
            let message = t('member.preregistration.errors.agbHeader')
            let generalTerms, communityRules, cancellationPolicy, rulesPrivacy;
            if (!data[`generalTermsAccepted`] || data[`generalTermsAccepted`]===false) {
                generalTerms = ' ' + t('member.preregistration.errors.generalTerms')
            }
            if (!data[`communityRulesAccepted`] || data[`communityRulesAccepted`]===false) {
                communityRules = ' ' + t('member.preregistration.errors.communityRules')
            }
            if (!data[`cancellationPolicyAccepted`] || data[`cancellationPolicyAccepted`]===false) {
                cancellationPolicy = ' ' + t('member.preregistration.errors.cancellationPolicy')
            }
            if (!data[`privacyPolicyAccepted`] || data[`privacyPolicyAccepted`]===false) {
                rulesPrivacy = ' ' + t('member.preregistration.errors.rulesPrivacy')
            }
            setAlertBody(message + (generalTerms ? generalTerms : '') + (communityRules ? communityRules : '')
            + (cancellationPolicy ? cancellationPolicy : '')+ (rulesPrivacy ? rulesPrivacy : ''))
            setShowAlert(true)
        }
    }

    return (
        <div className="form_row">
            <div className="pre-register-new">
                <div className="col-12">
                <img src={promise} alt={t('member.community.imageFor')+ " "+t('member.preregistration.promiseHeading')} />
                <h3>{t('member.preregistration.promiseHeading')}</h3>
                </div>
                <div className="promiseInfo">
                <div className="col-12">
                <p><strong>{t('member.preregistration.promiseSubheading')}</strong></p>
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.registerSubText')}</span></p>
                </div>
                <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.profileSubText')}</span></p>
                </div>
                <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.finalizeSubText')}</span></p>
                </div>
                </div>
            </div>
            <div className='membership_account'>
                <div className="reg_stepone">
                    <h3 className="lineH3 text-center"><strong>{t('member.registration.registration')}</strong> {t('member.preregistration.step3of3')}</h3>
                </div>
                <div className="form_wrapper">
                    <h4 className='agb_heading'>{t('member.preregistration.agbSubHeader1')}</h4>
                    <p className='agb_text'>
                    <Trans i18nKey="member.preregistration.agbText1" 
                            components={{ 
                                termsLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/agb/'>link</a>,
                                cancellationLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/agb/'>link</a>
                            }}/>
                    </p>
                    <h4 className='agb_heading'>{t('member.preregistration.agbSubHeader2')}</h4>
                    <p className='agb_text'>
                    <Trans i18nKey="member.preregistration.agbText2" 
                            components={{ 
                                rulesLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/kennenlernen/miteinander/'>link</a>
                            }}/>
                    </p>
                    <h4 className='agb_heading'>{t('member.preregistration.agbSubHeader3')}</h4>
                    <p className='agb_text'>
                    <Trans i18nKey="member.preregistration.agbText3" 
                            components={{ 
                                privacyLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/datenschutz/'>link</a>
                            }}/>
                    </p>
                    <Form.Group className="mb-3 first_child formGridCheckbox">
                        <Form.Check id='privacyPolicyAccepted' aria-required="true" 
                            className={showMessageAlertUI.privacyPolicyAccepted ? 'msg_pre_checkbox is-invalid' : 'msg_pre_checkbox' }
                            type="checkbox" 
                            label={t('member.preregistration.rulesPrivacy')}
                            onChange={(e)=> {
                                if(e.target.checked) { setshowMessageAlertUI({...showMessageAlertUI, privacyPolicyAccepted: false}) } else { setshowMessageAlertUI({...showMessageAlertUI, privacyPolicyAccepted: true}) }
                                data[`privacyPolicyAccepted`] = e.target.checked
                                setData({ ...data })
                            }}
                            onKeyDown={(e) => {
                                validateWithTAB(e);
                            }}
                            aria-label={showMessageAlertUI.privacyPolicyAccepted ? t('member.preregistration.labels.rulesPrivacyIsUncheck') : t('member.preregistration.labels.rulesPrivacy')}
                        />
                        {showMessageAlertUI.privacyPolicyAccepted &&
                            <Alert className='member_address' variant="danger">
                                <p>{t('member.preregistration.labels.rulesPrivacyIsUncheck')}</p>
                            </Alert>
                        }
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3 formGridCheckbox">
                        <Form.Check id='generalTermsAccepted' aria-required="true" 
                            className={showMessageAlertUI.generalTermsAccepted ? 'msg_pre_checkbox is-invalid' : 'msg_pre_checkbox' }
                            type="checkbox" 
                            label={t('member.preregistration.generalTerms')}
                            onChange={(e)=> {
                                if(e.target.checked) { setshowMessageAlertUI({...showMessageAlertUI, generalTermsAccepted: false}) } else { setshowMessageAlertUI({...showMessageAlertUI, generalTermsAccepted: true}) }
                                data[`generalTermsAccepted`] = e.target.checked
                                setData({ ...data })
                            }}
                            onKeyDown={(e) => {
                                validateWithTAB(e);
                            }}
                            aria-label={showMessageAlertUI.generalTermsAccepted ? t('member.preregistration.labels.generalTermsIsUncheck') : t('member.preregistration.labels.generalTerms')}
                        />
                        {showMessageAlertUI.generalTermsAccepted &&
                            <Alert className='member_address' variant="danger">
                                <p>{t('member.preregistration.labels.generalTermsIsUncheck')}</p>
                            </Alert>
                        }
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3 formGridCheckbox">
                        <Form.Check id='cancellationPolicyAccepted' aria-required="true" 
                            className={showMessageAlertUI.cancellationPolicyAccepted ? 'msg_pre_checkbox is-invalid' : 'msg_pre_checkbox' }
                            type="checkbox" 
                            label={t('member.preregistration.cancellationPolicy')}
                            onChange={(e)=> {
                                if(e.target.checked) { setshowMessageAlertUI({...showMessageAlertUI, cancellationPolicyAccepted: false}) } else { setshowMessageAlertUI({...showMessageAlertUI, cancellationPolicyAccepted: true}) }
                                data[`cancellationPolicyAccepted`] = e.target.checked
                                setData({ ...data })
                            }}
                            onKeyDown={(e) => {
                                validateWithTAB(e);
                            }}
                            aria-label={showMessageAlertUI.cancellationPolicyAccepted ? t('member.preregistration.labels.cancellationIsUncheck') : t('member.preregistration.labels.cancellationPolicy')}
                        />
                        {showMessageAlertUI.cancellationPolicyAccepted &&
                            <Alert className='member_address' variant="danger">
                                <p>{t('member.preregistration.labels.cancellationIsUncheck')}</p>
                            </Alert>
                        }
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3 formGridCheckbox">
                        <Form.Check id='communityRulesAccepted' aria-required="true" 
                            className={showMessageAlertUI.communityRulesAccepted ? 'msg_pre_checkbox is-invalid' : 'msg_pre_checkbox' }
                            type="checkbox" 
                            label={t('member.preregistration.communityRules')}
                            onChange={(e)=> {
                                if(e.target.checked) { setshowMessageAlertUI({...showMessageAlertUI, communityRulesAccepted: false}) } else { setshowMessageAlertUI({...showMessageAlertUI, communityRulesAccepted: true}) }
                                data[`communityRulesAccepted`] = e.target.checked
                                setData({ ...data })
                            }} 
                            onKeyDown={(e) => {
                                validateWithTAB(e);
                            }}
                            aria-label={showMessageAlertUI.communityRulesAccepted ? t('member.preregistration.labels.communityRulesIsUncheck') : t('member.preregistration.labels.communityRules')}
                        />
                        {showMessageAlertUI.communityRulesAccepted &&
                            <Alert className='member_address' variant="danger">
                                <p>{t('member.preregistration.labels.communityRulesIsUncheck')}</p>
                            </Alert>
                        }
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3 formGridCheckbox">
                        <Form.Check className='msg_pre_checkbox' type="checkbox" 
                        aria-label={t('member.preregistration.labels.enableNotifications')}
                            label={t('member.preregistration.labels.enableNotifications')}
                            onChange={(e)=> {
                                data[`enableMarketingNotifications`] = e.target.checked
                                setData({ ...data })
                            }}
                        />
                    </Form.Group>
                    <AlertModal show={showAlert} setShow={setShowAlert} heading={'Error'} body={alertBody}></AlertModal>
                    {isError ? <Alert
                        className='member_address mt-3'
                        variant="danger"
                        id='main_alert'
                        onClose={() => setIsError(false)}
                        dismissible
                    >
                        <p>{errorMessage}</p>
                    </Alert> : null}
                    <div className="mt-3 step_one_next">
                        <Button className='mx-5' variant="primary" onClick={(e) => save(e)}>
                            {t('member.preregistration.registerButton')}
                        </Button>
                    </div>
                    <div className="modal show">
                        <Modal className="registration_modal" show={showSuccessPopup}>
                            <Modal.Header>
                                <Modal.Title>{t('member.preregistration.userCreatedPopup.header')}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p>{t('member.preregistration.userCreatedPopup.body')}</p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" onClick={()=> {navigateToLogin();}}>
                                    {t('member.preregistration.userCreatedPopup.loginButton')}</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AGBdataprotection;