import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/membership.png';
import Banner from '../../components/banner/banner';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './messageToGK.css'
import {
  faEnvelope,
  faArrowLeft,
  faPaperclip,
  faArrowRight    
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function MessageToGK() {
  const { t } = useTranslation();

  let active = 3;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  const data = {
    "image": membershipIcon,
    "menuName": t('mymembership.mymembership'),
    "header": t('mymembership.msgtoGk.msgToGk'),
    "description": t('mymembership.msgtoGk.description'),
    "color": "#063978"
  }
  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data={data} />
      <div className='msg2gk_container'>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>{t('mymembership.msgtoGk.msgtype')}</Form.Label>
            <Form.Select defaultValue="Choose...">
              <option>Choose...</option>
              <option>...</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>{t('mymembership.msgtoGk.subject')}</Form.Label>
            <Form.Control />
          </Form.Group>
          <Form.Group as={Col} className="attachment_unread" id="formGridCheckbox">
            <Form.Check name='payment' className='attachment' type="checkbox" label={t('mymembership.msgtoGk.attachment')} />
            <Form.Check name='payment' className='attachment' type="checkbox" label={t('mymembership.msgtoGk.unread')} />
          </Form.Group>
        </Row>

        <Button className='suggest_btn' variant="success">
        {t('mymembership.msgtoGk.write')}
          <Badge className='plus_bg'>+</Badge>
          <span className="visually-hidden">{t('mymembership.msgtoGk.write')}</span>
        </Button>


        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className="msg_info" role="dialog" aria-label="Dialog">
          <div className="message_email">
            <p className="">
              <span className='icons_envelop'>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              {t('mymembership.msgtoGk.from')}:Gleichklang team
            </p>
          </div>
          <div className="mesasge_reply">
            <p className="">
              <span className='icons_leftarrow'>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              RE:  RE:  tedst (DESKTOP,Chrome 113 )  (DESKTOP,Chrome 110 )
            </p>
          </div>
          <div className="message_attachment">
            <p className="pl-0">
              <span className='icon_paperclip'>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              2024-02-29 09:23 AM
            </p>
          </div>
        </div>
        <div className='pagination_msg2gk'>
          <Pagination >{items}</Pagination>
        </div>


      </div>
		</main>
    </div>
  )
}

export default MessageToGK