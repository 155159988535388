import React, { useEffect, useState, useContext } from 'react'
import {Modal, Alert, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { saveStatus } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';

function EditStatus(props) {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let {
        loginData: {
          accessToken,
          memberDetails
        },
      } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
      }

    useEffect(() => {
			setSelectedAnswer(props?.profileData?.status);
    }, [])

    const onDataChange = (answer) => {
        setSelectedAnswer(answer);
      }

    const onSaveQuestionair = (answer) => {        
        const answerData = answer;
        const answerBody = {
					category: props?.type.toUpperCase(),
          status: answerData
        }
        saveStatus(answerBody, token_lang).then((response) => {
            if (response?.status === 200) {
                props.setRefreshQuestionaire(true);
            } else {
                setIsError(true);
                setErrorMessage(response.data.message);
            }
        })
      }


    function stripHtml(html){
        var temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} show={props.show} onHide={() => props.handleClose()} >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> {memberDetails?.alias}</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                {isError ?
                        (
                            <Alert
                                className='member_address mt-2'
                                variant="danger"
                                id='main_alert'
                                dismissible
                            >
                                <p>{errorMessage}</p>
                            </Alert>
                        ) : null}
										<div className="modal_content side_border">
												<h3 className="headings">{t('myprofile.status')}</h3>
												<div className="descriptions">
													 <p>Set your current status here. Your input will be saved automatically.</p>
												</div>
												<div className='freetext_details'>
													<Form.Group className=" text_question" id='textquestion'>
															<Form.Label className="" htmlFor="status">Status</Form.Label>
															<Form.Control
																	value={stripHtml(selectedAnswer)}
																	as="textarea"
																	id="status"
																	onBlur={(e) =>
																			onSaveQuestionair(e.target.value)
																		}
																		onChange={(e) => {
																			onDataChange(e.target.value);
																		}}
															/>
													</Form.Group>
												</div>
										</div>												
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditStatus;