import React from "react";
import Alert from 'react-bootstrap/Alert';

const AlertModal = ({ show, setShow, heading, body }) => {
    return (
            <Alert className='pre_registration_alert' show={show} variant="danger" onClose={() => setShow(false)}>
                    {body}
            </Alert>
    )
}

export default AlertModal;
