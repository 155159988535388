import React, { useEffect,  } from 'react';
import { useTranslation } from 'react-i18next';

function Spinner() {
    const { t } = useTranslation();

	useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () =>{
      document.body.style.overflowY = 'auto';
    }
  }, [])
	
  return (
			<div id="dot-loader" role="status">
				<div>
						<div className="sp-dot"></div>
						<div className="sp-dot"></div>
						<div className="sp-dot"></div>
				</div>
				<span>{t('spinnermsg')}</span>
			</div>
  )
}

export default Spinner;